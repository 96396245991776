@import "src/styles/variables";

.input {
  font-size: 14px;
  color: $gray-cold-900 !important;
  background: var(--primary-100) !important;
  border: 1px solid var(--primary-200);
}

.button {
  max-width: 127px;
  width: 100%;
  margin-left: 0.5rem;
}
