@import 'src/styles/variables';

.wrapper {
  margin: 0 auto;
  max-width: 724px;
}

.content {
  overflow: auto;
}

.emptyContainer {
  max-width: 430px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $gray-cold-700;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  svg {
    width: 64px;
    height: 64px;
    margin-bottom: 0.5rem;
  }
}

.emptyCoursesImg {
  width: 385px;
  height: 248px;
  margin-bottom: 1.5rem;
}
.emptyTitle {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
  text-align: center;
}
.emptyDescription {
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
  line-height: 1.375rem;
  color: $gray-cold-700;
}
