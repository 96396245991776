@import 'src/styles/variables';

.group {
  //position: absolute;
  width: 192px;
  background: $gray-neutral-100;
  box-shadow: $shadow-raised;
  border-radius: 4px;
  padding: 0.25rem 0.5rem 0.5rem;
}

:global .jtk-group-collapsed {
  :local .groupBlock {
    display: none;
  }
  :local .backButton {
    display: none;
  }
}

.groupTitle {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-900;
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-600;
  }
}

.groupBlock {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.outputNode {
  background: var(--primary-100);
  border-radius: 4px;
  font-size: 12px;
  color: $gray-cold-700;
  padding: 0.44rem 0.5rem;
  margin-bottom: 1px;

  &:last-child {
    margin-bottom: 0;
  }

  .inputElement {
    width: 75%;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 0.125rem;
    padding: 0;
    &:focus {
      background-color: $gray-neutral-100;
      border: 1px solid var(--primary-500);
    }
    &:hover {
      border: 1px solid var(--primary-500);
    }
  }
  &:focus {
    .inputElement {
      border: 1px solid var(--primary-500);
    }
  }
}

.backButton {
  position: absolute;
  top: -2rem;
  left: -2rem;
  color: $gray-neutral-100;
  background: $gradient;
  box-shadow: $shadow-popping;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;

  &:hover {
    color: $gray-neutral-100;
    background: linear-gradient(270deg, #008bff 0%, $primary-400 100%);
  }
}
