@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  background-color: var(--primary-100);
  height: var(--vh);
  display: flex;
  justify-content: flex-start;
}

.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgDefault {
  background-image: url('../../../../../assets/images/auth_bg_image.png');
  background-position: 0 50%;
}

.sidebar {
  width: 100%;
  max-width: 472px;
  height: var(--vh);
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.765) 0%, rgba(255, 255, 255, 0.68) 100%);
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
  border-radius: 0 1.5rem 1.5rem 0;
  display: flex;
  justify-content: center;
  padding: 1.5rem 3.75rem 3rem;

  @include lt-md {
    max-width: unset;
    border-radius: unset;
    padding: 1.5rem;
  }

  @include lt-sm {
    padding: 1.5rem 0.625rem 1rem;
  }
}

.wrapper {
  max-width: 352px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
