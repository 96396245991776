@import 'src/styles/variables';

.container {
  display: block;
}

.loading {
  pointer-events: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.wrapper {
  border-radius: 0.25rem;
  background: $gray-neutral-100;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.75rem;
}
