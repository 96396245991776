@import 'src/styles/variables';

.count {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-500);
  background: $gray-neutral-100;
  border-radius: 12px;
  margin-right: auto;
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem;
}

.title {
  composes: import title from '../../../courses/common/course-list-item/course-list-item.module.scss';
  max-width: 21rem;
  color: $gray-cold-900;

  a {
    margin-left: 0.5rem;
    font-weight: 400;
    color: var(--primary-500);

    svg {
      margin-left: 0.125rem;
    }
  }
}

.export {
  width: 7.875rem;
}
