@import 'src/styles/variables';

.container {
  border-radius: 0.25rem;
  background-color: $gray-neutral-100;
  padding: 1.5rem;
  margin-top: 0.5rem;
}

.iconContainer {
  padding: 0.75rem;
  border-radius: 50%;
  background: var(--primary-100);
  color: var(--primary-500);
  margin-right: 1rem;
}
.text {
  color: var(--primary-500);
  font-size: 24px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-200);
  padding-bottom: 1rem;
}

.description {
  font-size: 16px;
  color: $gray-cold-700;
  margin-top: 1rem;
}

.status {
  border-radius: 0.75rem;
  text-transform: unset;
}
