@import 'src/styles/variables';

.block {
  border-radius: 0.5rem;
  width: 18rem;
  background: $gray-neutral-100;
  box-shadow: $shadow-raised;
  margin: 0.5rem;
}

.thumbnail {
  height: 162px;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tag {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.progressbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba($gray-neutral-100, 0.75);
}

.progress {
  height: 4px;
  background: var(--primary-500);
}

.info {
  padding: 0.5rem 1rem 1rem;
}

.progressText {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-600;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  min-height: 40px;
  margin-bottom: 1rem;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // line-clamp: 2;
  // -webkit-box-orient: vertical;
}

.button {
  width: 100%;
  justify-content: center;
}
