@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  gap: 1rem;
  background: $gray-neutral-200;
  box-shadow: $shadow-well;
  border-radius: 0.25rem;
  color: $gray-cold-500;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: $gray-neutral-100;
    color: $primary-500;
  }

  &:focus, &:active {
    color: $primary-600;
  }
}

.icon {
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px dashed $gray-cold-300;
}
