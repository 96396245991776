@import 'src/styles/variables';

.container {
  padding: 0.5rem 2.5rem 0;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-500;
  margin-bottom: 1rem;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
