@import 'src/styles/variables';

.wrapper {
  padding: 0.625rem;
  background-color: $gray-neutral-100;
}

.container {
  border-radius: 0.25rem;
  background-color: $gray-cold-100;
  margin-top: 2px;
  position: relative;
}

.first {
  font-size: 12px;
  color: $green-700;
  text-align: center;
  padding: 0.25rem;
}

.text {
  composes: first;
  color: $gray-cold-900;
  margin-top: 10px;
}

.endpointCSS {
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 24px;
  height: 24px;
  background: #99c7ff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: $shadow-raised;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .elipse1 {
    border-radius: 20px;
    background: #ffffff;
    width: 12px;
    height: 12px;
  }
}
