@import 'src/styles/variables';

.container {
  background-color: $gray-neutral-100;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 0.75rem;
}

.title {
  font-size: 12px;
  color: $gray-cold-500;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
  &:first-child {
    margin-top: 0;
  }
}

.dateText {
  font-size: 16px;
  color: $gray-cold-900;
  border: none;
}

.dateText + .dateText {
  margin-left: 0.5rem;
}
