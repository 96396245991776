@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import 'native-css-variables';
@import 'variables';
@import 'bootstrap/bootstrap';
@import 'theme/buttons';
@import 'theme/forms';
@import 'theme/tabs';
@import 'theme/dropdown';
@import 'theme/tables';
@import 'theme/pagination';
@import 'theme/modal';

@import '~react-toastify/scss/main';
@import 'theme/toastify';

@import 'common';
@import 'select';
@import 'components/palette';
