@import 'src/styles/variables';

.container {
  display: flex;
  position: relative;
}

.containerSuccess {
  display: flex;
  align-items: center;
  color: $green-500;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}

.exitActiveSuccess {
  transform: translateY(100%);
  transition: transform 500ms 0.8s;
}
