@import 'src/styles/variables';

.container {
  position: relative;
}

.label {
  font-size: 12px;
  color: $gray-cold-600;
  align-items: center;
  display: flex;
  margin-right: 1.5rem;
}

.textContainer {
  max-width: 144px;
}

.text {
  padding-right: 2.5rem;
  font-size: 1.5rem;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
  height: 3rem;
}

.icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: $gray-cold-500;
}
