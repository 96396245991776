@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: 63px auto;
  align-items: center;
  gap: 0.5rem;
  background: $gray-neutral-100;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;

  .img {
    max-width: 63px;
    max-height: 36px;
    margin: auto;
  }

  &:hover {
    .title {
      color: $primary-500;
    }
  }

  &:active {
    background: $primary-200;
  }

  &.active {
    background: $primary-500;

    .title {
      color: $gray-neutral-100;
    }

    &:hover {
      background: $primary-400;
    }

    &:active {
      background: $primary-600;
    }
  }
}

.title {
  font-size: 12px;
  line-height: 18px;
}
