@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.footerSidebar {
  font-size: 12px;
  color: $gray-cold-500;
  margin-left: 0.5rem;
}

.returnCourseContainer {
  margin-top: auto;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}
