@import 'src/styles/variables';

.container {
  position: relative;
  height: 164px;
  overflow: hidden;
}

.backgroundGradient {
  background: $gradient;
  box-shadow: $shadow-raised;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.backgroundImg {
  position: absolute;
  z-index: 2;
  height: 100%;
}

.titleText {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-weight: 600;
  color: $gray-neutral-100;
  z-index: 3;
}

.wrapper {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  .info {
    font-weight: 600;
    color: $gray-neutral-100;
    max-width: 260px;
    width: 100%;
    margin-bottom: 0;
  }
}

.btnContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  max-width: 257px;
  width: 100%;
  svg {
    margin-right: 0.5rem;
  }
}
