@import 'src/styles/variables';

.description {
  font-size: 14px;
  color: $gray-cold-800;
  margin: 1rem 0 0.5rem 0;
}

.wrapper {
  position: relative;
  border-radius: 0.25rem;
  .text {
    font-size: 14px !important;
    color: $gray-cold-500;
    margin-top: 0.25rem;
    padding-left: 2.75rem;
    padding-right: 3rem;
  }
}

.container {
  display: flex;
  .iconContainer {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $yellow-100;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      color: $yellow-600;
    }
  }
  .title {
    margin-left: 0.75rem;
    font-weight: 600;
    font-size: 16px;
    color: $yellow-600;
    display: flex;
    align-self: center;
    white-space: nowrap;
  }
  .cross {
    color: $gray-cold-500;
    cursor: pointer;
    position: absolute;
    top: 0rem;
    right: 0rem;
  }
}
