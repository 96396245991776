@import 'src/styles/variables';

.container {
  background: $gray-cold-100;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    .title {
      color: var(--primary-500);
    }
  }
}

.itemContainer {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.iconSurvey {
  color: $orange-500;
}

.iconChevron {
  color: var(--primary-300);
}

.iconArrow {
  color: var(--primary-500);
}
