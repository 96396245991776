@import 'variables';

.select-container {
  .select {
    &__control {
      border-color: $primary-300;
      min-height: 30px;
      font-size: 14px;
      box-shadow: none;

      &--is-focused {
        border-color: var(--primary-600) !important;
      }

      &--menu-is-open {
        .select__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &:hover {
        border-color: var(--primary-500);
      }
    }

    &__value-container {
      padding: 0 4px;
    }

    &__indicator {
      padding: 7px;
      color: $gray-cold-500;

      &:hover {
        color: $gray-cold-500;
      }
    }

    &__placeholder {
      color: $gray-cold-500;
    }

    &__menu {
      box-shadow: $shadow-floating;
      overflow: hidden;
      margin-top: 4px;
    }

    &__menu-list {
      padding: 0;
    }

    &__menu-notice {
      padding: 0;
      font-size: 14px;
      color: $gray-cold-600;
    }

    &__no-option-message {
      padding: 1.5rem 2rem;
      display: flex;
      flex-direction: column;
    }

    &__option {
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 3px 8px 4px;
      width: 100%;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border-top: 1px solid $gray-cold-200;
      line-height: 20px;

      &:first-child {
        padding-top: 6px;
        border: none;
      }

      .with-icon {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .icon {
          line-height: 0;
          color: var(--primary-500);
        }

        .label {
          line-height: 24px;
        }
      }

      .buttons {
        opacity: 0;
        margin-left: auto;
        flex-shrink: 0;
        margin-top: -2px;
        margin-bottom: -2px;
      }

      &:active {
        background-color: var(--primary-200);
      }

      &--is-focused {
        background-color: var(--primary-100);
        color: unset;

        .buttons {
          opacity: 1;
        }

        .btn {
          color: var(--primary-500);
        }
      }

      &--is-selected {
        background-color: var(--primary-100);
        color: var(--primary-500);
      }
    }

    &__single-value {
      cursor: pointer;

      .with-icon {
        display: flex;
        align-items: center;
        gap: 4px;

        .icon {
          line-height: 0;
          color: var(--primary-500);
        }

        .label {
          line-height: 24px;
        }
      }
    }
  }

  &.invalid {
    border-color: $red-600;
  }

  &.select-lg {
    .select {
      &__control {
        min-height: 46px;
        font-size: 14px;

        &--menu-is-open {
          .select__dropdown-indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__value-container {
        padding: 0 14px;
      }

      &__indicator {
        padding: 11px 12px;
      }

      &__option {
        padding: 13px 16px 14px;

        &:first-child {
          padding-top: 14px;
        }
      }
    }
  }

  &.select-sm {
    .select {
      &__control {
        min-height: 20px;
        font-size: 12px;

        &--menu-is-open {
          .select__dropdown-indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__value-container {
        padding: 0 0.25rem;
      }

      &__indicator {
        padding: 0;
      }

      &__menu {
        width: auto;
      }

      &__option {
        padding: 3px 8px 4px;
      }
    }
  }
}
