@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 320px;

  .btn {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0.25rem;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    color: $gray-cold-700;
    text-align: center;
    margin-bottom: 1rem;
  }

  .icon {
    color: $yellow-500;
    width: 64px;
    height: 64px;
    margin-bottom: 0.5rem;
  }
}
