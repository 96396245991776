@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  padding: 0.5rem 1.5rem;
  background: $gray-neutral-100;
  @media (min-width: 576px) {
    grid-template-columns: auto 1fr auto;
  }
}

.image {
  display: none;
  @media (min-width: 576px) {
    display: block;
    border-radius: 50%;
    margin-right: 1rem;
    margin-top: 0.5rem;
    width: 48px;
    height: 48px;
  }
}

.input {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  resize: none;
  line-height: 20px;
  font-size: 14px;
  min-height: 28px;
  @media (min-width: 576px) {
    min-height: 48px;
    padding-right: 1rem;
    padding-top: 0.875rem;
    padding-bottom: 0;
  }

  &::placeholder {
    color: $gray-cold-500;
  }
  &:hover {
    &::placeholder {
      color: $gray-cold-700;
    }
  }
}

.send {
  background: $gray-cold-200;
  border-radius: 50%;
  margin-left: 0.25rem;
  width: 32px;
  height: 32px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  color: $gray-cold-400;
  cursor: default;

  @media (min-width: 576px) {
    margin-bottom: 0.5rem;
  }

  &.active {
    cursor: pointer;
    background: $gradient-main;
    color: $gray-neutral-100;
    &:hover {
      background: var(--primary-400);
    }
    &:active {
      background: var(--primary-600);
    }
  }
}
