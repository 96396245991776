@import 'src/styles/variables';

.navbar {
  flex: 1;
  height: 100%;
  padding: 0 2rem 0 0;
  justify-content: flex-end;

  &-nav {
    height: 100%;
  }

  .item {
    height: 100%;

    .link {
      font-size: 14px;
      color: $gray-cold-700;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 2rem;
      border-bottom: 2px solid transparent;
      transition: border-bottom-color 0.1s, color 0.2s;

      &.active,
      &:hover {
        border-bottom-color: var(--primary-500);
        color: var(--primary-500);
      }

      &.active {
        font-weight: 600;
      }
    }

    .noneEvents {
      pointer-events: none;
    }
  }
}

.link {
  &:hover {
    text-decoration: none;
    color: var(--primary-600);
  }

  flex-shrink: 0;

  .name {
    color: $gray-cold-700;
  }
}

.zoomContainer {
  padding: 4px;
  border-radius: 16px;
  border: solid 1px var(--primary-200);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  user-select: none;
}

.zoomBtn {
  color: var(--primary-500);
  width: 24px;
  height: 24px;
  background-color: var(--primary-100);
  border-radius: 20px;
  cursor: pointer;
}

.input {
  max-width: 80px;
  color: $gray-cold-700;
  font-size: 12px;
  border: none;
  text-align: center;
  user-select: none;
  pointer-events: none;
  background: transparent;

  &:focus {
    outline: none;
    border: none;
  }
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.funnelName {
  border-color: transparent;
  padding: 5px 6px 5px 8px;
  height: unset;
  max-width: 300px;

  &:hover {
    border-color: $primary-300;
  }
}

.wrapper {
  position: relative;
  display: flex;
}

.customStatus {
  position: unset;
  margin-left: 16px;
  align-items: center;
  display: flex;
}

.editName {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.editWrapper {
  height: 100%;
  align-items: center;
}

.funnelActionsToolbar {
  display: flex;
  height: 100%;
}

.actionItem {
  border-left: 1px solid var(--primary-200);
  display: flex;
  align-items: center;

  .icon {
    color: $gray-cold-500;
  }
}

.linkDisable {
  pointer-events: none;
  color: $gray-cold-500;
}
.linkDisableCursor {
  cursor: not-allowed;
}
