@import 'src/styles/variables';

.container {
  padding: 1.5rem 3.5rem 3.5rem;
}

.title {
  padding: 2.5rem 3.5rem;
  background: var(--primary-100);
  color: var(--primary-600);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0.5rem 0.5rem 0 0;

  svg {
    margin-bottom: 1rem;
  }

  span {
    padding-top: 0.25rem;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
}

.text {
  line-height: 22px;
  margin-bottom: 1.5rem;
  text-align: center;
}
