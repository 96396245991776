@import 'src/styles/variables';

.container {
  background: $gradient;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 0.5rem;
}

.text {
  font-size: 14px;
  font-weight: 600;
  color: $gray-neutral-100;
}
