@import 'src/styles/variables';

.dialog {
  border-radius: 0.25rem;
  overflow: hidden;
  max-width: 452px;
  margin: 0 auto;
}

.container {
  max-width: 340px;
  margin: 1.5rem auto 2.375rem;
}

.titleContainer {
  background: $gray-cold-100;
  text-align: center;
  padding-top: 3.5rem;
}

.icon {
  width: 64px;
  height: 64px;
  color: $red-500;
}

.title {
  font-size: 24px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.description {
  margin-bottom: 1.5rem;
  line-height: 22px;
  text-align: center;

  p {
    margin-bottom: 22px;
  }
}

.btn {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 48px;
}

.btnDisconnect {
  background: $red-100;
  color: $red-500;
  margin-bottom: 0.5rem;
  @extend .btn;

  &:hover {
    background: $red-200;
    color: $red-500;
  }
}
