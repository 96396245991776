@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';


.container {
  border-top: solid 1px $gray-cold-300;
  padding: 0.5rem 0 1rem;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
}

@include lt-sm {
  .container {
    align-items: flex-start;
  }
}

.organization {
  color: $gray-cold-700;
  font-weight: 600;
  font-size: 14px;
}

.links {
  display: flex;
  gap: 1rem;
}

.link {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}
