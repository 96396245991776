@import 'src/styles/variables';

.description {
  font-size: 14px;
  color: $gray-cold-800;
  margin: 1rem 0 0.5rem 0;
}

.warning {
  color: $gray-cold-500;
}

.text {
  color: $gray-cold-700;
  font-size: 12px;
  margin-left: 0.5rem;
}
