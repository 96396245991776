@import 'src/styles/variables';

.container {
  padding: 0.375rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-100);
    .createTagContainer {
      background: var(--primary-500);
      .suggestionsNoneText,
      .createText {
        color: $gray-neutral-100;
        .overlay {
          background: linear-gradient(90deg, #0072ff 31.25%, rgba(0, 114, 255, 0) 100%);
        }
      }
      &.suggestionsEmptyNoneText {
        background: unset;
      }
    }
  }
}

:global #ReactTags {
  #ReactTags-0 {
    :local .create {
      padding: 1rem;
    }
  }
}

.create {
  padding: 0.375rem 1rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.image {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--primary-500);
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.plusContainer {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-100);
  }
}

.text {
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.3px;
  color: $gray-cold-700;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;

  &.hidden {
    visibility: hidden;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.createTagContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-500);
  border-radius: 12px;
  padding: 3px 8px;
  max-width: 255px;
  white-space: nowrap;
  overflow: hidden;

  .suggestionsNoneText {
    color: $gray-cold-700;
    font-size: 12px;
    overflow: hidden;
    direction: rtl;
  }
  .createText {
    color: var(--primary-600);
    font-size: 12px;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -32px;
      width: 32px;
      border-radius: 13px 0px 0px 13px;
      background: linear-gradient(90deg, #ffffff 35.94%, rgba(255, 255, 255, 0) 98.44%);
    }
  }
  &.suggestionsEmptyNoneText {
    color: $gray-cold-700;
    font-size: 14px;
    border: none;
    letter-spacing: -0.003em;
  }
}
