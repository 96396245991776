@import 'src/styles/variables';

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 23, 51, 0.5);
  backdrop-filter: blur(2.5px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerModal {
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
  background: $gray-neutral-100;
  padding: 1rem 0.5rem 0.5rem;
  max-width: 222px;

  .btnContainer {
    display: flex;
    margin-top: 0.5rem;
    justify-content: stretch;
    gap: 0.25rem;
    width: 100%;
  }

  .title {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
