@import 'src/styles/variables';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2rem 0 (2rem + $sidebar-menu-width);
  z-index: 1;

  .inner {
    background: $gray-neutral-100;
    box-shadow: $shadow-floating;
    display: flex;
    padding: 1rem 1.5rem;
  }

  &.change {
    display: none;
  }
}
