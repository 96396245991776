@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  border-radius: 0.25rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1rem;
}

.priceContainerElement {
  border-radius: 0.5rem;
  background-color: $gray-neutral-100;
  &.active {
    margin-left: -0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.875rem;
    background-color: var(--primary-100);
  }
}

.paymentContainer {
  position: absolute;
  top: 0.5rem;
  right: 0;
}

.disableRadio {
  margin-top: 0.5rem;
}

.selectedContainer {
  width: 90px;
  display: flex;
  justify-content: space-between;
}

.iconSetting {
  color: $primary-500;
  margin-right: 0.5rem;
}

.iconChevron {
  color: $gray-cold-500;
}

.dropItem {
  padding: 0.25rem 0.75rem;
}

.payment {
  border: none;
  background: transparent;
  font-weight: 600;
  text-align: left;
  display: block;
  width: 100%;
  cursor: pointer;
  &::placeholder {
    font-weight: normal;
  }
}
