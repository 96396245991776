@import 'src/styles/variables';

.container {
  display: block;
  max-width: 592px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 1rem;
}

.input {
  position: relative;
  margin-bottom: 0.5rem;

  .status {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: $gray-cold-200;
    color: $gray-cold-400;
    line-height: 0;
    cursor: pointer;
    pointer-events: none;

    &.loading {
      background: none !important;
    }

    &.valid {
      background: $gradient;
      color: $gray-neutral-100;
      pointer-events: auto;

      &:hover {
        background: var(--primary-600);
      }

      &:focus,
      &:active {
        background: var(--primary-700);
      }
    }
  }
}
