@import 'src/styles/variables';

.addRangeBtn {
  background-color: var(--primary-100);
  width: 100%;
  justify-content: left;
  display: flex;
  color: var(--primary-500);
  align-items: center;
  font-weight: normal;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}

.containerError {
  background-color: $red-100;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.errorTitle {
  color: $gray-neutral-900;
  font-weight: bold;
  font-size: 14px;
}

.relative {
  position: relative;
}
