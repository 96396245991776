@import 'src/styles/variables';

.container {
}

.icon {
  color: $yellow-500;
}

.description {
  margin-top: 0.25rem;
  font-size: 14px;
  color: $gray-cold-500;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
