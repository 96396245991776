@import 'src/styles/variables';

.studentForm {
  padding: 1.5rem;
}

.studentNameContainer {
  display: flex;
  justify-content: space-between;
}

.studentName {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;
  flex-grow: 1;
  margin-right: 1.5rem;

  & > div {
    &:first-child {
    }

    &:last-child {
      flex-grow: 1;
      flex-shrink: 1;

      .formControl {
        min-width: 100%;
      }
    }
  }

  .formControl {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: -0.6px;
    color: $gray-cold-900;
    border-bottom: 1px solid $primary-300;
    border-radius: 0;

    &:first-child {
      margin-right: 0.375rem;
    }
  }
}

.deleteButton {
  align-self: flex-start;
  margin-top: 0.375rem;
  margin-right: -0.5rem;
  color: $red-200;

  &:hover {
    color: $red-500;
  }
}
