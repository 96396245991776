@import 'src/styles/variables';

.container {
  height: 140px;
  padding: 1.5rem;
  position: relative;
}

.wrapper {
  position: relative;
}

.warning {
  background: $yellow-100;
  border: 1px solid $yellow-300;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: $yellow-600;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.description {
  font-size: 14px;
}

.btn {
  height: 48px;
}
