@import 'src/styles/variables';

.container {
  display: block;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
}

.postContainer {
  border-top: 1px solid $gray-cold-200;
  border-bottom: 1px solid $gray-cold-200;
}

.commentContainer {
  margin-top: 0 !important;
  div {
    margin: 0;
    img {
      margin-left: 1.5rem;
    }
  }
}
