@import 'src/styles/variables';

.createPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: var(--primary-100);
  border-radius: 0.5rem;
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  .icon {
    margin-bottom: 0.25rem;
    color: var(--primary-500);
  }
}

.utmBuilder {
  margin: auto -2rem -2rem;
  padding: 1rem 2rem 1.5rem;
  background: $gray-neutral-200;

  :global .form-label {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: $gray-cold-900;
  margin-bottom: 0.5rem;
}

.editLink {
  font-weight: normal;
  color: var(--primary-500);
  cursor: pointer;
}

.copy {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  z-index: 2;
}

.open {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 2rem;
}

.form {
  display: none;

  &.show {
    display: block;
  }
}

.overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 23, 51, 0.5);
  backdrop-filter: blur(5px);
}

.input {
  position: relative;

  .open & {
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0.2rem;
    right: 1px;
    width: 62px;
    height: 1.5rem;
    background: linear-gradient(270deg, #ffffff 56.45%, rgba(255, 255, 255, 0) 88.03%);
    z-index: 1;
  }
}
