@import 'src/styles/variables';

.titleLink {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1rem;
}

.btn {
  &:disabled {
    &:hover {
      background-color: $gray-cold-300;
      color: $gray-neutral-100;
    }
  }
}
