@import 'src/styles/variables';

.wrapper {
  position: fixed;
  bottom: 25px;
  right: 100px;
  z-index: 101;
  box-shadow: $shadow-popping;
  border-radius: 50%;

  &.moved {
    right: 618px;
  }
}

.iconCross {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  box-shadow: $shadow-raised;
  background: $gray-neutral-100;
  border-radius: 50%;
  color: $gray-cold-600;
  cursor: pointer;
  z-index: 1;

  &.active {
    z-index: 5;
  }

  &:hover {
    background: var(--primary-200);
    color: var(--primary-500);
  }
}

.containerCollapsed {
  position: fixed;
  bottom: 20px;
  right: 90px;
  background: $gray-neutral-100;
  width: 416px;
  z-index: 3;
  height: 0;
  box-shadow: $shadow-floating;
  border-radius: 0.5rem;
  overflow: hidden;

  .moved & {
    right: 608px;
  }

  &.active {
    height: auto;
  }
}

.containerCollapsedBody {
  overflow-y: auto;
  height: 224px;
  position: relative;
  z-index: -1;
}

.collapseContainer {
  padding: 0.5rem;
  overflow-y: auto;
}

.modalContainer {
  z-index: 1;
  position: absolute;
  top: 82px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 23, 51, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btnContainer {
    display: flex;
    margin-top: 0.5rem;
    justify-content: stretch;
    gap: 0.25rem;
    width: 100%;
  }

  .content {
    background: $gray-neutral-100;
    box-shadow: $shadow-popping;
    border-radius: 0.25rem;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    max-width: 222px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .description {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
