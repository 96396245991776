@import '../../../../../../../../styles/variables';

.container {
  padding: 1rem;
  background-color: var(--primary-100);
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  position: relative;

  &:hover {
    .title {
      color: var(--primary-600);
    }
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: $gray-cold-700;
}

.attempt {
  font-size: 12px;
  line-height: 20px;
  background-color: $gray-neutral-100;
  border-radius: 0.125rem;
  padding: 0 0.25rem;
  margin-right: 0.5rem;
}

.detail {
  color: var(--primary-500);
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;

  .icon {
    overflow: hidden;
    width: 1rem;
    height: 1rem;
    position: relative;

    svg {
      position: absolute;
    }
  }

  .chevron {
    opacity: 1;
    transition: opacity 0.25s 50ms;
  }

  .arrow {
    transition: transform 0.25s 50ms;
    transform: translateX(-100%);
  }

  &:hover {
    .chevron {
      opacity: 0;
    }

    .arrow {
      transform: translateX(0);
    }
  }
}

.breadcrumbs {
  border-top: 1px solid $gray-cold-300;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.menu {
  position: absolute;
  top: 14px;
  right: 12px;
  line-height: 0;
}

:global .dropdown {
  &.show {
    .dropdown-kebab-toggle {
      color: var(--primary-500);
    }
  }

  .dropdown-kebab-toggle {
    color: $primary-300;

    &:hover {
      color: var(--primary-500);
    }
  }
}
