@import 'src/styles/variables';

.container {
  background: $gray-cold-200;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.text {
  font-size: 14px;
  margin-right: 1.5rem;
}

.image {
  max-width: 464px;
  max-height: 310px;
}
