@import 'src/styles/variables';

.container {
  margin-bottom: 2rem;
  flex: 0 0 320px;
  max-width: 320px;
  padding: 0 1rem;
}

.item {
  background: $gray-neutral-100;
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
  box-shadow: $shadow-raised;
  display: flex;
  flex-direction: column;
}

.preview {
  position: relative;
  width: 100%;
  height: 162px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: $gray-neutral-200;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.nonePosition {
  position: unset;
}

.info {
  padding: 0.875rem 0.5rem 0.875rem 1rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  .kebab {
    color: $gray-cold-500;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--primary-500);
      background: var(--primary-100);
      border-radius: 0.25rem;
      width: 32px;
      height: 32px;
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: $gray-cold-700;
  margin-bottom: 0;
  display: block;

  a {
    color: $gray-cold-900;
  }
}

.date {
  font-size: 12px;
  color: $gray-cold-500;
  margin-top: auto;
}

.additionInfo {
  display: flex;
  align-items: center;
  font-size: 14px;

  .icon {
    color: $gray-cold-500;
    margin-right: 0.25rem;
  }
}

.students {
  display: flex;
  align-items: center;
  color: $black;
  min-width: 70px;
}

.comments {
  display: flex;
  align-items: center;
  color: $black;
}

.reviews {
  display: flex;
  align-items: center;
  color: $black;
  margin-left: auto;

  .icon {
    color: $yellow-500;
  }
}

.labels {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
}
