@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-200);
  border-radius: 0.25rem;
}

.active {
  background: $red-100;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.5rem;
}

.playText {
  @extend .text;
  color: $gray-cold-900;
}

.stopText {
  @extend .text;
  color: $red-500;
}

.iconStop {
  color: $red-500;
}

.recorder {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border-style: solid;
  border-width: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play {
  @extend .recorder;
  background: $red-500;
  border-color: $gray-neutral-100;
  &:hover {
    background: $red-400;
  }
}

.stop {
  @extend .recorder;
  background: $gray-neutral-100;
  border-color: $red-500;
  &:hover {
    border-color: $red-400;
    .iconStop {
      color: $red-400;
    }
  }
}
