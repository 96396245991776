.container {
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
}

.toggleContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
