@import 'src/styles/variables';

.markQuiz {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
  background-color: var(--primary-100);
  box-shadow: $shadow-floating;

  .markQuizContain {
    display: flex;
    align-items: center;
  }

  .markQuizTitle {
    letter-spacing: -0.003em;
    color: $gray-cold-700;
    font-size: 14px;
    font-weight: 600;
    margin-right: 0.5rem;
  }

  .markQuizButtons {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  .linkText {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .iconLink {
    margin-left: 0.25rem;
  }

  .actionsButtons {
    display: flex;
    align-items: center;
  }

  .btnDisable {
    pointer-events: none;
  }
}
