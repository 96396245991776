@import 'src/styles/variables';

.container {
  background-color: $gray-neutral-100;
}

.wrapper {
  width: 256px;
  border-radius: 0.125rem;
  border: solid 1px $gray-cold-300;
}

.textContainer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $gray-cold-700;
  background-color: $gray-cold-200;
  padding: 0.75rem;
}

.bgContainer {
  background-color: $gray-neutral-100;
  border-top: solid 1px $gray-cold-300;
  padding: 0.625rem 0.75rem;
}

.circle {
  border-radius: 50%;

  :global .btn-delete {
    left: -4px;
    bottom: -4px;
    right: auto;
    top: auto;
  }

  :global .title {
    font-size: 12px;
    color: $gray-cold-500;
  }
}

.outputs {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 118px;
}
