@import 'src/styles/variables';

.filters {
  :global .nav-link {
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    letter-spacing: -0.33px;
    color: $gray-cold-500;

    &.active {
      color: var(--primary-500);
    }
  }

  :global .nav-item {
    &:last-child {
      margin-left: 0.25rem;
      padding-left: 0.25rem;
      border-left: 1px solid $gray-cold-300;
    }
  }
}
