@import 'src/styles/variables';

.container {
  margin-bottom: 1rem;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
}

.open {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  margin-left: 1rem;

  &:hover {
    text-decoration: inherit;
  }
}
