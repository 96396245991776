@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.text {
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.note {
  color: $gray-cold-700;
  margin-bottom: 2rem;
  text-align: center;
}

.button {
  width: 100%;
  max-width: 16rem;
}

.img {
  min-height: 128px;
}
