@import 'src/styles/variables';

.container {
  background-color: var(--primary-200);
  padding: 1px 0;
  margin-right: 0.25rem;
  margin-left: 0;
  margin-bottom: 0.25rem;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  &:hover:enabled {
    background-color: var(--primary-500);

    .iconPlus,
    .text {
      color: $gray-neutral-100;
    }
  }

  &:disabled {
    cursor: default;

    .text {
      color: $gray-cold-500;
    }
  }
}

.containerSelect {
  composes: container;
  background-color: var(--primary-500);
}

.iconContainer {
  display: flex;
  align-items: center;
  padding-right: 2px;
}

.iconPlus {
  width: 1rem;
  height: 1rem;
  color: $gray-cold-500;
}

.text {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-900;
}

.textSelect {
  composes: text;
  color: $gray-neutral-100;
}

.iconCheck {
  color: $gray-neutral-100;
  width: 1rem;
  height: 1rem;
}

.closeContainer {
  background-color: $gray-neutral-100;
  border-radius: 10px;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  width: 1rem;
  height: 1rem;
  color: var(--primary-500);
}
