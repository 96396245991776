@import 'src/styles/variables';

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: var(--primary-600);
  cursor: pointer;
}

.email {
  display: inline-block;
  border-bottom: 1px solid $gray-cold-700;
  margin-bottom: -1px;
}

.row {
  display: flex;
}

.selectedRow {
  background-color: var(--primary-200);

  .listName {
    font-weight: bold;
  }
}

:global .table {
  :local .lists {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  :global tbody {
    :local .selectedRow {
      :global td {
        color: $gray-cold-900;
      }
    }
  }
}

.title {
  composes: import title from '../../courses/common/course-list-item/course-list-item.module.scss';
  -webkit-line-clamp: 1;
  font-weight: normal;
  color: var(--primary-600);
}
