@import 'src/styles/variables';

.container {
  background-color: $gray-neutral-100;
  padding: 0.75rem;
}

.date {
  font-size: 16px;
  color: $gray-cold-900;
}

.zone {
  composes: date;
  color: $gray-cold-500;
}

.title {
  font-size: 12px;
  color: $gray-cold-500;
  margin-bottom: 0.25rem;
}
