@import 'src/styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.middleCircle {
  position: absolute;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: $gray-neutral-100;
}

.progressPinner {
  border-radius: 50%;
  height: 16px;
  width: 16px;
}
