@import 'src/styles/variables';

.Toastify {
  /** Used to define container behavior: width, position: fixed etc... **/
  &__toast-container {
    /** Used to define the position of the ToastContainer **/
    &--top-left {
    }

    &--top-center {
    }

    &--top-right {
    }

    &--bottom-left {
    }

    &--bottom-center {
    }

    &--bottom-right {
    }
  }

  /** Classes for the displayed toast **/
  &__toast {
    padding: 0.75rem;
    border-radius: 4px;
    box-shadow: $shadow-floating;
    background-color: $gray-neutral-100;
    color: $gray-cold-500;

    &--dark {
    }

    &--default {
    }

    &--info {
    }

    &--success {
    }

    &--warning {
    }

    &--error {
    }

    &-body {
      padding: 0;
      width: 100%;
    }
  }

  /** Classes for the close button. Better use your own closeButton **/
  &__close-button {
    &--default {
    }

    & > svg {
    }

    &:hover,
    &:focus {
    }
  }

  /** Classes for the progress bar **/
  &__progress-bar {
    &--animated {
    }

    &--controlled {
    }

    &--rtl {
    }

    &--default {
    }

    &--dark {
    }
  }
}
