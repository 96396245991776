@import 'src/styles/variables';

.container {
  background: $red-100;
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: $red-500;
  font-weight: 600;
  font-size: 16px;
}

.description {
  font-size: 14px;
  margin-top: 0.25rem;
}

.btn {
  max-width: 190px;
  margin-top: 1.875rem;
}

.cardContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.875rem;
  width: 216px;
  background: $gradient;
  box-shadow: 0px 20px 25px -10px rgba(3, 38, 101, 0.35);
  .iconCard {
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1rem;
  }
  .cardWrapper {
    display: flex;
    justify-content: space-between;
  }
  border-radius: 0.5rem;
  .text {
    color: $gray-neutral-100;
    font-size: 14px;
    position: absolute;
    z-index: 1;
  }

  .cardNumber {
    font-size: 16px;
    color: $gray-neutral-100;
    position: absolute;
    bottom: 1rem;
    z-index: 1;
  }
}

.bgEllipse1 {
  position: absolute;
  background: var(--primary-500);
  right: -57px;
  top: 70px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.bgEllipse2 {
  composes: bgEllipse1;
  top: -122px;
  right: 56px;
}
