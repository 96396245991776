@import 'src/styles/variables';

.nameserversContainer {
  background: $primary-100;
  border: 1px solid $primary-200;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}

:global {
  .full-instructions {
    :local {
      .nameserversContainer {
        background: $gray-neutral-100;
      }
    }
  }
}

.name {
  color: $primary-600;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  cursor: default;

  span {
    margin-right: 0.5rem;
  }

  &:hover {
    .copy {
      opacity: 1;
    }
  }
}

.name + .name {
  margin-top: 0.5rem;
}

.copy {
  opacity: 0;
  color: $primary-600;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    color: $primary-400;
  }

  &:active {
    color: $primary-500;
  }

  svg {
    margin-right: 0 !important;
    margin-left: 0.5rem;
  }

  &.coped {
    color: $gray-cold-500;
  }
}
