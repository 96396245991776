@import 'src/styles/variables';

.row {
  display: flex;

  td {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &:hover {
    .actions {
      .buttons {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.actions {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.delete {
  color: $red-500 !important;
}

.selectedRow {
  background-color: var(--primary-200);

  .actions {
    .buttons {
      opacity: 1;
      visibility: visible;
    }
  }
}

:global .table {
  :global tbody {
    :local .selectedRow {
      :global td {
        color: $gray-cold-900;
      }
    }
  }
}

.title {
  flex-grow: 1;
}

.warning {
  color: $red-500;
}

.titleModal {
  color: var(--primary-500) !important;
}
