@import 'src/styles/variables';

.container {
  padding: 2rem 3.5rem 3.5rem 3.5rem;
}

.hint {
  color: $gray-cold-500;
  font-size: 12px;
  line-height: 18px;
}

.integration {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.notice {
  margin-top: 1rem;
  font-size: 14px;
  text-align: center;
}

.link {
  color: $primary-600;
  cursor: pointer;
  &:hover {
    color: $primary-400;
  }
}

.error {
  border-radius: 0.25rem;
  padding: 0;
  margin-top: 2rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
