@import 'src/styles/variables';

.container {
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.container + .container {
  margin-left: 16px;
}

.text {
  width: 12px;
  height: 12px;
}

.selectedItem {
  padding: 3px;
  border: solid 2px var(--primary-500);
}
