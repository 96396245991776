@import 'src/styles/variables';

.wrapper {
  max-width: 442px;
}

.description {
  font-size: 14px;
  line-height: 20px;
}
.ns {
  font-weight: 600;
}
.ns + .ns {
  margin-top: 0.25rem;
}

.viewAll {
  width: fit-content;
  text-decoration: underline;
  color: $primary-600;
  font-size: 12px;
  line-height: 18px;
  margin-top: 1rem;
  cursor: pointer;
  svg {
    margin-left: 0.25rem;
  }
  &:hover {
    color: $primary-400;
  }
  &:active {
    color: $primary-500;
  }
}
