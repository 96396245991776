@import 'src/styles/variables';

.header {
  position: sticky;
  top: -1rem;
  background: $gray-neutral-100;
  z-index: 1;
  &.isPinned {
    box-shadow: 0px 1px 5px -2px rgba(7, 7, 55, 0.15), 0px 2px 10px 2px rgba(7, 7, 55, 0.08);
  }
  @media (min-width: 576px) {
    top: -2.5rem;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 1rem 1rem 1rem 1.5rem;
  span {
    color: $gray-cold-500;
  }
}

.container {
  // overflow: hidden;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  background: $gray-neutral-100;
  margin-bottom: 24px;
  @media (min-width: 576px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

.postCommentContainer {
  border-top: 1px solid $gray-cold-100;
  border-bottom: 1px solid $gray-cold-100;
}

.emptyPadding {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.loaderContainer {
  @extend .emptyPadding;
  display: flex;
  justify-content: center;
  align-items: center;
}
