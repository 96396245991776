@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.imagePreview {
  background-color: $gray-neutral-100;
  border: 1px solid $primary-300;
  border-radius: 0.25rem;
  width: 72px;
  height: 48px;
  color: $primary-500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.bg {
    background-image: url("../../../../../assets/images/bg-transparent.png");
  }

  &:hover {
    .overlay {
      display: flex;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  gap: 6px;
  display: none;
  background: rgba(13, 13, 13, 0.25);
}

.btn {
  color: $gray-neutral-100;

  &:hover {
    background: none;
    color: $primary-100;
  }
}

.separator {
  width: 1px;
  height: 24px;
  background: $gray-neutral-100;
  opacity: 0.5;
}

.button {
  margin: 0;
  flex: 1;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.tips {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}
