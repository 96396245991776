@use './common';
@import 'src/styles/variables';

.bubble {
  .ql-tooltip {
    z-index: 10;

    .ql-tooltip-arrow {
      display: none;
    }
  }

  .ql-toolbar {
    background: var(--primary-100);
    box-shadow: $shadow-floating;
    border-radius: 0.5rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    width: 650px;

    .ql-formats {
      button {
        color: $gray-cold-500;
        display: flex;
        align-items: center;
        height: 24px;
        padding: 0;

        &:hover {
          color: var(--primary-500);
        }
      }

      .ql-picker-label {
        padding: 0;
      }

      .ql-expanded {
        .ql-picker-options {
          background-color: var(--primary-100);
          box-shadow: $shadow-floating;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;


          .ql-picker-item {
            padding: 0;
          }

          span {
            &::before {
              color: $gray-cold-500;
            }

            &:hover {
              &::before {
                color: var(--primary-500);
              }

              svg {
                filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
              }
            }
          }
        }

        &.ql-color-picker {
          .ql-picker-options {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }

        &.ql-icon-picker {
          .ql-picker-options {
            align-items: center;
          }
        }
      }
    }
  }

  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: $gray-cold-500;
  }

  .ql-toolbar .ql-fill {
    fill: $gray-cold-500;
    stroke: none;
  }

  .ql-toolbar .ql-picker {
    color: $gray-cold-500;
  }

  .ql-tooltip-editor {
    input {
      color: $gray-cold-700 !important;
    }
  }

  button:hover {
    button.ql-active,
    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: var(--primary-500) !important;
    }

    .ql-fill,
    .ql-stroke.ql-fill {
      fill: var(--primary-500) !important;
    }

    .ql-stroke,
    .ql-stroke-miter {
      stroke: var(--primary-500) !important;
    }
  }

  .ql-picker {
    .ql-picker-label {
      &:hover {
        svg {
          fill: $gray-cold-500 !important;
        }
      }
    }

    // &.ql-size {
    //   .ql-picker-label,
    //   .ql-picker-item {
    //     &::before {
    //       content: attr(data-value);
    //       color: $gray-cold-500;
    //     }
    //   }
    // }

    &.ql-header {
      .ql-picker-label {
        &::before {
          //color: $gray-cold-500 !important;
        }
      }
    }
  }
}

.email-editor-title {
  @include common.font-size(32px);

  .ql-editor {
    padding-top: 0;
    padding-bottom: 1rem;
    overflow-x: hidden;
  }

  .ql-container {
    font-weight: 600;
    font-size: 32px;

    ::before {
      font-style: normal !important;
    }
  }
}

.email-editor-message {
  @include common.font-size(16px);

  .ql-container {
    font-size: inherit !important;

    &::before {
      font-style: normal !important;
    }
  }

  .ql-editor {
    padding-top: 0;
  }
}

.simple-editor {
  font-size: 16px;
  @include common.font-size(16px);

  .ql-container {
    font-size: inherit !important;
    font-family: $font-family;

    &::before {
      font-style: normal !important;
    }
  }
}
