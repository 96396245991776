@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
}

.message {
  font-size: 16px;
  line-height: 22px;
  max-width: 352px;
  text-align: center;
  margin-bottom: 2rem;
}

.digits {
  margin-bottom: 1rem;
}

.img {
  height: 160px;
  margin-bottom: 3rem;

  @include lt-sm {
    height: 120px;
    margin-bottom: 2rem;
    img {
      height: 100%;
    }
  }
}

.skeleton {
  height: 2rem;
  margin-bottom: 0.75rem;
}

.resend {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-500;
  margin-bottom: 0.25rem;
  pointer-events: none;

  &.active {
    pointer-events: initial;
    color: var(--primary-600);
    cursor: pointer;
  }
}

.timer {
  height: 1.5rem;
  margin-bottom: 1.5rem;
}
