@import 'src/styles/variables';

.panel {
  position: relative;
  background-color: $gray-neutral-100;
  height: 3rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  max-width: calc(100% - 14rem);
}

.delete {
  border: none;
  color: $red-500;
  flex-shrink: 0;

  &:hover {
    color: $red-500;
  }
}

.title {
  border-left: 1px solid $gray-cold-200;
  padding-left: 0.25rem;
  line-height: 0;
  max-width: 100%;

  & > div {
    max-width: 100%;
  }
}

.input {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-width: calc(100% - 0.5rem);

  &:hover,
  &:focus {
    border-color: $primary-300;
  }
}

.dropDeleteModal {
  color: $yellow-500;
}

.titleTextModal {
  color: $gray-cold-900 !important;
}

.quizDescription {
  margin-left: 2.5rem;
  color: $gray-cold-500;
  font-size: 14px;
}

.quizBtnDelete {
  background-color: $red-100;
  color: $red-500;
  border: none;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  font-weight: 600;
  margin-left: 1rem;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $red-300;
  }
}
