@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 64px;
  height: 64px;
  color: $yellow-500;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 0.5rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-top: 0.25rem;
}
