@import 'src/styles/variables';

.container {
  margin-top: 0.5rem;
  position: relative;
}

.sender {
  color: $gray-cold-600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.375rem;
}
