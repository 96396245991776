@import 'src/styles/variables';

.divider {
  border-bottom: 1px solid $gray-cold-200;
  margin-bottom: 1rem;
  &.draft {
    border-bottom: 1px solid $gray-cold-300;
  }
}

.section {
  display: flex;
  flex-wrap: wrap;
}

.create {
  color: var(--primary-500);
  font-weight: 600;

  width: 100%;
  text-align: center;
  border-bottom: 1px solid $gray-cold-200;
  line-height: 0.1em;
  margin: 10px 0 20px;
  &.draft {
    border-bottom: 1px solid $gray-cold-300;
  }
}

.createSpan {
  background-color: $gray-neutral-200;
  padding: 0 1.5rem;
  &.draft {
    background-color: $gray-cold-200;
  }
}

.item {
  padding: 0.1875rem 0.5rem 0.1875rem 0.375rem;
  line-height: 1;
  margin: 0 0.1875rem;
  font-weight: normal;

  svg {
    margin-right: 0.125rem;
  }

  &.disabled {
    background: $gray-neutral-200;
    color: $gray-neutral-500;
    pointer-events: none;
  }

  &:disabled {
    background: $gray-neutral-200;
    color: $gray-neutral-500;
  }
}
