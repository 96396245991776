@import 'src/styles/variables';

.messageContainer {
  background: $gray-neutral-100;
  padding: 1rem 1.5rem 1rem 1rem;
  display: flex;
  align-items: center;
  width: 310px;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  user-select: none;
  pointer-events: none;

  &.publish {
    color: $green-600;
  }

  &.archive {
    color: $red-500;
  }
}

.icon {
  margin-right: 1rem;
}

.title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.25rem;
}

.message {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-600;
}
