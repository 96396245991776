@import 'src/styles/variables';

.container {
    position: relative;
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.titleContainer {
  position: relative;
}

.iconDrag {
  color: $primary-300;
  position: absolute;
  left: 0.25rem;
  top: 1rem;
  line-height: 0;
  z-index: 1;
  cursor: move;
}

.loader {
  background-color: rgba($gray-neutral-200, 0.99) !important;
}
