@import 'src/styles/variables';

.img {
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
}
.product {
  composes: img;
  background-color: var(--primary-100);
  color: var(--primary-500);
}
.course {
  composes: img;
  background-color: $orange-100;
  color: $orange-500;
}
