@import 'src/styles/variables';

.container {
  padding: 1rem;
  display: flex;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  align-items: center;
}

.title {
  font-size: 14px;
  -webkit-line-clamp: 1;
  width: 100%;
}

.containerPublish {
  @extend .container;
  background: var(--primary-100);
}

.containerDraft {
  @extend .container;
  background: $yellow-100;
}

.iconFolder {
  color: $yellow-500;
  margin-right: 0.5rem;
}

.iconLesson {
  color: $orange-500;
  margin-right: 0.5rem;
}
