@import 'src/styles/variables';

.container {
  display: flex;
}

.icon {
  color: $primary-600;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    color: $primary-400;
  }
  &:active {
    color: $primary-500;
  }
}

.checkmark {
  margin-left: 2px;
  color: $primary-500;
}

.label {
  color: $gray-cold-700;
  font-size: 12px;
  line-height: 18px;
}

.value {
  font-weight: 600;
  font-size: 14px;
}
