@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  color: $gray-cold-500;
  font-size: 12px;
  line-height: 18px;
  cursor: default;
}

.home {
  margin-right: 0.375rem;
}

.item {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  svg {
    flex-shrink: 0;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 155px;
}

.step {
  color: var(--primary-600);
  padding: 3px 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--primary-200);
}
