@import 'src/styles/variables';

.container {
  background: var(--primary-500);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.text {
  color: $gray-neutral-100;
  font-size: 12px;
  padding: 0 0.25rem;
}

.icon {
  color: $gray-neutral-100;
  cursor: pointer;
  margin-right: 2px;
  &:hover {
    color: var(--primary-500);
    background: $gray-neutral-100;
    border-radius: 50%;
  }
}
