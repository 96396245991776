@import 'src/styles/variables';

.kebabContainer {
  position: absolute;
  right: 1rem;
  top: 1rem;
  .iconKebab {
    color: $gray-cold-500;
  }
  .removeIcon {
    color: $red-500;
  }
  .textMenu {
    color: $gray-cold-700;
    font-size: 14px;
  }
  .itemDivider {
    margin: 0;
  }
  .itemMenu {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    &:hover {
      background: var(--primary-100);
    }
  }
  .dropdown {
    position: unset;
  }
}
