@import 'src/styles/variables';

.container {
  width: 100%;
  max-width: 352px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 1.5rem;

  span {
    display: block;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 10px;
    color: var(--primary-500);
  }
}

.logout {
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  cursor: pointer;
  color: var(--primary-600);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 16px;
}
