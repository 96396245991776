@import 'src/styles/variables';

.container {
  position: fixed;
  left: 6.25rem;
  bottom: 0.25rem;
  width: 20.75rem;
  background: $gradient;
  box-shadow: $shadow-raised;
  border-radius: 4px;
  padding: 2rem 1.5rem 1rem;
  color: $gray-neutral-100;
  z-index: 100;
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: $gray-neutral-100;

  &:hover {
    color: inherit;
    background: rgba(255, 255, 255, 0.35);
  }
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.25rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.003em;
  font-feature-settings: 'case' on;
  color: var(--primary-200);
  margin-bottom: 1.5rem;
}

.nav {
  margin-bottom: 1rem;
}

.item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0.125rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.35);

    .chevron {
      display: none;
    }

    .hovered {
      width: auto;
      color: $gray-neutral-100;
      transform: translateX(0);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.arrow {
  color: $primary-300;
  margin-left: auto;

  .hovered {
    width: 0;
    transform: translateX(-0.25rem);
    transition: transform 0.2s;
  }
}

.join {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 14px;
  line-height: 20px;
  background: var(--primary-500);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  min-height: 3.5rem;

  &:hover {
    background: var(--primary-600);
  }
}

.email {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    line-height: 22px;

    span {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: $primary-300;
    }
  }
}

.button {
  color: $gray-neutral-100;
  border-radius: 50%;
  padding: 0.75rem;
  background: rgba($gray-neutral-100, 0.15);

  &:hover {
    color: inherit;
    background: rgba($gray-neutral-100, 0.35);
  }
}
