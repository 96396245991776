@import 'src/styles/media-queries';

.logo {
  margin-right: 0.5rem;

  .icon {
    width: auto;
    height: 48px;

    @include lt-sm {
      height: 32px;
    }
  }
}
