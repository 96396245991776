@import 'src/styles/variables';

.conditionText {
  font-size: 12px;
  color: $gray-cold-700;
}

.conditionTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: flex-end;
}

.text {
  font-size: 14px;
  color: $gray-cold-500;
}

.bgCondition {
  background-color: $gray-cold-100;
  width: 88px;
  height: 1.5rem;
}
.bgCount {
  background-color: $gray-cold-200;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
  align-items: center;
}

.iconTrue {
  color: $green-500;
}

.iconFalse {
  color: $red-500;
}

.round {
  width: 16px;
  height: 16px;
  background-color: $gray-neutral-100;
  border-radius: 1.5rem;
  border: solid 1px #cce3ff;
  cursor: pointer;
}
