@import 'src/styles/variables';
@import 'src/styles/media-queries';

.container {
  background: $gray-neutral-100;
  border-radius: 0.25rem;
  box-shadow: $shadow-popping;
  overflow: hidden;
  margin-bottom: 0;

  .tabs {
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
    height: 3rem;
  }
}

.top {
  display: flex;
  align-items: center;
  padding: 0.6875rem 1rem;
  border-bottom: 1px solid $gray-cold-200;

  @include lt-lg {
    display: none;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;

  &.full {
    overflow: initial;
    white-space: normal;
  }
}

//.details {
//  font-weight: normal;
//  font-size: 12px;
//  margin-left: auto;
//}
