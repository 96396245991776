@import '../../../../../../../../../styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  color: $gray-cold-700;
  font-size: 14px;
}

.score {
  @extend .text;
  font-weight: 600;
}

.totalText {
  @extend .text;
  color: $gray-cold-500;
  .totalValue {
    font-weight: 600;
  }
}

.iconStar {
  color: $yellow-500;
}
