@import 'src/styles/variables';

.title {
  font-size: 14px;
  line-height: 1.43;
  color: $gray-cold-700;
}

.icon {
  flex-shrink: 0;
}
