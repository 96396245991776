@import 'src/styles/variables';

.container {
  background: $primary-100;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  &.active {
    background: $primary-200;
  }
}
