@import 'src/styles/variables';

.modalSize {
  max-width: 452px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    background: var(--primary-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .iconInfo {
      width: 64px;
      height: 64px;
      color: var(--primary-500);
      margin-top: 3.5rem;
    }

    .title {
      font-size: 24px;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  .descriptionContainer {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    .description {
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }
    .btn {
      margin-bottom: 3.5rem;
      width: 100%;
    }
  }
}
