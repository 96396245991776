@import 'src/styles/variables';

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: var(--primary-500);
  margin-bottom: 2rem;

  img {
    margin-left: 0.5rem;
  }
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 1rem;
  text-align: center;
}

.link {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-600);

  &:hover {
    color: var(--primary-500);
  }
}

.terms {
  font-size: 9px;
  line-height: 12px;
  color: $gray-cold-800;
  text-align: center;
}
