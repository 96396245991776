@import 'src/styles/variables';

.container {
  padding: 4rem 1.5rem;
  background: $gray-neutral-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.general {
  padding: 1rem 1.5rem 0.5rem;
  background: $gray-neutral-100;
}
