@import 'src/styles/variables';

.label {
  color: $gray-cold-600;
  font-size: 14px;
}

.container {
  position: relative;
  height: 100%;
}
