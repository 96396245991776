@import 'src/styles/variables';

.container {
  display: block;
}

.text {
  //height: 108px !important;
  border: 1px solid var(--primary-300);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.actions {
  gap: 0.5rem;
}
