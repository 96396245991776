@import 'src/styles/variables';

.container {
  color: $gray-cold-700;
  padding: 1.5rem 3rem 2rem !important;
}

.payment {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.25rem;
}

.card {
  margin-bottom: 1.5rem;

  svg {
    margin-right: 0.5rem;
  }
}

.note {
  font-size: 12px;
  line-height: 18px;
}
