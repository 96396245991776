@import 'src/styles/variables';

.text {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.007em;
  color: var(--primary-500);
  margin-bottom: 0.5rem;
}

.intro {
  width: 100%;
  max-width: 400px;
  position: relative;
  margin: 0 auto 1rem;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.trial {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
}
