@import 'src/styles/variables';

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 352px;

  .title {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
  }

  .description {
    font-size: 1rem;
    text-align: center;
    color: $gray-cold-700;
    margin-top: 0.25rem;
  }
}
