@import 'src/styles/variables';

.container {
  padding: 1.5rem 1rem;
  // height: 100vh;
  // overflow: hidden;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1.5rem;
}
