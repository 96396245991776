@import 'src/styles/variables';

.container {
  position: relative;
}

.container + .container {
  margin-top: 1.5rem;
}

.customCodeContainer {
  height: 160px;
  margin-bottom: 4rem;
}

.wrapperContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  position: relative;
}

.iconDrag {
  color: $primary-300;
  position: absolute;
  left: 0.25rem;
  top: 1rem;
  line-height: 0;
  z-index: 1;
  cursor: move;
}

.settings {
  padding-top: 1rem;
  border-top: 1px solid $gray-cold-200;
}

.blockTitle {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  .icon {
    color: var(--primary-500);
    margin-right: 0.5rem;
  }
}

.loader {
  background-color: rgba($gray-neutral-200, 0.99) !important;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}
