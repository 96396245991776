@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.25rem;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
}

.btnSize {
  width: 100%;
}

.attach {
  margin-bottom: 1rem;
  iframe {
    max-width: unset !important;
    max-height: 432px;
    width: 100%;
  }
}
