@import 'src/styles/variables';

.options {
  margin-bottom: 1.5rem;
}

.listItem {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.listItem + .listItem {
  margin-top: 0.5rem;
}

.icon {
  color: var(--primary-500);
  margin-right: 0.25rem;
}

.iconPopular {
  color: $primary-300;
}
