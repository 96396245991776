@import 'src/styles/variables';

.container {
  border-top: 1px solid var(--primary-200);
  display: flex;
  width: 100%;
  background-color: $gray-neutral-100;
  z-index: 1;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: var(--primary-200);
    cursor: pointer;

    .icon {
      color: var(--primary-500);
    }
  }
}

.title {
  font-size: 12px;
  color: $gray-cold-700;
}

.text {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-600);
  -webkit-line-clamp: 1;
}

.icon {
  color: $gray-cold-500;
  margin-right: 0.688rem;
}
