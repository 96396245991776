@import 'src/styles/variables';

.container {
  padding: 0.75rem 1rem;
  box-shadow: $shadow-popping;
  min-width: 15.5rem;
  max-width: 248px;
}

.organization {
  display: block;
}

.organizationList {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $gray-cold-200;
}

.organizationName {
  cursor: default;
}

.leaveOrganization {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  cursor: default;
  padding: 0.5rem 0;

  &:hover {
    color: $red-500;
  }
}

.separator {
  border-bottom: 1px solid $gray-cold-200;
  margin: 0.25rem 0;
}

.item {
  font-size: 14px;
  color: $gray-cold-900;
  cursor: pointer;
  display: block;
  line-height: 20px;
  padding: 0.25rem 0;

  &:hover {
    color: var(--primary-500);
    text-decoration: none;
  }
}

.organizationText {
  color: $gray-cold-900;
  line-height: 20px;
  -webkit-line-clamp: 3;
}

.role {
  color: $gray-cold-500;
  cursor: default;
  font-size: 12px;
}

.logoutLink {
  color: $gray-cold-900;
  cursor: pointer;

  &:hover {
    color: var(--primary-500);
  }
}

.logout {
  color: $gray-cold-500;
}

.unActiveSubscribe {
  color: $gray-cold-400;
}

.organizationInfo {
  position: relative;
  padding: 0.5rem 0;
  cursor: default;
}

.member {
  color: $gray-cold-700;
  position: absolute;
  top: 0.5rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: $gray-neutral-100;
  opacity: 0;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.name {
  font-size: 14px;
  line-height: 20px;
}

.email {
  font-size: 12px;
  line-height: 18px;
}

.cabinet {
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid $gray-cold-200;
  padding-bottom: 0.5rem;

  .link {
    color: $primary-600;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
  }
}
