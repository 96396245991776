@import 'src/styles/variables';

.form {
  //padding: 2rem;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  padding: 2rem 2rem 1rem;
  border-bottom: 1px solid $gray-neutral-300;
}

.body {
  padding: 1rem 2rem;
}

.notes {
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}


.actionButtons {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 1.5rem;
  text-align: right;
  box-shadow: $shadow-floating;
  z-index: 1;
}

.iconModal {
  color: $yellow-500;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.btnSave {
  border: none;
  background-color: transparent;
  color: var(--primary-600);
  font-weight: 600;
  margin-right: 1rem;
}

.description {
  margin-left: 2.5rem;
  margin-top: 0.75rem;
}

.iconClose {
  color: $gray-cold-500;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  cursor: pointer;
}
