@import 'src/styles/variables';

.container {
  height: 128px;
  background-image: url('../../../../../assets/images/dashboard.png'), $gradient;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-neutral-100;
  border-radius: 0.5rem 0.5rem 0 0;
}
