@import 'src/styles/variables';

.containerShadow {
  box-shadow: $shadow-floating;
  overflow: hidden;
  border-radius: 0.5rem;
}

.header {
  background-color: $gray-neutral-100;
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;
}

.status {
  // margin: ;
}

.breadcrumbs {
  display: flex;
  color: var(--primary-400);
}

.open {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;

  button {
    margin-left: 0.25rem;
    font-size: 14px;
    padding: 0 0.25rem;
    line-height: 1.5rem;
    font-weight: normal;
  }
}

.passing {
  padding: 0.5rem 2.5rem;
  background-color: var(--primary-100);
  border-radius: 0 0 0.5rem 0.5rem;
}

.content {
  margin-top: 0.25rem;
  background-color: var(--primary-100);
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
}

.quizQuestion {
  box-shadow: $shadow-floating;
}

.color {
  color: var(--primary-400);
}
