@import 'src/styles/variables';

.text {
  font-size: 14px;
  color: $gray-cold-900;
  ::placeholder {
    color: $gray-cold-500;
  }
}

.titleListAttach {
  font-size: 14px;
  color: $gray-cold-700;
  font-weight: normal;
}
