@import 'src/styles/variables';

.actionButtons {
  display: flex;
  padding: 0.75rem 1.5rem;
  text-align: right;
  box-shadow: $shadow-floating;
  z-index: 1;
}

.save {
  width: 7rem;
}

.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: $gray-cold-500;
}

.studentId {
  display: flex;
  align-items: flex-end;
  min-height: 3rem;
  padding: 0 1.5rem;
}

.analytics {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.tabs {
  display: flex;
  gap: 1rem;

  :global &.nav-tabs {
    .nav-link {
      &.disabled {
        pointer-events: auto;
        font-weight: normal;
      }
    }
  }
}
