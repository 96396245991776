@import 'src/styles/variables';

.icon {
  color: $red-500;
}

.description {
  color: $gray-cold-500;
  font-size: 14px;
  line-height: 20px;
  padding-left: 2.5rem;
  padding-right: 3.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.checkboxIcon {
  color: $primary-500;
}
