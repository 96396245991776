@import 'src/styles/variables';

.container {
  border-bottom: 1px solid $gray-cold-300;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  max-width: 768px;
  &.billing {
    border: none;
  }
}

.title {
  font-weight: 600;
  font-size: 64px;
  line-height: 1;
  color: $yellow-600;
  margin-bottom: 1rem;
}

.description {
  color: $gray-cold-700;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 1rem;
}

.btn {
  padding: 14px;
}
