@import 'src/styles/variables';

.containerCollapsedHeader {
  background: var(--primary-200);
  padding: 1rem 1.5rem;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: $shadow-floating;
}
