@import 'src/styles/variables';

.label {
  font-size: 12px;
  padding: 0 0.5rem;
  line-height: calc(1.5rem - 2px);
  border-radius: 2px;
  user-select: none;
  text-transform: capitalize;
}

:global .state.published {
  background: $green-100;
  color: $green-700;
  border: 1px solid $green-300;
}

:global .state.draft {
  background: $yellow-100;
  color: $yellow-700;
  border: 1px solid $yellow-400;
}

:global .state.archived {
  background: $red-100;
  color: $red-700;
  border: 1px solid $red-300;
}

:global .state.connecting {
  background: $gray-cold-100;
  color: $gray-cold-700;
  border: 1px solid $gray-cold-400;
}
