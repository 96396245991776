@import 'src/styles/variables';

.container {
  padding:0 2.5rem 0.5rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1rem;
}

.loading {
  color: var(--primary-500);
  display: flex;
  justify-content: center;
  margin-bottom: -1.5rem;
}

.select {
  width: 18px;
  height: 18px;
  background-color: $gray-neutral-100;
  border-radius: 0.25rem;
  border: 1px solid $primary-300;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
}

.checkmark {
  position: absolute;
  top: -4px;
  left: -4px;
}

.noOption {
  padding: 1.5rem 3rem;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-600;

  span {
    color: var(--primary-500);
  }
}

.error {
  color: $red-500;
}
