@import 'src/styles/variables';
@import 'src/styles/theme/buttons';

.impersonationButton {
  z-index: 99999;
  position: absolute;
  padding: 10px 0 10px 15px;
  box-shadow: $shadow-floating;
  top: 20vh;
  right: 0;
  border-radius: 5px 0 0 5px;
  background-color: var(--secondary);
}

.usersContainer {
  min-width: 550px;
  background-color: var(--secondary);
  max-height: 70vh;
  overflow-y: auto;
  margin-top: 5px;
  padding-right: 10px;
}

.avatar {
  border-radius: 50%;
  box-shadow: $shadow-floating;
}

.userListItem:hover:not(.userSelectedListItem) {
  box-shadow: $shadow-popping;
  background-color: #ecf0f1;
}

.userListItem {
  cursor: pointer;
  margin: 1rem 0;
  padding: 2rem;
  background-color: $gray-neutral-100;
  font-size: 18px;
  color: $gray-neutral-900;
  box-shadow: $shadow-floating;
  border-radius: $border-radius;
}

.userSelectedListItem {
  background-color: #70a1ff;
  color: var(--gray-dark);
}

.searchInput {
  padding: 0.5rem;
  border: none;
  border-radius: 9px;
  background: none;
  box-shadow: $shadow-popping;

  &:focus {
    outline: none;
    box-shadow: $shadow-floating;
  }
}

.depersButton {
  margin-left: 1rem;
  border-radius: 9px;
  border: none;
  box-shadow: $shadow-popping;
}
