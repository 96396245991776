@import 'src/styles/variables';

.number {
  background-color: $red-500;
  color: $gray-neutral-100;
  border-radius: 20px;
  width: 12px;
  height: 12px;
  font-size: 11px;
  margin-right: 0.25rem;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: $red-500;
  font-size: 12px;
}
