@import 'src/styles/variables';

.shadowBlock {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #f5f9ff 38.24%, rgba(245, 249, 255, 0) 92.16%);
  height: 152px;
  pointer-events: none;

  &.open {
    position: initial;
    height: auto;
    background: none;
    padding-top: 1.5rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
  }
}

.expand {
  margin-bottom: 1rem;
  width: 206px;
  justify-content: center;
  pointer-events: all;

  .open & {
    margin-bottom: 0.5rem;
  }
}
