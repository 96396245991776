@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  color: var(--primary-500);

  .icon {
    margin-right: 0.5rem;
  }

  input[type='range'] {
    cursor: pointer;
    -webkit-appearance: none;
    height: 8px;
    background: $primary-100;
    border-radius: 1px;
    background-image: linear-gradient(var(--primary-500), var(--primary-500));
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background: transparent;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
