@import 'src/styles/variables';

.inputContainer {
  max-width: 288px;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.inputContainer + .inputContainer {
  margin-left: 1rem;
}

.label {
  color: $gray-cold-600;
}

.input {
  color: $gray-cold-900;
}
