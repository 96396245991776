@import 'src/styles/variables';

.tabsContainer {
  background: $gray-neutral-100;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  border-width: unset;
}

.tabBtnContainer {
  border-radius: 2px;
  padding: 0;
  border-width: 0;
  font-weight: normal;
  font-size: 14px;
  width: 92px;
  height: 32px;
  background-color: var(--primary-100);
  color: var(--primary-500);
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    border-color: transparent;
    color: $gray-neutral-100;
    background-color: var(--primary-500);
  }
  &:hover:not(.active) {
    border-color: transparent;
    background-color: var(--primary-200);
  }
}

.tabContainer + .tabContainer {
  margin-left: 2px;
}
