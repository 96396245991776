@import 'src/styles/variables';

.container {
  background-color: $gray-neutral-100;
  border-bottom: 1px solid $gray-cold-300;
}

.wrapper {
  width: 256px;
  border-radius: 0.25rem;
  border: solid 1px $gray-cold-300;
  overflow: hidden;
}

.textContainer {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $gray-cold-700;
  background-color: $gray-cold-200;
  padding: 0.75rem;
}

.bgContainer {
  background-color: $gray-neutral-100;
  border-top: solid 1px $gray-cold-300;
  padding: 0.625rem 0.75rem;
}

.title {
  font-size: 14px;
  line-height: 1.43;
  color: $gray-cold-700;

  &.funnel {
    position: initial;
  }
}

.icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  :global svg {
    width: 100%;
    height: 100%;
  }
}

.circle {
  border-radius: 50%;

  :global .btn-delete {
    left: -12px;
    bottom: -12px;
  }
}

.pageOutput {
  display: none;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  color: var(--primary-400);
  font-size: 14px;
  font-weight: 600;
  padding: 0.375rem 1rem;
  border: none;
  background: $gray-neutral-100;
  box-shadow: $shadow-raised;
  border-radius: 0.25rem;

  &:hover {
    color: var(--primary-600);
  }
}

:global .element-node {
  &:hover {
    :local .pageOutput {
      display: block;
    }
  }
}

:global .selected {
  :local .pageOutput {
    display: block;
  }
}
