@import 'src/styles/variables';

.container {
  border-radius: 0.25rem;
  background: $gray-neutral-200;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text {
  color: var(--primary-600);
  font-weight: 600;
  font-size: 14px;
}
