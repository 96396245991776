@import 'src/styles/variables';

.container {
  padding: 3.5rem 3.5rem 2rem;
}

.label {
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
  text-align: center;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 1.5rem;
  text-align: center;
}
