@import 'src/styles/variables';

.content {
  max-width: 424px;
}

.header {
  font-weight: 600;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
}

.label {
  color: $gray-cold-600;
  font-size: 14px;
  line-height: 20px;
}

.previewLabel {
  color: $gray-cold-700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0.25rem;
}

.preview {
  color: $gray-cold-700;
}
