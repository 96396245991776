@import 'src/styles/variables';

.btn {
  &:disabled {
    svg path {
      fill: #fff;
    }
  }
}

.btnText {
  margin-left: 0.5rem;
}

.errorMessage {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-size: 12px;
  color: $red-600;
  text-align: center;
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
  text-align: center;
}
