.textContainer {
  max-width: 512px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
