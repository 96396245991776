@import 'src/styles/variables';

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.pointer {
  cursor: pointer;
}

.hideCaret {
  caret-color: transparent;
}

.iconContainer {
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
}

.icon {
  color: var(--primary-500);
  margin-bottom: 1rem;
}

.header {
  background: $gray-cold-200;
  padding: 2rem 2rem 2rem 3rem;
  user-select: none;
}

.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.6px;
  color: $gray-cold-900;
}

.description {
  font-size: 14px;
  color: $gray-cold-700;
}

.content {
  padding: 2rem 2rem 0.5rem 3rem;
}

.bgContainer {
  background-color: $gray-neutral-200;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
}

.text {
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
}

.textField {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
  display: flex;
  align-items: flex-end;
}

.container {
  padding: 2rem;
}

.containerModal {
  display: flex;
  margin-top: 1rem;
}

.containerOptions {
  display: flex;
}

.btnOption {
  max-height: 2rem;
  margin-left: 0.5rem;
  border: none !important;
}

.textColor {
  color: $gray-cold-700;
}

.draggableIcon {
  margin-left: -30px;
  margin-right: 0.5rem;
  svg {
    color: $gray-cold-500;
  }
}

.darkContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 99;
  background-color: #001733;
}

.posRel {
  position: relative;
}

.height {
  height: 100%;
}

.defaultBG {
  background-color: $gray-neutral-100 !important;

  &:focus {
    background-color: $gray-neutral-100 !important;
  }
}

.fieldIcon {
  color: $gray-cold-500;
}
