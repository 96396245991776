@import "src/styles/variables";

.courseCategory {
  margin-bottom: 1rem;
}

.title {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.label {
  position: relative;
  margin: 0 0.5rem 0.5rem 0;
  user-select: none;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .category {
    background-color: var(--primary-500);
    color: $gray-neutral-100;
  }
}

.category {
  border-radius: 12px;
  padding: 0.25rem 0.5rem 0.25rem 0.375rem;
  background-color: $gray-neutral-100;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-500);
  display: flex;
  align-items: center;

  &.active {
    background-color: var(--primary-500);
    color: $gray-neutral-100;
  }

  .icon {
    margin-right: 0.125rem;
  }
}
