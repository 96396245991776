@import 'src/styles/variables';

.container {
  background: $yellow-100;
  border: 1px solid $yellow-400;
  box-sizing: border-box;
  border-radius: 16px;
  align-items: center;
  padding: 0.75rem;
  &.hide {
    display: none;
  }
}

.text {
  font-size: 12px;
  color: black;
}

.icon {
  color: $yellow-500;
  margin-right: 0.5rem;
}

.error {
  background: $red-100;
  border: 1px solid $red-300;
  svg {
    color: $red-500;
  }
}
