@import 'src/styles/variables';

.iconLink {
  color: var(--primary-500);
}

.iconCopy {
  color: $gray-cold-500;
  font-size: 14px;
  cursor: pointer;
  margin-top: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-900 !important;

  .main {
    color: var(--primary-500);
  }
}

.sharableDescription {
  color: $gray-cold-500;
  font-size: 14px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
}

.shareableTitle {
  font-size: 24px;
  color: black;
  margin-left: 1rem;
}

.sharedText {
  font-size: 16px;
  color: $gray-cold-900;
  margin-top: 1rem;
}

.containerModal {
  padding-bottom: 4rem !important;
}
