@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  padding: 1rem 1.5rem;
}

.captionContainer {
  padding: 0.5rem 1.5rem 1rem 1.5rem;
}

.titleThread {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.breadControls {
  display: flex;
  gap: 0.5rem;
}
.control {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $primary-500;
  cursor: pointer;
  &:nth-child(2n) {
    padding-left: 0.5rem;
    border-left: 1px solid $gray-cold-300;
  }
}

.breadcrumbsContainer {
  display: flex;
  justify-content: space-between;
  .breadWrapper {
    display: flex;
  }
  .breadItem {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $gray-cold-500;
  }
}

.title {
  text-decoration: none !important;
  color: $primary-500;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.previewContainer {
  background: $primary-100;
  padding: 0.25rem;
  margin-top: 1rem;
  pointer-events: none;
}
