@import 'src/styles/variables';

.cancelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  .cancelText {
    font-size: 12px;
    line-height: 18px;
    color: $red-500;
    margin-right: 0.5rem;
    display: none;
  }

  .canceledIcon {
    border-radius: 50%;
    background: $gray-neutral-100;
    color: $gray-cold-500;
  }
  &:hover {
    .canceledIcon {
      background: $red-500;
      color: $gray-neutral-100;
    }
    .cancelText {
      display: block;
    }
  }
}

.checkMarkIcon {
  background: $green-200;
  color: $green-500;
  border-radius: 50%;
}
.sizeLoader {
  width: 24px;
  height: 24px;
}

.text {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}

.canceled {
  @extend .text;
}

.errorText {
  @extend .text;
  color: $yellow-700;
}

.spinnerSize {
  width: 24px;
  height: 24px;
}

.processingText {
  @extend .text;
  margin-right: 0.5rem;
}
