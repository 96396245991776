@import 'src/styles/variables';

.container {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray-cold-700;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 16px;
  line-height: 22px;
  max-width: 332px;
}
