@import 'src/styles/variables';

.imageContainer {
  display: block;
  min-height: 432px;
  padding: 1.5rem;
  border-radius: 0.25rem;
  background-color: $gray-neutral-100;

  &.imageContainerCustomization {
    min-height: 428px;
    padding: 0;
  }
}

.empty {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(432px - 3rem);
  margin-bottom: 0;
  cursor: pointer;
  border: 1px dashed $primary-300;
  border-radius: 0.25rem;

  &::after {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    content: '';
    transition: box-shadow 0.33s ease-in-out;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 2px transparent;
  }

  &:hover {
    &::after {
      box-shadow: inset 0 0 0 2px $primary-300;
    }
  }

  &.emptyCustomization {
    min-height: calc(428px - 3rem);
  }

  .icon {
    width: 64px;
    height: 64px;
    margin-bottom: 0.5rem;
    color: var(--primary-500);
  }

  .text {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .note {
    text-align: center;
    color: $gray-cold-500;
    font-size: 12px;
    line-height: 18px;
  }
}

.input {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.image {
  width: 100%;
  height: 432px;
  transition: opacity 0.2s ease-in;
  opacity: 0;
  object-fit: cover;

  &.imageCustomization {
    height: 428px;
  }

  &.loaded {
    opacity: 1;
  }
}

.imageActions {
  display: flex;
}

.blurContainerUploader {
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 23, 51, 0.5);
  backdrop-filter: blur(5px);

  &:after {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    content: '';
    pointer-events: none;
    border: 1px dashed $gray-neutral-100;
  }

  .isOverPanel {
    position: absolute !important;
    z-index: 99;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    width: auto !important;
  }

  .wrapper {
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3px;

    .cancelWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .cancelContainer {
        z-index: 100;
        width: 64px;
        height: 64px;
        transition: height 0.1s, width 0.1s;
        opacity: 0.25;
        border-radius: 50%;
        background: $gray-neutral-100;

        &.isOverCancel {
          width: 80px;
          height: 80px;
        }
      }

      .cancel {
        position: absolute;
        z-index: 110;
        padding: 0.5rem;
        color: $gray-neutral-100;
        border: 1px solid $gray-neutral-100;
        border-radius: 50%;
      }
    }
  }

  .cancelText {
    z-index: 100;
    color: $gray-neutral-100;
    font-size: 14px;
  }

  .dropContainer {
    height: inherit;
    text-align: center;

    .dropText {
      color: $gray-neutral-100;
      font-size: 32px;
      font-weight: 600;
    }

    .dropTextDescribe {
      color: $gray-neutral-100;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
