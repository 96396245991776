@import 'src/styles/variables';

.header {
  background: $primary-100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  .paynet {
    color: #ec6665;
  }

  svg {
    height: 80px;
    width: 80px;
  }

  .divider {
    width: 2px;
    background: $gray-cold-300;
    margin: 0 2rem;
    height: 80px;
  }
}
