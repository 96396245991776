@import 'src/styles/variables';

.header {
  overflow: hidden;
  position: relative;
  margin-bottom: 1px;
}

.steps {
  gap: 0.25rem;
}

.step {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  color: $gray-cold-500;
}
