@import 'src/styles/variables';

@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == 'lg' {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == 'xl' {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  .custom-control-label {
    padding-left: calc(#{$index} + 1.25rem);
    padding-bottom: 0;
    min-height: 1.5rem;
  }

  .custom-control-label::before {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
    background-color: $gray-cold-400;
    border: none;
    left: 0;
  }

  .custom-control-label::after {
    width: calc(#{$mainVal} - 6px);
    height: calc(#{$mainVal} - 6px);
    border-radius: calc(#{$index} - (#{$mainVal} / 2));
    border: solid 3px $gray-cold-400;
    background-color: $gray-neutral-100;
    left: 0;
    top: 0.25rem;
    box-sizing: content-box;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(#{$mainVal} - 0.25rem));
    border: solid 3px var(--primary-500);
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary-300;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    border: solid 3px var(--primary-300);
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: var(--primary-200);
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: $gray-cold-200;
  }
}

// sm - DEFAULT, md, lg, xl
.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch('md');
}

.custom-switch.custom-switch-lg {
  @include switch('lg');
}

.custom-switch.custom-switch-xl {
  @include switch('xl');
}

.custom-control {
  z-index: unset;
}

.custom-switch {
  .custom-control-label::before {
    //left: 0;
  }
}

.custom-switch.custom-switch-md.switch-right {
  .custom-control-label {
    padding-right: calc(2rem + 1.25rem);
    padding-left: 0 !important;
    width: 100%;

    &:after {
      left: auto;
      right: 0;
      top: 0;
      transform: translateX(-19px);
    }

    &:before {
      left: auto;
      right: 0;
      top: 0;
    }
  }
}
