@import 'src/styles/variables';

.wrapper {
  position: relative;
  height: 100%;
}

.container {
  background: var(--primary-200);
  padding: 1rem;
  border-radius: 0.25rem;
  min-height: 80px;
  display: flex;
  align-items: center;
  &.mobile {
    min-height: 138px;
    padding: 1.5rem;
  }
}

.duration {
  margin-right: 1.5rem;
  text-align: end;
}

.currentPosition {
  margin-left: 1.5rem;
  text-align: start;
}

.iconTrash {
  color: $red-500;
  margin-left: 1.5rem;
  cursor: pointer;
  &:active {
    color: $red-600;
  }
  &:hover {
    color: $red-400;
  }
}
