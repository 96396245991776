@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-800;
}

.dot {
  color: $gray-cold-500;
  margin: 0.375rem;
}
