@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  display: flex;
  justify-content: center;
  align-self: stretch;
}

.img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  box-sizing: content-box;
  border: 2px solid transparent;
  padding: 2px;
}

:global {
  .show {
    :local .img {
      border: 2px solid var(--primary-500);
    }
  }
}

.wrapper {
  width: 248px;

  @include gt-md {
    transform: translate(100px, 20px) !important;
  }

  @include lt-lg {
    transform: translate(1px, 54px) !important;
  }

  @include lt-sm {
    width: calc(100vw - 16px);
    transform: translate3d(0px, 64px, 0px) !important;
  }
}

.logoutLink {
  color: $gray-cold-500;
  cursor: pointer;
  border: none;
  padding: 0;

  &:hover {
    color: var(--primary-500);
  }
}

.logout {
  padding: 1rem;
  width: 100%;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-900;
}

.email {
  white-space: pre-wrap;
  word-break: break-word;
  color: #8a9aa8;
  font-size: 12px;
  line-height: 18px;
}

.divider {
  margin: 0 1rem;
}
