@import "src/styles/variables";

.imagePlaceholder {
  display: flex;
  background-color: var(--primary-200);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--primary-500);
  border-radius: 0.25rem;
}
