.container {
  display: block;
}

@media (min-width: 576px) {
  .modal {
    max-width: 724px;
  }
}

.containerModal {
  background-color: transparent;
}
