@import 'src/styles/variables';

.form-group {
  margin-bottom: 0.25rem;
}

.form-label {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.29px;
  color: $gray-cold-700;
  margin-bottom: 0.375rem;
}

.form-control {
  color: $gray-cold-900;
  transition: border-color 0.2s;

  &:disabled,
  &[readonly] {
    border-color: $gray-neutral-300;
    color: $gray-cold-500;
  }

  &.invisible-control {
    padding: 0;
    height: auto;
    border: none;
  }

  .has-icon & {
    padding-left: 1.75rem;
  }

  &:hover:not(:disabled):not(.is-invalid) {
    border-color: $input-hover-border-color;
  }

  &.invalid {
    border-color: $red-500 !important;
  }
}

.form-control-lg {
  font-size: 14px;
  height: 48px;
}

.help-text {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
}

.form-control-underline {
  border-radius: 0;
  border-width: 0 0 1px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: -0.3px;

  &.form-control-lg {
    height: 35px;
    padding: 0;
  }

  &:disabled,
  &[readonly] {
    border-color: $gray-neutral-300;
    color: $gray-cold-900;
  }
}

.custom-control-label {
  font-size: 14px;
  color: $gray-cold-700;
  padding-left: 1.5rem;
  cursor: pointer;
  line-height: 1.5rem;
  min-height: 1.5rem;
  user-select: none;

  &:before {
    color: #fff;
    border-color: var(--primary-300);
    left: 0;
    top: 0.25rem;
  }

  &:hover {
    &:before {
      border-color: var(--primary-400);
    }
  }

  &:active {
    &:before {
      border-color: var(--primary-500);
    }
  }
}

.radio-button {
  .custom-control-label {
    &:after {
      position: absolute;
      top: 7px;
      left: 3px;
      display: block;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
    }
  }
}

.checkbox-button {
  .custom-control-label {
    &:before {
      width: 18px;
      height: 18px;
      top: 0.2rem;
    }

    &:after {
      position: absolute;
      top: 0.2rem;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      content: '';
      border-radius: 0;
      background-size: 100%;
    }
  }
}

.custom-control {
  padding-left: 0;
  display: flex;
  align-items: center;
}

.custom-control-input {
  &:checked ~ .custom-control-label:before {
    background-color: transparent;
    border-color: var(--primary-300);
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: transparent;
    border-color: var(--primary-500);
  }
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-label:after {
    background-color: var(--primary-500);
    background-image: none;
  }
}

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label:after {
    background-color: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwNzJmZiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4yOTMgOC43OTNjLjM5LS4zOSAxLjAyNC0uMzkgMS40MTQgMCAuMzYuMzYuMzg4LjkyOC4wODMgMS4zMmwtLjA4My4wOTQtNyA3Yy0uMzYuMzYtLjkyOC4zODgtMS4zMi4wODNsLS4wOTQtLjA4My00LTRjLS4zOS0uMzktLjM5LTEuMDI0IDAtMS40MTQuMzYtLjM2LjkyOC0uMzg4IDEuMzItLjA4M2wuMDk0LjA4M0wxMSAxNS4wODVsNi4yOTMtNi4yOTJ6Ii8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
    background-size: 24px;
    background-position: -4px -4px;
  }
}

.custom-switch {
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: $gray-cold-400;
  }
}

.custom-switch {
  &.custom-switch-sm {
    &.custom-switch-inverse {
      .custom-control-label {
        &::before {
          background-color: #99c7ff;
        }

        &::after {
          transform: translateX(calc(1rem - 0.25rem));
          border: solid 3px #0072ff;
        }
      }

      .custom-control-input {
        &:checked ~ .custom-control-label {
          &::before {
            background-color: #bfcdd9;
          }

          &::after {
            border: solid 3px #bfcdd9;
            transform: none;
          }
        }
      }
    }
  }
}

.select-field-underline {
  font-size: 0.875rem;

  .select-field {
    &__control {
      border-color: $input-border-color;
      border-radius: 0;
      border-width: 0 0 1px;
      box-shadow: none;
      min-height: 31px;
      letter-spacing: -0.3px;

      &--is-focused {
        border-color: var(--primary-500);
      }

      &--menu-is-open {
        .select-field__indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      color: $gray-cold-900;
    }

    &__indicators {
    }

    &__indicator {
      padding: 5px;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      box-shadow: $shadow-floating;
      z-index: 2;
    }

    &__menu-list {
    }

    &__option {
      padding: 0.375rem 0.5rem;
      border-width: 1px 0;
      border-style: solid;
      border-color: transparent;
      line-height: 1.43;
      letter-spacing: -0.3px;
      color: $gray-cold-700;

      &--is-focused {
        border-color: $gray-cold-200;
        background-color: var(--primary-100);
        color: $gray-cold-700;
      }

      &--is-selected {
        border-color: transparent;
        background-color: #fff;
        color: $gray-cold-900;

        &.select-field__option--is-focused {
          border-color: $gray-cold-200;
          background-color: var(--primary-100);
          color: $gray-cold-700;
        }
      }
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0;
  color: $red-500;
}

.predefined-control {
  &::placeholder {
    color: $gray-cold-900;
  }
}
