@import "src/styles/variables";

.count {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-500);
  background: $gray-neutral-100;
  border-radius: 12px;
  margin-right: auto;
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem;
}

.export {
  width: 7.875rem;
}
