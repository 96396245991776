@import 'src/styles/variables';

.blurContainerUploader {
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 23, 51, 0.5);
  backdrop-filter: blur(5px);

  &:after {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    content: '';
    pointer-events: none;
    border: 1px dashed $gray-neutral-100;
  }

  .isOverPanel {
    position: absolute !important;
    z-index: 99;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    width: auto !important;
  }

  .wrapper {
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3px;

    .cancelWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .cancelContainer {
        z-index: 100;
        width: 64px;
        height: 64px;
        transition: height 0.1s, width 0.1s;
        opacity: 0.25;
        border-radius: 50%;
        background: $gray-neutral-100;

        &.isOverCancel {
          width: 80px;
          height: 80px;
        }
      }

      .cancel {
        position: absolute;
        z-index: 110;
        padding: 0.5rem;
        color: $gray-neutral-100;
        border: 1px solid $gray-neutral-100;
        border-radius: 50%;
      }
    }
  }

  .cancelText {
    z-index: 100;
    color: $gray-neutral-100;
    font-size: 14px;
  }

  .dropContainer {
    height: inherit;
    text-align: center;

    .dropText {
      color: $gray-neutral-100;
      font-size: 32px;
      font-weight: 600;
    }

    .dropTextDescribe {
      color: $gray-neutral-100;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
