@import 'src/styles/variables';

.container {
  width: 100%;
  max-width: 512px;
  padding: 60px 80px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.765) 0%, rgba(255, 255, 255, 0.68) 100%);
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
  border-radius: 1rem;
  border: 2px solid $gray-neutral-100;
  margin-bottom: 1.5rem;
}
