@import 'src/styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:hover {
    background-color: var(--primary-100);
    border-radius: 0.25rem;
  }
}

.label {
  font-size: 14px;
  color: $gray-cold-500;
  margin-left: 0.5rem;
}

.img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.imageUser {
  background-color: var(--primary-200);
  color: var(--primary-500);
  composes: img;
}

.imageInvitation {
  border-radius: 50%;
  background-color: $gray-cold-100;
  color: $gray-cold-400;
  border: 1px solid $gray-cold-400;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
  }
}

.name {
  font-size: 14px;
  line-height: 20px;
  cursor: default;
}

.plainText {
  user-select: none;
}

.invitationText {
  font-size: 12px;
  color: $gray-cold-500;
}

.textResend {
  cursor: pointer;
  font-size: 12px;
  color: var(--primary-500);
  margin-left: 2px;
  display: flex;
}

.email {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
}
