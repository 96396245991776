@import 'src/styles/variables';

.container {
  padding-top: 1rem;
  padding-bottom: 2rem;
  flex-wrap: nowrap;
}

.bgContainer,
.bgContainer:focus {
  background-color: $gray-neutral-200;
  line-height: 1.43;
  letter-spacing: -0.3px;
  font-size: 14px;

  &::placeholder {
    color: $gray-cold-500;
  }
}

.icon {
  width: 32px;
  height: 32px;
  color: $gray-cold-500;
}

.closeIcon {
  cursor: pointer;
  margin-top: 1.3rem;
}

.crateTitle {
  color: $gray-cold-700;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.fieldNameContainer {
  margin-bottom: 1rem;
  position: relative;
}

.fieldTitle {
  color: $gray-cold-700;
  line-height: 1.43;
  letter-spacing: -0.3px;
  font-size: 14px;
  margin-bottom: 4px;
}

.selector {
  background-color: $gray-cold-500;
}
