@import 'src/styles/variables';

.container {
  border-bottom: 1px solid $gray-neutral-300;
  margin-right: -2rem;
  margin-left: -2rem;
  padding: 0.25rem 2rem 1rem;
}

// .containerTop {
//   composes: container;
//   border-bottom: unset;
//   border-top: 1px solid $gray-neutral-300;
// }
