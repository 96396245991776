@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.disconnect {
  color: $red-500;
}

.iconRemove {
  color: $red-500;
}

.description {
  color: $gray-cold-500;
  font-size: 14px;
  padding: 0 2.5rem;
  margin-top: 0.5rem;
}
