@import 'src/styles/variables';

.container {
  max-width: calc(100% - 160px);
  min-width: 1172px;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.content {
  display: flex;
  height: calc(100% - 160px);
  flex-direction: column;
  background-clip: border-box;
}

.wrapper {
  //max-width: 630px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 1.5rem auto 0.5rem;
  flex-grow: 1;
  min-height: 0;
}

.body {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.subject {
  margin-bottom: 1.5rem;
  padding: 0 calc((100% - 600px)/2);
}

.message {
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
  padding: 0 calc((100% - 630px) / 2) 3rem;
}

.insert {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  left: calc((100% - 630px)/2 + 1rem);
  border: 1px solid $gray-cold-300;
  box-sizing: border-box;
  box-shadow: $shadow-popping;
  border-radius: 16px;
  height: 2rem;
  white-space: nowrap;
  font-size: 14px;
  background: $gray-neutral-100;
  padding: 0.375rem 1rem 0.375rem 0.5rem;

  .icon {
    color: $gray-cold-500;
    margin-right: 0.25rem;
  }
}
