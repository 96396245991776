@import 'src/styles/variables';
@import 'src/styles/media-queries';

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  padding-left: $student-sidebar-width;

  @include lt-xl {
    padding-left: $student-sidebar-width + 1rem;
    padding-right: 1rem;
  }

  @include lt-lg {
    padding-left: 1rem;
  }

  @include lt-md {
    padding: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  background: $gray-neutral-100;
  box-shadow: $shadow-popping;
  border-radius: 0.5rem 0.5rem 0 0;
  max-width: 816px;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  @include lt-md {
    padding: 0.5rem;
    border-radius: 0;
  }
}

.buttons {
  display: flex;
  border-right: 1px solid var(--primary-200);
  padding-right: 1rem;

  @include lt-md {
    border: none;
  }
}

.button {
  padding: 0.25rem;
  border-radius: 50%;
  background-color: $gray-cold-100;
  color: $gray-cold-500;

  &:hover {
    background-color: $gray-cold-100;
  }

  &.disabled,
  &[disabled] {
    opacity: 1;
    color: $gray-cold-300;
  }

  &:first-child {
    margin-right: 0.75rem;

    @include lt-md {
      margin-right: 0.5rem;
    }
  }
}

.completedBtn {
  &[disabled] {
    background: inherit;
    opacity: 1;
    border-color: $gray-cold-300;
    color: $gray-cold-500;
  }
}

.name {
  font-size: 14px;
  line-height: 20px;
  padding: 0 1.5rem 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include lt-md {
    display: none;
  }
}
