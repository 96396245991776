@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  box-shadow: $shadow-floating;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 24px;
  color: $gray-cold-900;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}

.image {
  border-radius: 0.25rem;
  width: 200px;
  height: 112px;
}
