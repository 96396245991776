@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.status {
  font-size: 24px;
  color: $gray-cold-900;
  margin-bottom: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 928px;

  @include lt-sm {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 1rem;
  }

  @include gt-lg {
    transform: translateX(-3rem);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 928px;
  gap: 2rem;
  margin-bottom: 2rem;

  @include lt-sm {
    gap: 0.5rem;
  }

  @include gt-lg {
    transform: translateX(-3rem);
  }
}
