@import 'src/styles/variables';

.container {
  max-width: 90%;
}

.input {
  padding: 0.25rem 0.5rem;
  height: 1.25rem;
  font-size: 16px;
  font-weight: 600;
  margin-left: -9px;
  border-color: transparent;
  max-width: 100%;

  &::placeholder {
    color: $gray-cold-500;
  }
}
