@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.iconContainer {
  background-color: var(--primary-200);
  border-radius: 50%;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.75rem;
}

.icon {
  color: var(--primary-500);
  width: 64px;
  height: 64px;
}

.uploadText {
  color: var(--primary-600);
  font-weight: 600;
}

.uploadIconContainer {
  color: var(--primary-500);
}

.socialTitle {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.25rem;
}

.socialDescription {
  font-size: 12px;
  margin-bottom: 1rem;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}

.info {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
  display: flex;
  align-items: flex-start;
  max-width: 288px;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
}

.formWrapper {
  height: 100%;
}
