@import 'src/styles/variables';

.container {
  background: var(--primary-100);
  padding: 0.5rem;
}

.inner {
  border: 1px dashed $primary-300;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.inputFile {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 0;
}

.illustrationContainer {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: $gray-cold-700;
}

.description {
  font-size: 12px;
  color: $gray-cold-700;
}
