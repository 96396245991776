@import 'src/styles/variables';

.visibility {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $gray-cold-500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.2s;
  user-select: none;

  &.withLabel {
    top: 42px;
  }

  &:hover {
    color: var(--primary-500);

    .tooltip {
      opacity: 1;
      color: $gray-cold-500;
    }
  }
}

.tooltip {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
  margin-right: 0.25rem;
  opacity: 0;
  transition: opacity 0.2s;
}

.error {
  font-size: 12px;
  line-height: 18px;
  color: $red-600;
}
