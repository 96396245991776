@import 'src/styles/variables';

.infoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1rem;
  position: absolute;
  bottom: 0;
  background: $gray-neutral-100;
  width: 100%;
  border-top: 1px solid $gray-cold-200;
  .info {
    font-size: 12px;
    color: $gray-cold-500;
    padding: 0.375rem 0;
    svg {
      margin-right: 0.25rem;
      color: $gray-cold-400;
    }
  }
}

.InfoWrapper {
  border-radius: 0px 0px 0.5rem 0.5rem;
  padding: 0.5rem;
}
.infoIcon {
  color: $green-500;
  margin-bottom: 0.25rem;
}
.description {
  font-size: 12px;
  margin-bottom: 0.5rem;
}
