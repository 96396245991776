@import 'src/styles/variables';

.title {
  margin: 0 0 0.5rem;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
}

.description {
  max-width: 95%;
  margin: 0 0 1.5rem;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  font-size: 14px;
  line-height: 20px;
}

.successContainer {
  position: relative;
  overflow: hidden;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: $gray-neutral-100;

  .email {
    color: var(--primary-700);
  }

  .mailPicture {
    position: absolute;
    right: 1rem;
    bottom: -0.25rem;
    width: 80px;
    height: 80px;
  }

  .successText {
    font-size: 12px;
    line-height: 18px;
  }

  .textTimer {
    color: $gray-cold-700;
    font-size: 12px;
  }

  .conformationText {
    margin-right: 0.25rem;
    color: $gray-cold-500;
    font-size: 12px;

    &.active {
      cursor: pointer;
      color: var(--primary-600);

      &:hover {
        color: var(--primary-500);
      }
    }
  }

  .timerContainer {
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 1.5rem;
  }
}
