@import 'src/styles/variables';

.iconContainer {
  color: $gray-cold-500;
  text-align: center;
  margin-top: 2rem;
}

.text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  margin-bottom: 0.25rem;
  color: $gray-cold-500;
}

.titleDate {
  composes: title;
  font-weight: 600;
}
