@import 'src/styles/variables';

.tag {
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 24px;
  background-color: $gray-neutral-100;
  color: var(--primary-500);
  padding: 0 8px 0 6px;
  font-size: 12px;
}

.icon {
  line-height: 0;
  margin-right: 0.125rem;
}
