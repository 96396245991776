@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  display: grid;
  grid-template-columns: max(26rem) auto;
  height: calc(100vh - 64px);

  .draftLesson {
    background-color: $gray-cold-200;
  }

  @include lt-lg {
    display: block;
    //height: 100vh;
  }
}

.wrapper {
  max-width: 768px;
  width: 100%;
  flex: 1;
}

@include lt-lg {
  .modalDialog {
    margin: 0;
    transform: none !important;
  }
  .modalContent {
    height: 100vh;
    max-width: $student-sidebar-width;
    border-radius: 0;
  }
  .nextLessonContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary-500);
    z-index: 1040;
    display: flex;
    height: 48px;
    justify-content: space-between;
    box-shadow: $shadow-popping;
  }
  .btn {
    background-color: transparent;
    background-image: unset;
  }
}
