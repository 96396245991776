@import 'src/styles/variables';

.container {
  margin-top: 1.3rem;
  position: relative;
}

.dragContainer {
  &:last-child {
    margin: 0;
  }
}

.dragIcon {
  svg {
    color: $gray-cold-500;
  }
}

.optionWrapper {
  display: flex;
  align-items: center;
}

.check {
  &:checked {
    background-color: white;
  }
  &::before {
    color: var(--primary-500);
    background-color: white;
  }
}

.selectContainer {
  margin-top: 1rem;
  align-items: flex-end;
}

.radioButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.radioText {
  line-height: 1.43;
  letter-spacing: -0.3px;
  font-size: 14px;
  color: $gray-cold-700;
}

.optionTrash {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.addOption {
  padding: 0.25rem 0.5rem 0.24rem 1rem;
  border-radius: 4px;
  border: solid 1px var(--primary-600);
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
  margin-top: 1rem;
}

.icon {
  height: 24px;
  width: 24px;
  color: var(--primary-500);
}

.defaultText {
  color: $gray-cold-500;
  padding-right: 1.5rem;
  // background-color: $gray-neutral-100;
  &:focus {
    // background-color: $gray-neutral-100;
    color: $gray-cold-900;
  }
}

.append {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
