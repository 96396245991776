@import 'src/styles/variables';

.almostText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.provider {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-600;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
}

.text {
  font-size: 14px;
  line-height: 20px;
}

.text + .text {
  margin-top: 0.75rem;
}

.listTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-600;
  margin-bottom: 0.5rem;
}

.list {
  padding: 0 0 0 16px;
  margin: 0 0 0.75rem;

  li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

:global {
  .full-instructions {
    :local {
      .listTitle {
        color: $gray-cold-900;
      }
    }
  }
}
