@import 'src/styles/variables';

.container {
  display: block;
}

.forgot {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-600);
  text-align: right;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: var(--primary-400);
  }
}

.loading {
  padding: 0.5rem;
  background-color: var(--primary-600);
  background-image: none;
  pointer-events: none;
}

.error {
  font-size: 12px;
  line-height: 18px;
  color: $red-600;
}
