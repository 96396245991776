@import 'src/styles/variables';
@import 'src/styles/media-queries';

.orgContainer {
  position: relative;
}

.orgImg {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: relative;
  display: flex;

  &.bg {
    background-color: var(--primary-500);
  }

  img {
    position: initial;
    top: 0;
    border: none;
    width: 100%;
    height: auto;
  }

  @include lt-sm {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.orgText {
  font-size: 14px;
  font-weight: 600;
  top: 0;

  @include lt-sm {
    line-height: 1.5rem;
  }
}
.orgName {
  font-size: 14px;
  margin-left: 0.5rem;
  -webkit-line-clamp: 3;
  max-width: calc(100% - 40px);
}

.title {
  font-size: 24px;
  color: $gray-cold-900;
  margin-top: 0.5rem;
}

.lessons {
  font-size: 14px;
  height: 24px;
  color: $gray-cold-700;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  .lessonInfo {
    @extend .lessons;
    font-weight: 600;
    margin: 0 0 0 6px;
  }
  .percentContainer {
    margin-left: 6px;
    margin-right: 0.5rem;
  }
  .passPercent {
    color: var(--primary-500);
    font-weight: normal;
  }
  .dot {
    margin-left: 6px;
    color: $gray-cold-500;
  }
}

.completeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  .completeIcon {
    background: $green-500;
    border-radius: 50%;
    color: $gray-neutral-100 !important;
  }
  .completed {
    color: $green-500;
    font-size: 14px;
    font-weight: normal;
    margin-left: 0.5rem;
  }
}
