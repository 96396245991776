@import 'src/styles/variables';

.set-size {
  font-size: 4em;
  padding: 0.5rem 0;
  cursor: pointer;
}

@mixin size($width: 58px, $height: 58px) {
  height: $height;
  width: $width;
}

.pie-wrapper {
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pie {
  @include size();
  clip: rect(0, 1em, 1em, 0.5em);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.half-circle {
  @include size();
  border: 5px solid var(--primary-500);
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
  // box-shadow: 0px 0px 5px rgba(0, 114, 255, 0.5);
}

.shadow {
  @include size(40px, 40px);
  border-radius: 50%;
}

.percent {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: $gray-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-600);
}

.cancelContainer {
  color: $gray-cold-500;
}

.cancel {
  position: absolute;
  top: 5px;
  left: 4px;
}
