@import 'src/styles/variables';

.container {
  display: block;
}

.answer {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
}

.student {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 12px;
  border-radius: 12px;
  background: $gray-cold-200;
}
