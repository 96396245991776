@import 'src/styles/variables';

.text {
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: var(--primary-500);
  font-size: 12px;

  &.edit {
    cursor: text;
  }

  cursor: default;

  &.active {
    color: var(--button-text-color);

    ::selection {
      background: #559dfb;
    }

    ::-moz-selection {
      background: #559dfb;
    }
  }

  ::selection {
    background: #afd4fd;
  }

  ::-moz-selection {
    background: #afd4fd;
  }
}

.input {
  caret-color: transparent;

  &:not(:read-only) {
    caret-color: auto;
  }
}

.container {
  background: var(--primary-100);
  border-radius: 0.75rem;
  height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  composes: text;
  cursor: pointer;

  &:first-child {
    margin-left: 0.5rem;
  }

  &.active {
    background-color: var(--primary-500);
  }
}

.icon {
  color: $primary-300;
  cursor: move;
}

.chevron {
  color: var(--primary-500);
  cursor: pointer;

  &.active {
    color: $gray-neutral-100;
  }
}

.dropContainer {
  position: unset;
  height: 1rem;
}

.input {
  border: none;
  background-color: transparent;
  composes: text;
  max-width: 140px;
}

.dropMenuTextDelete {
  font-size: 12px;
  color: $red-500;
  border-top: solid 1px $gray-cold-200;
  display: flex;
  padding-top: 0.5rem;
  cursor: pointer;
}

.dropMenuTextRename {
  font-size: 12px;
  color: $gray-cold-900;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.containerMenu {
  min-width: unset;
  max-width: 134px;
}

.contentMenu {
  padding: 0.5rem 1rem;
}

.checkMark {
  background-color: var(--primary-500);
  color: $gray-neutral-100;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  cursor: pointer;
}

.dropDeleteModal {
  color: $yellow-500;
}

.titleTextModal {
  color: $gray-cold-900 !important;
}
