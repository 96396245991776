@import 'src/styles/variables';

.container {
  min-height: 100px;
}

.left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem 0.5rem 0.5rem 1.5rem;
  height: 100%;
}

.right {
  padding: 1.5rem;
  background: var(--primary-100);
  height: 100%;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.list {
  padding-right: 0.5rem;
  overflow-y: auto;
  flex-grow: 1;
}

.item {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 14px;
  color: $gray-cold-700;
  cursor: pointer;

  &:hover {
    color: var(--primary-500);
  }

  &.passed {
    color: $gray-cold-500;
    cursor: default;
  }

  &.active {
    color: var(--primary-500);

    .marker {
      color: inherit;
    }
  }
}

.marker {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: $gray-cold-500;
  flex-shrink: 0;
}

.name {
  font-weight: 600;
  line-height: 20px;
}

.video {
  height: 182px;
  background: #fff;
  margin-bottom: 0.5rem;
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}

.time {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.25rem;
}

.description {
  font-size: 14px;
  line-height: 20px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--primary-500), 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.videoModal {
  background-color: transparent;
  position: relative;
}

.close {
  position: absolute;
  top: 0.625rem;
  right: -2.5rem;
  color: $gray-neutral-100;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--primary-500), 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play {
  padding: 0.5rem;
  background: $gradient;
  color: $gray-neutral-100;
  border-radius: 50%;
}

.videoModal {
  background-color: transparent;
  position: relative;
}
