@import 'src/styles/variables';

.blockTitle {
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.selector {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  & > div {
    width: 88px;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 2px;
    text-align: center;
    border-bottom: 2px solid var(--primary-200);
    color: $gray-cold-700;
    cursor: pointer;

    &.active {
      font-weight: 600;
      border-color: var(--primary-500);
    }
  }
}
