@import 'src/styles/variables';

.video {
  color: inherit;
  border-radius: .25rem;
  overflow: hidden;
}

.bottomPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
}

.info {
  display: flex;
}

.comments {
  margin-right: 1.5rem;
  color: var(--primary-500);
  white-space: nowrap;
}

.views {
  margin-right: 1.5rem;
  color: $gray-cold-500;
  white-space: nowrap;
}

.saved {
  color: $gray-cold-500;
  white-space: nowrap;
  margin-right: 1.5rem;
}

.actions {
  display: flex;
}

.videoWrapper {
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 432px;
  }
}

.iframe {
  border: none;
  margin: 0;
  padding: 0;
  min-height: 432px;
  width: 100%;
  height: 100%;
}
