@import 'src/styles/variables';

.container {
  border-radius: 50%;
}

.btn {
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid transparent;
  &:active {
    background: var(--button-background) !important;
  }
}

.reload {
  background: $gray-neutral-100;
  border: 1px solid var(--primary-300);
  svg {
    color: var(--primary-500);
  }

  &:hover {
    background: var(--primary-200) !important;
    svg {
      color: var(--primary-400) !important;
    }
  }
  &:active {
    background: var(--primary-300) !important;
    svg {
      color: var(--primary-600) !important;
    }
  }
}
