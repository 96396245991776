@import 'src/styles/variables';

.containerText {
  max-width: 529px;
  margin-left: 1.5rem;
}

.title {
  font-size: 24px;
}
