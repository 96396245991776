@import 'src/styles/variables';

.dripNode {
  border-top: 1px solid $gray-cold-300;
  margin: 0.5rem 0.75rem 0 0.75rem;
  padding-top: 0.5rem;
  font-size: 12px;
  color: $gray-cold-700;
  &.selected {
    border-top: 1px solid var(--primary-400);
    color: var(--primary-200);
  }
}
