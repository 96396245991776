.toggleElement {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  &:after {
    display: none !important;
  }
}
