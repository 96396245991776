@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--primary-100);
  border-radius: 0.25rem;
}

.handleIcon {
  color: $gray-cold-500;
}
.draggingHandleIcon {
  color: var(--primary-500);
}

.dragging {
  background: var(--primary-200);
  box-shadow: $shadow-popping;
}
