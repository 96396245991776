@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  color: $gray-cold-900;
  margin: 0;
}
