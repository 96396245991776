@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
}

.content {
  padding: 2rem 3.5rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: $gray-cold-500;
}

