@import 'src/styles/variables';

.popup {
  top: 300px;
  left: 10rem;
  width: 16rem;
  min-height: 7rem;

  :global .scrollable-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
  }
}

.title {
  margin-left: 0.75rem;
}

.paletteElements {
  display: block;
}

.paletteElement {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.25rem;
  border-radius: $border-radius;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-200);

    .elementName {
      color: var(--primary-600);
    }
  }

  :global .element-preview {
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}

.elementName {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}

.elementPreview {
}
