@import 'src/styles/variables';

.student {
  display: flex;
  margin-bottom: 0.75rem;

  .studentIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    color: var(--primary-500);
    border-radius: 50%;
    background-color: var(--primary-200);
  }

  .studentContent {
    display: flex;
    flex-direction: column;

    .studentHeader {
      display: flex;
      align-items: center;
    }

    .studentTitle {
      letter-spacing: -0.003em;
      color: $gray-cold-700;
      font-size: 12px;
    }

    .studentName {
      margin: 0;
      letter-spacing: -0.003em;
      color: $gray-cold-900;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    .studentDate {
      letter-spacing: -0.003em;
      color: $gray-cold-500;
      font-size: 14px;
    }

    .studentDot {
      width: 0.25rem;
      height: 0.25rem;
      margin: 0 0.5rem;
      border-radius: 50%;
      background-color: $gray-cold-500;
    }
  }
}
