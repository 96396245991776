@import 'src/styles/variables';

.container {
  width: 100%;
}

.wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
}

.input {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  border: 1px solid $primary-600;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.5rem;
  font-size: 14px;
  line-height: 18px;
  color: $gray-cold-500;
  background: $gray-neutral-100;
  cursor: default;

  &.invalid {
    border-color: $red-500;
  }
}

.autosize {
  border: none;
  padding-right: 0;
  margin-right: -2px;

  &::selection {
    background: $primary-200;
  }
}
