@import 'src/styles/variables';
@import 'src/styles/media-queries';

.block {
  display: block;
  margin-bottom: 1rem;

  @include lt-lg {
    margin-bottom: 1.5rem;
  }

  @include lt-md {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: $gray-neutral-100;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  @include lt-md {
    padding: 0.75rem 1rem;
  }
}

.chevron {
  color: $gray-cold-500;
  margin-left: auto;
}

.container {
  border-radius: 0.25rem;
  overflow: hidden;

  @include lt-md {
    margin-top: 0.25rem;
  }
}

.unsetOverflow {
  overflow: unset !important;
}

.topRounded {
  border-radius: 0.25rem;
}

.icon {
  color: $gray-cold-500;
}
