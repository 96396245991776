@import 'src/styles/variables';

.modalContain {
  overflow: hidden !important;
}

.modal {
  max-width: 768px;
  height: calc(100% - 3rem);
}
.modalContent {
  height: 100%;
  overflow-y: hidden;
}

.comments {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 1rem 1rem 1rem 1.5rem;
  span {
    color: $gray-cold-500;
  }
}

.header {
  position: sticky;
  top: -1rem;
  background: $gray-neutral-100;
  z-index: 1;
  width: 100%;
  // position: absolute;
  &.isPinned {
    box-shadow: 0px 1px 5px -2px rgba(7, 7, 55, 0.15), 0px 2px 10px 2px rgba(7, 7, 55, 0.08);
  }
  @media (min-width: 576px) {
    top: 0rem;
  }
}

.postCommentContainer {
  border-top: 1px solid $gray-cold-100;
  border-bottom: 1px solid $gray-cold-100;
}

.info {
  max-width: 482px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0.5rem auto;
  .warningColor {
    color: $yellow-500;
  }
  .textWarning {
    font-size: 14px;
    line-height: 20px;
  }
}

.closeBtn {
  z-index: 10;
}
