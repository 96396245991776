@import 'src/styles/variables';

.placeholder {
  position: relative;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $gray-cold-500;
  background-color: var(--primary-200);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 24px;
    color: $gray-cold-700;
    display: block;
  }
}

.circle {
  position: absolute;
  width: 176px;
  height: 176px;
  border-radius: 50%;
  top: -112px;
  left: -48px;
  background-color: var(--primary-500);
  color: $gray-neutral-100;

  svg {
    position: absolute;
    bottom: 16px;
    left: 64px;
  }
}
