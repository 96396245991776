@import 'src/styles/variables';

.info {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
}

.attempt {
  background-color: $gray-neutral-200;
  padding: 0 0.25rem;
  margin-right: 0.5rem;
}

.option {
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: $gray-cold-500;

  .number {
    color: $gray-cold-900;
    margin-right: 0.25rem;
    font-weight: 600;
  }

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: $gray-neutral-100;
    margin-right: 0.25rem;
  }

  .success {
    background: $green-500;
  }

  .fail {
    background: $red-500;
  }

  .progress {
    background: $yellow-500;
  }

  .skipable {
    background: $gray-cold-500;
  }
}

.status {
  display: flex;
  align-items: center;
}

:global .select__option--is-selected {
  :local .number {
    color: var(--primary-500);
  }
}
