@import 'src/styles/variables';

.input {
  max-width: 162px;
}

.description {
  font-size: 12px;
  color: $gray-cold-700;
  max-width: 155px;
  margin-left: 0.5rem;
}
