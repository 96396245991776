@import "src/styles/variables";

.buttons {
  composes: buttons from '../../offer-list/offer-list.module.scss';
  padding: 2px 8px;
  border-radius: 14px;
  box-shadow: $shadow-raised;
  background-color: $gray-neutral-100;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  display: flex;

  & > div {
    margin-right: 0.25rem;

    &:last-child {
      border-left: 1px solid $gray-cold-200;
      margin-left: 0.25rem;
      margin-right: 0;
      padding-left: 0.5rem;

      & > div {
        position: relative;
      }
    }
  }

  :global .btn {
    color: var(--primary-500);
  }

  :global .btn:not(.btn:first-child) {
    margin-left: 0.125rem;
  }
}

.delete {
  composes: delete from '../../offer-list/offer-list.module.scss';
}

.archive {
  color: $red-500 !important;

  &:disabled {
    color: $gray-cold-500 !important;
  }
}
