@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 0.5rem 0 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid $gray-cold-200;
  flex-shrink: 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
}
