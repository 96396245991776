@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.navLink {
  composes: import navLink from '../../../../styles/components/sidebar.module.scss';
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: solid 1px $gray-cold-200;
  border-right: solid 1px $gray-cold-200;
}

.container {
  overflow: auto;
  height: var(--vh);
  padding: 2rem 2rem 0;

  @include lt-lg {
    height: calc(100% - $student-navbar-height);
  }

  @include lt-sm {
    overflow: visible;
    height: calc(100% - $student-navbar-height + 1rem);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.soonIcon {
  width: 43px;
  height: 16px;
}

.bellContainer {
  box-shadow: $shadow-popping;
  background-color: $gray-neutral-100;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-cold-500;
  cursor: pointer;

  .new {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--primary-500);
    border-radius: 50%;
    position: absolute;
  }
}

.sidebarIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: 1rem;
}

.navbarIconContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
  width: 100%;
  height: $student-navbar-height;
  box-shadow: $shadow-popping;
  background-color: $gray-neutral-100;

  &.extend {
    box-shadow: none !important;
  }
}

.logo {
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  @include lt-sm {
    left: 1rem;
  }

  .icon {
    height: 100%;
  }

  .btnMenu {
    max-height: 32px;
  }
}

.footerText {
  font-size: 12px;
  color: $gray-cold-700;
  cursor: pointer;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &:hover {
    color: $gray-cold-700;
    text-decoration: none;
  }
}

.footerText + .footerText {
  margin-right: 1rem;
}

.footer {
  border-top: solid 1px $gray-cold-300;
}

.modalDialog {
  margin: 0;
  max-width: unset;

  .modalContent {
    border-radius: 0;
  }
}

.emptyContainer {
  height: 100%;
  width: 100%;
  max-width: 608px;
  max-height: 212px;
  margin: auto;
  padding: 2rem;
  background-color: $gray-neutral-100;
  font-size: 24px;
  color: $gray-neutral-900;
  box-shadow: $shadow-floating;
  border-radius: $border-radius;

  .emptyIcon {
    color: var(--primary-500);
    width: 64px;
    height: 64px;
    margin-bottom: 3rem;
  }
}

@include lt-sm {
  .bellContainer {
    width: 32px;
    height: 32px;
  }

  .logo {
    width: unset;
    //margin-left: -0.5rem;

    .btnMenu {
      margin-left: -1.5rem;
    }
  }

  .footer {
    //flex-direction: column;
    align-items: flex-start;
  }

  .emptyContainer {
    padding: 1.5rem;
    height: unset;

    .emptyIcon {
      margin-bottom: 2rem;
    }
  }

  .navbarIconContainer {
    height: calc($student-navbar-height - 1rem);
    padding-right: 0.5rem;
  }
}

@include lt-lg {
  .bellContainer {
    background-color: transparent;
    box-shadow: none;
  }
}

@include gt-md {
  .navbarIconContainer {
    position: absolute;
    right: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
