@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding-bottom: 0.75rem;
  margin-left: auto;
}

.element {
  border-radius: 0.563rem 0.188rem 0.188rem 0.563rem;
  background-color: var(--primary-200);
  padding: 0 1.063rem 0 0.5rem;
  font-size: 12px;
  color: var(--primary-400);
  margin-top: 0.75rem;
  &.selected {
    background-color: var(--primary-500);
    color: $gray-neutral-100;
  }
}
