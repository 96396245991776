@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  background-color: var(--primary-200);
  border-radius: 2px;
  margin-left: 0;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.textDefault {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
  white-space: nowrap;
  padding: 1px 0 1px 0.25rem;
  user-select: none;
}

.icon {
  display: flex;
  align-items: center;
  color: $gray-cold-500;
}
