@import 'src/styles/variables';
@import 'src/styles/media-queries';

.container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include lt-sm {
    padding: 0 10px;

    .wrapper {
      padding: 0 10px;
      width: 100%;
    }
  }
}

.image {
  width: 100%;
  max-width: 512px;
  margin-bottom: 2rem;
}

.text {
  color: $gray-cold-700;
  text-align: center;
  line-height: 22px;
  max-width: 460px;
  margin-bottom: 1rem;

  span {
    color: var(--primary-500);
  }
}

.button {
  width: 256px;

  @include lt-sm {
    width: 100%;
  }
}
