@import 'src/styles/variables';

.wrapper {
  padding: 0 40px;
}

.tagTitle {
  color: $gray-cold-700;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
  margin-top: 24px;
}

.colorContainer {
  display: flex;
  align-items: center;
}

.colorItem + .colorItem {
  margin-left: 16px;
}

.randomColorContainer {
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  border-radius: 24px;
  border: solid 1px $primary-300;
  align-items: center;
  margin-left: 10px;
}

.randomText {
  margin-right: 22px;
  font-size: 14px;
  color: $gray-cold-700;
}

.description {
  color: $gray-cold-500;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 25px;
}

.footerContainer {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.text {
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
}

.textColor {
  color: unset;
}

.error {
  position: absolute;
  top: 100%;
  color: $red-600;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
}
