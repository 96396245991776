@import 'src/styles/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  background: $gray-neutral-100;
  box-shadow: $shadow-popping;
  border-radius: 20px;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  &.active {
    z-index: 4;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0.625rem 1rem 0.625rem 0.5rem;
    &.processing {
      gap: 0.25rem;
      display: flex;
      align-items: center;
    }
    &.uploading {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &.completed {
      background: $green-200;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .iconCheckMark {
        background: $gray-neutral-100;
        border-radius: 50%;
        color: $green-500;
      }
    }
    &:hover {
      &.completed {
        background: $green-300;
      }
    }
  }
  &:hover {
    background: var(--primary-200);
  }

  .circleSize {
    width: 24px;
    height: 24px;
  }
}
