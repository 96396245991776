@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  border: 1px solid var(--primary-300);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.003em;
    color: var(--primary-500);
    width: 38px;
    text-align: end;
  }
  &:hover {
    background: var(--primary-100);
  }
  &:active {
    border: 1px solid var(--primary-500);
    background: var(--primary-100);
  }
  &:focus {
    border: 1px solid var(--primary-600);
    background: var(--primary-100);
  }
}

.icon {
  color: var(--primary-500);
}

.dropContainer {
  min-width: 74px;
}
.dropItem {
  justify-content: end;
  padding: 0.375rem 0.5rem;
}
