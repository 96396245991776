@import 'src/styles/variables';

.container {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  flex-grow: 1;
  border-radius: 0.25rem;
}

.title {
  font-size: 14px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.titleLesson {
  @extend .title;
  color: $gray-cold-700;
}

.progressContainer {
  display: flex;
  margin-left: 0.5rem;
  align-items: center;

  svg {
    margin-right: 0.25rem;
  }
}

.iconSwitch {
  color: $gray-cold-400;
}

.itemTitle {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.5rem;
  &.drip {
    font-size: 14px;
    color: $gray-cold-500;
  }
}

.iconDrip {
  color: $yellow-500;
}

.iconDisable {
  color: $gray-cold-400;
}

.haveHotPassed {
  @extend .iconDisable;
}

.iconDripLesson {
  @extend .iconDisable;
}

.iconCompleted {
  color: $green-600;
}
