@import 'variables';
@import 'src/styles/media-queries.scss';

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(138, 154, 168, 0.2);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(138, 154, 168, 0.5);
}

:focus {
  outline: none;
}

#ReactTags {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

textarea {
  cursor: auto;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: var(--bg-color);
  letter-spacing: -0.3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.block-account {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 4rem;
  // padding-right: 4rem;
  box-shadow: $shadow-raised;
}

.link {
  text-decoration: underline;
  color: $primary-600;
  &:hover {
    color: $primary-400;
  }
  &:focus {
    color: $primary-500;
  }
  &:visited {
    color: $purple-700;
    &:hover {
      color: $purple-400;
    }
    &:focus {
      color: $purple-500;
    }
  }
  &:disabled {
    color: $gray-cold-500;
  }
}

.logo {
  width: 6rem;
  text-align: center;
  color: var(--primary-500);
  cursor: pointer;

  &:hover {
    color: $red-200;
  }
}

.error {
  color: $red-600;
  font-size: 12px;
  margin-top: 0.25rem;
}

.sidebar {
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: block;
  padding: 0;
  flex-flow: column;
  margin-left: 0;
  margin-top: $content-margin-top;
  background: #fff;
  box-shadow: $shadow-raised;
  width: 32rem;

  &-container {
    display: flex;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  &.sidebar-left {
    left: 0;
  }

  &.sidebar-right {
    right: 0;
  }

  &.sidebar-small {
    width: $sidebar-menu-width;
  }

  &.full-height {
    margin-top: 0;
    z-index: 102;
  }

  .side-menu {
    overflow-y: auto;
    height: 100%;
  }
}

.navbar-vertical-container {
  position: relative;
  height: 100%;
  max-height: 100%;
  // overflow-y: auto;
}

.hasSidebar {
  padding-left: 6rem;
}

.student-cabinet {
  padding-left: 6rem;
  height: var(--vh);

  @include lt-lg {
    padding-left: 0;
  }
}

.hasNavbar {
  padding-top: 4rem;
}

.main {
  display: block;
  flex-grow: 1;
}

.ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.overflow-unset {
  overflow: unset !important;
}

.content {
  padding: 2rem;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  &.contentOffer {
    max-height: calc(100vh - 7.5rem);
  }

  &.course {
    padding-bottom: 64px;
  }

  &.student {
    height: var(--vh);
    padding-bottom: 3rem;

    @include lt-lg {
      padding: 6rem 2rem 3rem;
    }

    @include lt-md {
      padding: 5rem 0.625rem 2.625rem;
    }
  }
}

.page-top {
  .actions {
    display: flex;

    .btn {
      margin-left: 0.75rem;
    }
  }
}

.help-note {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.29px;
  color: $gray-cold-500;
}

.modal-backdrop {
  backdrop-filter: blur(2px);
}

.confirm-dialog {
  .modal-title {
    font-size: 16px;
    letter-spacing: -0.33px;
    color: $gray-cold-900;
    font-weight: 600;

    .icon {
      color: var(--primary-500);
    }
  }

  .modal-body {
    font-size: 14px;
    letter-spacing: -0.29px;
    padding-right: 13rem;

    .text-muted {
      padding-left: 2.5rem;
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 101;
  background-color: $primary-900;
}

// Text
.note {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
}

.blur {
  filter: blur(1px);
}

.soon-badge {
  display: inline-block;
  width: 32px;
  height: 12px;
  background: $yellow-500;
  border: 1px solid $gray-neutral-100;
  border-radius: 0.25rem;
  color: $gray-cold-900;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0;

  &-lg {
    width: 50px;
    height: 17px;
    font-size: 13px;
    line-height: 15px;
  }
}

.coming-soon {
  cursor: not-allowed;
  user-select: none;

  &.external {
    cursor: pointer;
  }
}

.count {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-500);
  background: $gray-neutral-100;
  border-radius: 12px;
  margin-right: auto;
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.intercom-lightweight-app {
  z-index: 100 !important;
}

.spinner {
  animation: 0.75s linear infinite spinner-border;
}
