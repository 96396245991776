@import 'src/styles/variables';

.containerItem {
  display: flex;
  align-items: center;

  &.text {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-700;

    &.active {
      color: $gray-cold-500;
    }

    &.error {
      color: $red-500;
    }
  }

  &.active {
    svg {
      color: $green-600;
      background-color: $green-200;
    }
  }

  svg {
    background-color: $gray-cold-200;
    border-radius: 50%;
    color: $gray-cold-500;
    margin-right: 0.5rem;
  }
}

.containerItem + .containerItem {
  margin-top: 0.5rem;
}
