@import 'src/styles/variables';

.container {
  padding: 2rem;
}

.header {
  padding-top: 0.625rem;
  display: flex;
  align-items: center;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  color: $gray-cold-900;

  span {
    color: var(--primary-600);
  }
}

.close {
  width: 2rem;
  height: 2rem;
  color: $gray-cold-500;
  flex-shrink: 0;
}

.icon {
  color: var(--primary-500);
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  flex-shrink: 0;

  &.warning {
    color: $yellow-500;
  }
}

.cancel {
  margin-right: 0.5rem;
}

.description {
  color: $gray-cold-900;
  font-size: 14px;
  line-height: 20px;
  padding-left: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.text {
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
