@import 'src/styles/variables';

.enrollment {
  padding: 1.5rem;
}

.courses {
  display: block;
}

.thumb {
  width: 8rem;
  height: 4.5rem;
  border-radius: 0.125rem;
  background-color: var(--primary-200);
  overflow: hidden;
  flex-shrink: 0;
}

.addCourse {
  text-align: center;
}
