@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.hiddenScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.unselectable {
  user-select: none;
}

.draggableContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: auto;
  composes: hiddenScroll;
  scroll-behavior: smooth;
}

.dragContainer {
  overflow: hidden;
  padding-right: 3.5rem;

  @include lt-lg {
    padding-right: 0.5rem;
  }
}

.arrowContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.events {
    pointer-events: none;
  }

  @include lt-lg {
    display: none;
  }
}

.icon {
  margin: 0.25rem;
}

.iconArrowScroll {
  background: var(--primary-500);
  box-shadow: $shadow-raised;
  color: $gray-neutral-100;
  border-radius: 50%;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  composes: unselectable;
}

.iconArrowScroll + .iconArrowScroll {
  margin-left: 0.25rem;
}
