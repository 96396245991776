@import 'src/styles/variables';

.container {
  display: block;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  color: $gray-cold-700;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.all {
  height: 48px;
  padding: 0 0.5rem 0 1rem;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $gray-neutral-100;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    color: $primary-500;

    .icon {
      color: $primary-300;
    }
  }

  &:active {
    background: $primary-200;
    color: $primary-500;

    .icon {
      color: $primary-400;
    }
  }

  &.active {
    background: $primary-500;
    color: $gray-neutral-100;
  }
}

.icon {
  color: $gray-neutral-100;
}

.emptyCourses {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $gray-cold-700;
}
