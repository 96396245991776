
@import 'src/styles/variables';

.uploadThumbnail {
  position: relative;
  display: flex;
  align-items: center;
}

.uploadThumbnailTitle {
  visibility: visible;
  margin: 0 1rem 0 0;
  transition-duration: 0.25s;
  transition-property: opacity, transform;
  transform: scaleY(1);
  letter-spacing: -0.003em;
  opacity: 1;
  color: var(--primary-500);
  font-size: 12px;
}

.uploadThumbnailTitleHover {
  visibility: hidden;
  transform: scaleY(0);
  opacity: 0;
}

.uploadThumbnailHints {
  position: absolute;
  left: -5rem;
  visibility: hidden;
  overflow: hidden;
  margin: 0;
  transition-duration: 0.25s;
  transition-property: opacity, transform;
  transform: scaleY(0);
  text-align: right;
  letter-spacing: -0.003em;
  opacity: 0;
  color: $gray-cold-600;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
}

.uploadThumbnailHintsHover {
  visibility: visible;
  transform: scaleY(1);
  opacity: 1;
}

.uploadThumbnailButton {
  position: relative;
  overflow: hidden;
  width: 4.5rem;
  height: 3rem;
  padding: 0;
  transition-duration: 0.25s;
  transition-property: background-color;
  border: 1px solid $primary-300;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.85);

  &:hover {
    background-color: linear-gradient(0deg, rgba(245, 249, 255, 0.85), rgba(245, 249, 255, 0.85));

    .uploadThumbnailImage {
      opacity: 0.5;
    }

    .uploadThumbnailIcon {
      opacity: 1;
    }
  }
}

.uploadThumbnailImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: 0.25s;
  transition-property: opacity;
  opacity: 0;
  object-fit: cover;

  &.uploadThumbnailImageShow {
    opacity: 1;
  }
}

.uploadThumbnailIcon {
  position: absolute;
  z-index: 1;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  transition-duration: 0.25s;
  transition-property: opacity;
  opacity: 0;
  color: var(--primary-500);
}

.uploadThumbnailSpinner {
  position: absolute;
  z-index: 2;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  transition-duration: 0.25s;
  transition-property: opacity;
  color: var(--primary-500);
}

.uploadThumbnailInput {
  display: none;
}
