@import 'src/styles/variables';

.text {
  color: $gray-cold-700;
  padding: 0rem 1.5rem 0rem 1.5rem;
}

.unavailableBtnPlan {
  margin-left: 0.5rem;
}
