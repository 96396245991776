@import 'src/styles/variables';

.tags {
  display: flex;
}

.tagCircle {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: 1px solid $gray-neutral-100;
  margin-right: -4px;
  background-color: var(--primary-500);
  box-sizing: content-box;
}
