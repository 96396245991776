@import "src/styles/variables";

.container {
  background: $gray-neutral-100;
  border-radius: 0.5rem;
  height: 100%;
  box-shadow: $shadow-raised;
}

.image {
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
}

.state {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.preview {
  position: relative;
  height: 190px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    max-width: 100%;
  }
}

.info {
  padding: 0.75rem 1rem 1rem;
  position: relative;

  .action-button {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: $gray-cold-500;
  }

  .create-date {
    font-size: 12px;
    color: $gray-cold-500;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: $gray-cold-700;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.5rem;
}
