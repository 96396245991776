@import 'src/styles/variables';
@import 'src/styles/media-queries';

.container {
  @include lt-sm {
    display: none;
  }
}

.btn {
  width: 100%;
  justify-content: center;

  &:hover {
    .name {
      color: $gray-cold-900;
    }
  }
}

.iconPlay {
  width: 24px;
}

.textEllipsis {
  display: block;
  width: 100%;
  text-align: left;
}
