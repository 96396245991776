@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  background: $gradient-main;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  z-index: 3;
  box-shadow: $shadow-popping;

  @include lt-md {
    height: 4rem;
    padding: 0 1rem;
  }
}

.home {
  color: $gray-neutral-100;
  border-radius: 50%;
  padding: 0.25rem;
  background: transparent;

  &:hover {
    color: $gray-neutral-100;
    background: transparent;
  }

  &:active {
    color: var(--primary-500);
    background-color: var(--primary-100);
  }
}

.menu {
  color: $gray-neutral-100;
  background: var(--primary-500);
  border-radius: 50%;
  padding: 0.25rem;
}

.left {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.center {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: $gray-neutral-100;
}

.right {
  display: flex;
  padding-left: 0.75rem;

  :global {
    .btn {
      border-radius: 50%;

      @include gt-sm {
        padding: 0.5rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $gray-neutral-100;
  -webkit-line-clamp: 1;

  @include gt-sm {
    font-weight: 600;
    font-size: 14px;
  }
}
