.videoWrapper {
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 432px;
  }
}

.iframe {
  border: none;
  margin: 0;
  padding: 0;
  min-height: 432px;
  width: 100%;
  height: 100%;
}
