@import 'src/styles/variables';

.icon {
  color: var(--primary-400);
  display: flex;
  align-self: center;
}

.iconChevron {
  color: var(--primary-400);
  margin: 0 2px;
}
