@import 'src/styles/variables';

.container {
  background: var(--primary-100);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem 2rem 2rem;
}

.wrapper {
  padding: 2rem 3rem 3rem;
}

.title {
  font-weight: 600;
  font-size: 32px;
  color: var(--primary-500);
  margin-top: 1.5rem;
}

.text {
  color: $gray-cold-600;
  font-size: 14px;
  margin-bottom: 0.375rem;
}

.img {
  width: 124px;
  height: 124px;
}

.crossIcon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: $gray-cold-500;
  cursor: pointer;
}
