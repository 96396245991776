@import 'src/styles/variables';

.btn {
  height: 48px;
  width: 100%;
  margin-bottom: 0.25rem;
}

.container {
  padding: 2rem 3.5rem 3rem;
  background: var(--primary-100);
  border-radius: 0.5rem;
}

.plan {
  margin-bottom: 2rem;
}

.label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  color: $gray-cold-700;
}

.name {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  flex-grow: 1;
}

.amount {
  color: var(--primary-500);
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
}

.cards {
  background: $gray-neutral-100;
  border: 1px solid $gray-cold-300;
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 2rem;
  display: flex;
  overflow-x: auto;

  & > div {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card {
  width: 158px !important;
  height: 112px !important;
  cursor: pointer;
}

.selected {
  border-color: $primary-300;
}

.note {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
