@import 'src/styles/variables';

.descriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 3.5rem 3.5rem 3.5rem;
}

.birthday {
  position: absolute;
  top: -85px;
  left: 116px;
}

.containerBirthday {
  border-radius: 0.25rem 0.25rem 0 0;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 3.5rem 2.5rem 3.5rem;

  .hoorayText {
    font-weight: 600;
    font-size: 32px;
    color: $gray-neutral-100;
  }

  .text {
    font-size: 24px;
    color: $gray-neutral-100;
    text-align: center;
  }
}

.orange {
  background: $yellow-500;
}

.blue {
  background: $gradient;
}

.closeHoorayBtn {
  color: $gray-neutral-100;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  &:hover {
    background-color: transparent;
  }
}

.dialogContainer {
  max-width: 452px;
}
