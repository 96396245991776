@import 'src/styles/variables';

.container {
  display: block;
}

.unsubscribe {
  @media (min-width: 576px) {
    :global .modal-sm {
      max-width: 452px;
    }
  }

}
