@import 'src/styles/variables';

.hideClose {
  :global .btn-icon {
    display: none;
  }
}

.icon {
  color: $yellow-500;
}

.description {
  font-size: 14px;
  color: $gray-cold-500;
  margin-left: 2.5rem;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
