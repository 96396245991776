@import 'src/styles/variables';

.container {
  max-height: 248px;
  min-height: 148px;
  overflow: hidden;
  position: relative;
  background: $gray-neutral-200;
  padding: 1rem;
}

.overlay {
  background: linear-gradient(0deg, #f8f7f8 11.5%, rgba(248, 247, 248, 0) 100%);
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.icon {
  margin-right: 0.5rem;
}

.crossContainer {
  box-shadow: $shadow-raised;
  border-radius: 0.25rem;
  background: $gray-neutral-100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  svg {
    color: $gray-cold-500;
    margin: 0;
  }
}
