@import 'src/styles/variables';

.search {
  width: 18rem;
}

.searchInput {
  border-width: 0 0 1px;
  border-radius: 0;
  border-color: var(--primary-200);
  font-size: 14px;
  color: $gray-cold-500;
  padding: 0.375rem 1.5rem 0.375rem 0;
  // height: 2.25rem;

  &::placeholder {
    color: $gray-cold-500;
  }

  &.leftButton {
    padding: 0.375rem 0.25rem 0.375rem 2rem;
  }

  &:global(.form-control-md) {
    padding-left: 2.5rem;
  }

  &.bordered {
    // height: 2rem;
    border: 1px solid $primary-300;
    border-radius: 0.25rem !important;
  }

  &.filled {
    padding-right: 2rem;
  }
}

.append {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.prepend {
  position: absolute;
  left: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  padding-left: 0.25rem;
}

.button {
  color: $gray-cold-500;
  padding: 0;
  line-height: 1;
}

.clear {
  position: absolute;
  z-index: 3;
  line-height: 1;
  padding: 0;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: $gray-cold-200;
  color: $gray-cold-500;

  &.leftButton {
    right: 0.5rem;
  }

  :global svg {
    vertical-align: top;
  }
}
