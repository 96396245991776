@import 'src/styles/variables';

.container {
  transform: rotate(-90deg);
}

.complete {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $green-500;
  color: $gray-neutral-100;
  line-height: 0;
}
