@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
}

.imagePreview {
  position: relative;
  width: 6rem;
  height: 6rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.delete {
  position: absolute;
  border-radius: 50%;
  bottom: -0.25rem;
  right: -0.25rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}

.imagePlaceholder {
  display: flex;
  background-color: var(--primary-200);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--primary-500);
  border-radius: 0.25rem;
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
