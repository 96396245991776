@import 'src/styles/variables';

.titleListAttach {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  color: $gray-cold-700;
}
