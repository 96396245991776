@import 'src/styles/variables';

.link {
  font-weight: 600;
  font-size: 14px;
  &:hover {
    text-decoration: none;
  }
}

.title {
  color: $gray-cold-900;
}

.trialDate {
  font-size: 14px;
  color: $gray-cold-500;
}

.daysLeft {
  font-weight: 600;
  color: $yellow-600;
  margin-top: 1rem;
}

.fixedHeight {
  height: 164px;
}
