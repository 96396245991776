@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.title {
  margin: 2.125rem 0 0;
  text-align: center;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  line-height: 22px;
}

.date {
  margin-top: 0.5rem;
  text-align: center;
  color: $gray-cold-700;
  font-size: 24px;
}

.wrapperDate {
  position: relative;

  .image {
    width: 100%;
    height: 100%;
  }

  .containerDate {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-neutral-100;
    position: absolute;
    width: 106px;
    height: 67px;

    top: 45px;
    left: 171px;

    @include lt-sm {
      left: 35%;
      top: 0%;
    }
  }

  .containerCalendar {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    text-align: center;
    overflow: hidden;

    .month {
      background: $red-500;
      color: $gray-neutral-100;
      font-size: 12px;
    }

    .day {
      background: $gray-cold-200;
    }
  }
}
