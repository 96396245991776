@import 'src/styles/variables';

.wrapper {
  padding: 1rem;
  box-shadow: $shadow-popping;
  border-radius: 0.5rem;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 560px;
  background-color: var(--primary-200);
  margin-left: auto;
  margin-right: auto;
  margin-top: 56px;
  box-shadow: $shadow-raised;
  border-radius: 0.5rem;
}

.bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 167px;
  border-radius: 1.188rem;
  border: 1px solid var(--primary-500);
  padding: 0 0.25rem 0 1rem;
  height: 2rem;
  margin-left: 1rem;
  background-color: $gray-neutral-100;
  .bgColor {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $gray-cold-300;
    border-radius: 50%;
    margin-left: 1.5rem;
  }
}

.text {
  font-size: 12px;
  white-space: nowrap;
}

.btnDisable {
  pointer-events: none;
}

.btn {
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  width: 72px;
  height: 48px;
  svg {
    margin: 0 !important;
  }
}
