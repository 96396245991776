@import '../../../../../../../../styles/variables';

.container {
  background-color: $gray-neutral-100;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.state {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-bottom: 2px;
  color: $gray-cold-500;
}

.result {
  display: flex;
  align-items: center;
}

.success {
  .result {
    color: $green-700;
    margin-right: 0.25rem;
  }

  .icon {
    background-color: $green-500;
    color: $gray-neutral-100;
  }

  .star {
    color: $yellow-500;
  }

  .points {
    color: $gray-cold-700;
  }
}

.fail {
  .result {
    color: $red-500;
    margin-right: 0.25rem;
  }

  .icon {
    background-color: $red-500;
    color: $gray-neutral-100;
  }

  .star {
    color: $gray-cold-300;
  }

  .points {
    color: $gray-cold-500;
  }
}

.icon {
  border-radius: 50%;
  line-height: 0;
  flex-shrink: 0;
  height: 1.5rem;
  margin-right: 0.5rem;
  background-color: $gray-cold-200;
  color: $gray-cold-500;
}

.points {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;

  span {
    color: $gray-cold-400;
    margin: 0 0.25rem;
  }
}

.date {
  color: $gray-cold-500;

  span {
    color: $gray-cold-400;
  }
}

.question {
  font-size: 14px;
  line-height: 20px;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  :global(.ql-container) {
    border: none;
  }
  :global(.ql-editor) {
    padding: 0;
  }
}

.answers {
  margin-top: 1rem;
  padding-top: 1rem;
  margin-left: 2rem;
  border-top: 1px solid $gray-cold-300;
}

.toggle {
  margin-left: 2rem;
  padding: 0 !important;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 0;
  color: var(--primary-500);
}
