@import 'src/styles/variables';

.sidebarTitle {
  margin: 0 0 0.75rem;
  letter-spacing: -0.003em;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;

  .reload {
    margin-left: 0.75rem;
    padding: 0;
    color: $primary-500;
    
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.quizFilter {
  margin: 0.75rem 0 0;

  .quizFilterTitle {
    letter-spacing: -0.003em;
    color: $gray-cold-700;
    font-size: 14px;
    font-weight: 600;
  }
}

.chipsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  gap: 0.25rem;
}

.chip {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-property: background-color, color;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  border-radius: 12px;
  background: $gray-neutral-100;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &:focus,
  &:active {
    background-color: $primary-200;
    color: $primary-500;
  }

  &:hover {
    color: $primary-500;
  }
}

.chipSelected {
  cursor: default;
  color: $gray-neutral-100;
  background-color: $primary-500;

  &:hover {
    color: $gray-neutral-100;
    background-color: $primary-500;
  }
}

.chipRemove {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  padding: 0;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-property: background-color;
  color: $gray-neutral-100;
  border-radius: 50%;
  line-height: 1rem;

  &:focus,
  &:active {
    color: $gray-neutral-100;
    background-color: $primary-600;
  }

  &:hover {
    color: $gray-neutral-100;
    background-color: $primary-400;
  }
}

.chipCheckIcon {
  margin-right: 0.25rem;
}
