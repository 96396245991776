@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  height: 100vh;
}

.logo {
  width: 114px;

  img {
    max-width: 100%;
  }
}

.wrapper {
  display: flex;
  position: relative;
  justify-items: end;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}

.left {
  padding: 2.5rem 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageContainer {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: $gradient;
  padding: 0;
  height: 100%;
}

.image {
  max-height: 100%;
  max-width: 100%;
  margin-top: 10vh;
}

.copy {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
}
