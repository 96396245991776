@import 'src/styles/variables';

.container {
  max-width: 288px;
}

.toggle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-600);
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  align-items: center;
  padding: 1.5rem 0 1.5rem;
}

.rules {
  padding: 0 !important;
  margin-bottom: 1.5rem !important;
}
