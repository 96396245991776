@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
  color: var(--primary-600);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    color: var(--primary-500);
  }

  &:active,
  &:focus {
    color: var(--primary-700);
  }

  svg {
    margin-right: 0.25rem;
  }
}

.disable {
  pointer-events: none;

  color: $gray-cold-300;
}
