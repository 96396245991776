@import 'src/styles/variables';

.statusContainer {
  display: flex;
  align-items: center;
  .update {
    background: transparent !important;
    display: flex;
    text-decoration: underline;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 150%;
    margin-left: 1rem;
    cursor: pointer;
    .icon {
      margin-left: 0.25rem;
      opacity: 0;
    }
    &:hover {
      color: $primary-400;
      svg {
        opacity: 1;
      }
    }
    &:focus {
      background: transparent !important;
      color: $primary-500 !important;
    }
  }
  .lastCheck {
    color: $gray-cold-500;
    font-size: 12px;
    line-height: 150%;
  }
}
.loading {
  @extend .update;
  color: $gray-cold-500 !important;
}

.loaded {
  @extend .update;
  color: $primary-600 !important;
}
