.ql-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
}

.ql-video-wrapper iframe,
.ql-video-wrapper object,
.ql-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
