@import 'src/styles/variables';

.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--primary-100);
}

.logo {
  margin: 1.5rem 0;

  img {
    width: 114px;
  }
}

.copy {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
  margin-bottom: 1rem;
}
