@import 'src/styles/variables';

.container {
  height: var(--vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgDefault {
  background-color: var(--primary-100);
}

.organization {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-700);
  margin-top: 0.5rem;
}

.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.aligned {
    padding-bottom: 1rem;
  }
}
