@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: $gray-neutral-100;
  box-shadow: $shadow-raised;
  border-radius: 0.5rem;
  //noinspection CssInvalidPropertyValue
  overflow: overlay;
}

.wrapper {
  background-color: var(--primary-100);
}

.title {
  font-size: 24px;
  color: var(--primary-500);
  display: flex;
  padding: 1.5rem;
  align-items: center;
  svg {
    margin-left: 0.5rem;
  }
  &:hover {
    text-decoration: none;
  }
}

.create {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: $gray-cold-900;
  svg {
    margin-left: 0.5rem;
    color: var(--primary-500);
    background-color: $gray-neutral-100;
    border-radius: 50%;
  }
}

.count {
  font-weight: 600;
  font-size: 32px;
  color: $gray-cold-500;
}
