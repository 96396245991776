@import 'src/styles/variables';

.container {
  position: relative;
  border-bottom: solid 1px $gray-cold-200;
  padding-bottom: 1rem;
  margin-right: -1rem;
  margin-left: -1rem;
}

.sideBarClose {
  top: 0;
  right: 0.5rem;
}
