@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  border-radius: 0.25rem;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  line-height: 36px;
  color: $gray-cold-700;
  margin-bottom: 1rem;
}
