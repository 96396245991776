@import 'src/styles/variables';
@import 'src/styles/media-queries';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.container {
  background-color: $gray-neutral-100;
  padding: 1rem 1rem 0 1rem;
  box-shadow: $shadow-floating;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  z-index: 99;
}

.title {
  color: var(--primary-500);
  font-size: 14px;
  align-self: center;
}

.footerSidebar {
  font-size: 12px;
  color: $gray-cold-500;
  margin-left: 0.5rem;
}

@include lt-lg {
  .container {
    box-shadow: none;
    height: 100%;
  }
}

.resize {
  width: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  //background-color: $gray-neutral-500;
  cursor: col-resize;
  user-select: none;
  z-index: 1;

  &:active {
    cursor: col-resize;
  }
}

.bottom {
  margin-bottom: 106px;
}
