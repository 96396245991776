@import 'src/styles/variables';

.timeContainer {
  position: relative;
  display: flex;
  // margin-top: 0.5rem;
}

.icon {
  position: absolute;
  left: 0.313rem;
  top: 0.5rem;
  color: var(--primary-500);
}

.clock {
  margin-left: -0.75rem;
  margin-top: -0.5rem;
  border-left: none;
  position: absolute;
  right: 0.625rem;
  top: 1.188rem;
}

.utcText {
  border: none;
  font-size: 0.875rem;
  color: $gray-cold-500;
}
