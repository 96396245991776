@import "src/styles/variables";

.message {
  padding: 0.25rem;
  display: flex;
}

.warning {
  color: $yellow-600;
  background-color: $yellow-100;
}
.success {
  color: $yellow-600;
  background-color: $yellow-100;
}
.error {
  color: $yellow-600;
  background-color: $yellow-100;
}

.icon {
  margin-right: 0.25rem;
  flex-shrink: 0;
}
.text {
  font-size: 14px;
  padding-top: 0.125rem;
}
