@import 'src/styles/variables';

.container {
  max-width: 272px;
  position: relative;
  box-shadow: $shadow-raised;
  border-radius: 0.25rem;
  background: $gray-neutral-100;
  padding: 1rem;
  margin-left: 0.5rem;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary-500;
    margin-bottom: 0.5rem;
  }
  .description {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-600;
    white-space: pre-line;
  }
}
