@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  border-radius: 0.5rem;
  height: 132px;
  width: 184px;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid $gray-neutral-100;
  flex-shrink: 0;
  transition: border-color 0.2s;

  &:hover {
    border-color: $primary-300;
  }

  &:first-child {
    margin-left: 0;
  }
}

.default {
  background: $gradient;

  .cardInfo {
    color: $gray-neutral-100;
  }

  :global .dropdown .dropdown-kebab-toggle {
    color: $primary-300;

    &:hover {
      color: var(--primary-500);
    }
  }
}

.defaultLabel {
  font-size: 12px;
  line-height: 18px;
  color: $gray-neutral-100;
  margin: 0.5rem 1rem 0.25rem;
}

.expired {
  font-size: 14px;
  line-height: 20px;
  color: $red-500;
  margin: 0 1rem;
}

.cardInfo {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
}

.menu {
  position: absolute;
  top: 14px;
  right: 8px;
}

.dropdown {
  min-width: 8rem;
}

.menuItem {
  color: $gray-cold-900;
}

.remove {
  color: $red-500 !important;

  &:hover {
    color: $red-500;
  }

  svg {
    color: $red-500;
  }
}

.confirmation {
  position: absolute;
  background: $gray-neutral-100;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-top: 1rem;
}

.confirmationTitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.buttons {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: center;

  :global .btn {
    font-weight: normal;
    font-size: 12px;
    position: relative;
    padding: 3px 8px;

    &:first-child::after {
      content: '';
      z-index: 1;
      position: absolute;
      right: 0;
      top: 0;
      height: 1.5rem;
      border-right: 1px solid $gray-cold-300;
    }

    &:last-child {
      color: $red-500;
    }
  }
}
