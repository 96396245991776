@import 'src/styles/variables';

.container {
  background: var(--primary-100);
  padding: 1rem 0 1.5rem 1.5rem;
  border-radius: 0.5rem;
}

.top {
  display: flex;
  justify-content: space-between;
  padding-right: 1.5rem;
  margin-bottom: 0.5rem;
}

.logo {
  width: auto;
  margin-right: 0.5rem;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 22px;
}

.carousel {
  display: block;
}

.button {
  border-radius: 50%;
  border: 1px solid var(--primary-200);
  background-color: $gray-neutral-100;
  padding: 0.125rem;
  width: 32px;
  height: 32px;

  &[disabled] {
    color: $gray-cold-500;
  }

  &:first-child {
    margin-right: 0.5rem;
  }
}

.booksIllustration {
  margin-left: 2.313rem;
  align-self: flex-end;
}
