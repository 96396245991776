@import 'src/styles/variables';

.btn {
  white-space: nowrap;
  color: var(--primary-600);
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: var(--primary-500);
    background-color: var(--primary-100);
  }

  &:active {
    background-color: var(--primary-200);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    opacity: 1;

    &:hover {
      color: inherit;
      background-color: inherit;
    }

    &:active {
      background-color: inherit;
    }
  }

  &.inverse {
    flex-direction: row-reverse;
  }

  &.btn-sm {
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-loading {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }

  &.btn-lg {
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-loading {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      pointer-events: none;
    }
  }
}

.btn-gradient-primary {
  color: var(--button-text-color);
  background-image: var(--gradient-main);
  background-color: var(--button-background);
  border-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    color: var(--button-text-color);
    background-image: none;
    background-color: var(--primary-400);
  }

  &:active {
    background-color: var(--primary-700);
  }

  &.disabled,
  &:disabled {
    background-color: $gray-cold-300;
    background-image: none;
  }
}

.btn-gradient-secondary {
  color: var(--primary-600);
  background: var(--primary-200);
  border-color: transparent;

  &:hover {
    background-color: var(--primary-100);
  }

  &:active {
    background-color: var(--primary-300);
  }

  &.disabled,
  &:disabled {
    background-color: $gray-cold-300;
    color: #fff;
  }
}

.btn-warning {
  color: $red-500;
  background: $red-100;
  border-color: transparent;

  &:hover {
    color: $red-500;
    background-color: $red-200;
  }

  &:active,
  &:focus {
    color: $red-500 !important;
    background-color: $red-200 !important;
  }

  &.disabled,
  &:disabled {
    background-color: $gray-cold-300;
    color: $gray-cold-500;
  }
}

.btn-icon-left {
  .icon,
  svg {
    margin-right: 0.5rem;
  }

  &.btn-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;

    .icon,
    svg {
      margin-right: 0.25rem;
    }
  }

  &.btn-lg {
    padding: 0.5rem 1.5rem 0.5rem 1rem;

    .icon,
    svg {
      margin-right: 0.5rem;
    }
  }
}

.btn-icon-right {
  &.btn-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;

    .icon,
    svg {
      margin-left: 0.25rem;
    }
  }

  &.btn-lg {
    padding: 0.5rem 1rem 0.5rem 1.5rem;

    .icon,
    svg {
      margin-left: 0.5rem;
    }
  }
}

.btn-icon {
  line-height: 1;
  padding: 0;
}

.btn-icon-shadow {
  color: $gray-cold-500;
  line-height: 1;
  padding: 0.25rem;
  box-shadow: $shadow-raised;
  background-color: $gray-cold-100;

  &:hover {
    color: $red-500;
  }

  &:active {
    background-color: $gray-cold-100;
  }
}

.btn-delete-connection {
  border-radius: 50%;

  span {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.btn-outline {
  background: #fff;
  border: 1px solid var(--primary-500);
  color: var(--primary-600);

  &.btn-sm {
    padding-top: 0.1875rem;
    padding-bottom: 0.1875rem;
  }

  &.btn-lg {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;

    &.btn-icon-left,
    &.btn-icon-right {
      padding-top: 0.4375rem;
      padding-bottom: 0.4375rem;
    }
  }
}

.btn-lg {
  line-height: 16px;
}

.btn-transparent {
  color: var(--primary-600);
  background: none;
  border-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--primary-100);
  }

  &:active {
    background-color: var(--primary-200);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    background-image: none;
    color: $gray-cold-500;
  }
}

.btn-modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: $gray-cold-500;
}
