@import 'src/styles/variables';

.container {
  display: flex;
}
.btnContainer {
  height: 32px;
  @extend .container;
}
.inputContainer {
  display: flex;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  position: relative;
  width: 288px;
  overflow: hidden;
  .input {
    display: block;
    margin: 0 auto;
  }
  .suffix {
    user-select: none;
    color: $gray-cold-500;
    font-size: 14px;
    cursor: text;
  }
  .autosize {
    height: 100%;
    input {
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}
