@import 'src/styles/variables';

.container {
  display: flex;
  gap: 2px;

  .icon {
    color: var(--primary-400);
    cursor: pointer;
    &:hover {
      color: var(--primary-500);
      background: $primary-300;
      border-radius: 0.25rem;
    }
  }
}
