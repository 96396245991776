@import 'src/styles/variables';

.container {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.6rem 0.5rem 1rem;
  height: 48px;

  &.wrong {
    background-color: $red-100;
  }

  &.success {
    background-color: $green-100;
  }

  &.waiting {
    background-color: $gray-cold-300;
    color: $gray-neutral-100;
  }
}

.text {
  font-weight: 600;
  font-size: 14px;
  margin-left: 0.5rem;

  &.wrong {
    color: $red-500;
  }

  &.success {
    color: $green-500;
  }

  &.waiting {
    color: $gray-neutral-100;
  }
}

.iconWrong {
  color: $red-500;
}

.iconSuccess {
  color: $green-500;
}
