@import 'src/styles/variables';

.btnCurrent {
  justify-content: center;
  border-color: $gray-cold-300;
  color: $gray-cold-500;
  pointer-events: none;
  background: transparent;

  &:disabled {
    opacity: 1;
  }
}
