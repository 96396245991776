@import 'src/styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.code {
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  border: none;
  height: inherit !important;
}
