@import 'src/styles/variables';

.description {
  font-size: 14px;
  color: $gray-cold-500;
  margin-left: 2.5rem;
  margin-right: 2rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-900 !important;
}

.orgName {
  @extend .title;
  color: var(--primary-500) !important;
}

.icon {
  color: $yellow-500;
}
