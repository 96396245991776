@import 'src/styles/variables';

.container {
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-weight: 600;
  font-size: 14px;
  color: $gray-cold-900;
}

.text {
  font-size: 12px;
  max-width: 158px;
  margin-bottom: 0.5rem;
  color: $gray-cold-900;
  text-align: center;
}

.btn {
  width: 100%;
}
