@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}

.descriptionContainer {
  display: flex;
  padding: 0.5rem 0;
}

.title {
  font-size: 16px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2; /* number of lines to show */
  //         line-clamp: 2;
  // -webkit-box-orient: vertical;

  &.prod {
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
  }
}

.text {
  font-size: 14px;
  color: $gray-cold-700;
}

.text + .text {
  margin-left: 1rem;
}

.cross {
  background-color: $gray-neutral-100;
  color: $gray-cold-500;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-left: auto;

  &:hover {
    color: $red-500;
    background-color: $red-100;
  }
}

.imgContainer {
  position: relative;
  width: 118px;
  height: 64px;
  flex-shrink: 0;
}

.iconContainer {
  left: 0.25rem;
  top: 0.25rem;
  width: 32px;
  height: 22px;
}

.image {
  border-radius: 2px;
  width: inherit;
  height: inherit;
}
