@import 'src/styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: $gray-neutral-100;
  margin-bottom: 1rem;
  box-shadow: $shadow-raised;
  border-radius: 0.5rem;
}

.img {
  width: 148px;
  height: 148px;
  position: absolute;
  top: -50px;
  right: 0.5rem;
  pointer-events: none;
}

.periodContainer {
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
}

.endTrialTxt {
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 14px;
}

.cardTxt {
  color: $gray-cold-700;
  font-size: 16px;
}
