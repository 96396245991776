@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  display: grid;
  grid-template-columns: max(26rem) auto;
  height: var(--vh);

  @include lt-lg {
    display: block;
    //height: 100vh;
  }
}

@include lt-lg {
  .modalDialog {
    margin: 0;
    transform: none !important;
  }
  .modalContent {
    height: var(--vh);
    max-width: $student-sidebar-width;
    border-radius: 0;
  }
  .nextLessonContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary-500);
    z-index: 1040;
    display: flex;
    height: 48px;
    justify-content: space-between;
    box-shadow: $shadow-popping;
  }
  .btn {
    background-color: transparent;
    background-image: unset;
  }
}
