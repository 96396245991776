@import 'src/styles/variables';

.disabled {
  user-select: none;
  cursor: pointer;

  :global .background {
    fill: $gray-cold-300
  }

  :global .background-2 {
    fill: $gray-cold-400
  }

  :global .element-name {
    color: $gray-cold-400;
  }
}
