@import 'src/styles/variables';

.priceInput {
  max-width: 162px;
}

.describeInput {
  max-width: 356px;
}

.description {
  font-size: 12px;
  color: $gray-cold-700;
  max-width: 162px;
  margin-left: 0.5rem;
}

.length {
  font-size: 12px;
  color: $gray-cold-500;
  margin-top: 0.25rem;
  text-align: end;
}
