@import '../variables';

.pagination-container {
  display: flex;
  align-items: center;
}

.btn-pagination-arrow {
  padding: 12px;
  line-height: 1;
  border-radius: 4px;
  box-shadow: $shadow-floating;
  color: $gray-cold-500;
  background-color: $gray-neutral-100;

  &:focus {
    box-shadow: $shadow-floating;
  }

  &:disabled {
    pointer-events: none;
    box-shadow: 0 1px 5px -2px rgba(7, 7, 55, 0.15);
  }
}

.pagination {
  margin: 0;
}

.page-link {
  display: block;
  margin-left: 0;
  text-decoration: none;
  font-size: 14px;
  min-width: 24px;
  border-radius: 4px;
  user-select: none;

  &:hover {
    text-decoration: none;
    background-color: var(--primary-100);
    box-shadow: $pagination-focus-box-shadow;
  }

  &:active {
    background-color: var(--primary-200);
  }

  &:focus {
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  margin: 0.125rem;

  &.active .page-link {
    box-shadow: $pagination-focus-box-shadow;
  }

  &.disabled .page-link {
  }
}
