@import 'src/styles/variables';

.productForm {
  padding: 1.5rem;
}

.deleteButton {
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  color: $gray-cold-500;
}

.coursePreview {
  width: 10.6rem !important;
}
