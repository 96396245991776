@import 'src/styles/variables';

.container {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin-left: 0.5rem;
}

.icon {
  color: var(--primary-500);
}

.cross {
  position: absolute;
  cursor: pointer;
  right: 2rem;
  top: 2rem;
  color: $gray-cold-500;
}
