@import 'src/styles/variables';

@mixin switch($res: '100') {
  $fontColor: $green-500;
  @if $res == '100' {
    $fontColor: $green-500;
  } @else if $res == '75' {
    $fontColor: $primary-500;
  } @else if $res == '50' {
    $fontColor: $yellow-500;
  } @else if $res == '25' {
    $fontColor: $red-500;
  }
  font-weight: 600;
  font-size: 64px;
  color: $fontColor;
  text-align: center;
  line-height: 64px;
}

.title100 {
  @include switch('100');
}

.title75 {
  @include switch('75');
}

.title50 {
  @include switch('50');
}

.title25 {
  @include switch('25');
}

.description {
  font-size: 24px;
  color: $gray-cold-700;
  text-align: center;
}
.descriptionAny {
  @extend .description;
  font-size: 1rem;
}

.text {
  font-size: 24px;
  line-height: 150%;
  margin-top: 2rem;
}

.image {
  width: 128px;
  height: 128px;
}

.notification {
  color: $gray-cold-700;
  text-align: center;
}
