@import 'src/styles/variables';

@mixin switch($res: 'unselected') {
  $fontColor: var(--primary-300);
  $borderColor: var(--primary-300);
  $background: $gray-neutral-100;
  @if $res == 'selected' {
    $borderColor: var(--primary-500);
    $background: $gray-neutral-100;
    $fontColor: var(--primary-500);
  } @else if $res == 'success' {
    $borderColor: transparent;
    $background: $green-100;
    $fontColor: $green-500;
  } @else if $res == 'error' {
    $borderColor: transparent;
    $background: $red-100;
    $fontColor: $red-500;
  } @else if $res == 'error-selected' {
    $borderColor: $red-500;
    $background: $red-100;
    $fontColor: $red-500;
  } @else if $res == 'success-selected' {
    $borderColor: $green-500;
    $background: $green-100;
    $fontColor: $green-500;
  } @else if $res == 'unselected-moderation' {
    $borderColor: transparent;
    $background: var(--primary-200);
    $fontColor: var(--primary-500);
  } @else if $res == 'selected-moderation' {
    $borderColor: var(--primary-500);
    $background: var(--primary-200);
    $fontColor: var(--primary-500);
  }

  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $borderColor;
  background: $background;
  //padding: 0 1rem;
  //margin-right: 0.25rem;
  //margin-left: 0.25rem;
  width: 32px;
  height: 32px;
  line-height: 1;
  cursor: pointer;
  > .text {
    font-weight: 600;
    font-size: 14px;
    color: $fontColor;
    white-space: nowrap;
  }
}

.containerUnselected {
  @include switch();
}

.containerSelected {
  @include switch('selected');
}

.containerSuccess {
  @include switch('success');
}

.containerSuccessSelected {
  @include switch('success-selected');
}

.containerToModerationSelected {
  @include switch('selected-moderation');
}

.containerToModerationUnSelected  {
  @include switch('unselected-moderation');
}

.containerError {
  @include switch('error');
}

.containerErrorSelected {
  @include switch('error-selected');
}

.result {
  background: var(--primary-100);
  border-radius: 1rem;
  color: var(--primary-300);
  font-weight: 600;
  font-size: 14px;
  padding: 0.375rem 1rem;
  cursor: pointer;

  &.active {
    color: var(--button-text-color);
    background: var(--primary-500);
  }
}
