@import 'src/styles/variables';

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.icon {
  position: absolute;
  left: 15px;
  top: 4px;
  line-height: 1;
  color: $gray-cold-500;
  z-index: 1;
}

.hide {
  visibility: hidden;
  width: 100%;
  height: 0;
}
