@import 'src/styles/variables';

.container {
  display: flex;
}

.message {
  margin-left: 0.75rem;
  flex-grow: 1;
}

.icon {
  border-radius: 50%;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  margin-bottom: 0.25rem;
}

.text {
  font-size: 14px;
  line-height: 1.43;
  color: $gray-cold-500;
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: $gray-cold-500;
}

:global .Toastify__toast--success {
  :local .icon {
    background-color: $green-100;
    color: $green-600;
  }
  :local .title {
    color: $green-600;
  }
}

:global .Toastify__toast--info {
  :local .icon {
    background-color: $green-100;
    color: $green-600;
  }
  :local .title {
    color: $green-600;
  }
}

:global .Toastify__toast--error {
  :local .icon {
    background-color: $red-100;
    color: $red-600;
  }
  :local .title {
    color: $red-600;
  }
}

:global .Toastify__toast--warning {
  :local .icon {
    background-color: $yellow-100;
    color: $yellow-600;
  }
  :local .title {
    color: $yellow-600;
  }
}
