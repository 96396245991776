@import 'src/styles/variables';

.container {
  background: $primary-100;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
