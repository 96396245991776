@import 'src/styles/variables';

.container {
  max-width: 768px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 1.875rem;
  min-height: 50vh;
}

.title {
  font-size: 24px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.tabContainer {
  background: $gray-neutral-100;
}

.tab {
  background-color: var(--primary-100);
  border-radius: 2px;
  width: 92px;
  height: 32px;
  font-size: 14px;
  color: var(--primary-500);

  &.active {
    background: var(--primary-500);
    color: $gray-neutral-100;
  }
}

.tab + .tab {
  margin-left: 2px;
}

.status {
  position: absolute;
  top: 10px;
  right: 0;
}

.actionContainer {
  padding-left: 6rem !important;
  z-index: 10;

  &.full {
    padding-left: 0 !important;
  }
}

.priceContainer {
  position: relative;
}
