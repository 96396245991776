@import 'src/styles/variables';

.dropdown {
  width: 310px;
  padding: 0 !important;
}

.dropdownItem {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  border-bottom: 1px solid $gray-cold-200;

  &:last-child {
    border-bottom: none;
  }

  &.link {
    cursor: pointer;
  }
}

.dropdownName {
  font-size: 14px;
  line-height: 24px;
  color: $gray-cold-700;
}

.dropdownDescription {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-600;

  &.url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropdownInfo {
  flex-grow: 1;
  overflow: hidden;
}

.label {
  padding-left: 1.75rem !important;
}
