@import 'src/styles/variables';

.container {
  width: 336px;
  height: 250px;
  overflow: auto;
  box-shadow: $shadow-popping;
  border: none;
}

.scrollContainer {
  overflow-y: auto;
  flex: 1;
}

.headerContainer {
  display: flex;
  align-items: center;
  background: $gray-neutral-200;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 1px solid $gray-cold-200;
}

.titleTextContainer {
  white-space: nowrap;
  height: 3.625rem;
  flex-grow: 1;
  min-width: 0;

  .titleText {
    font-size: 14px;
    font-weight: 600;
    color: $gray-cold-700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.cancel {
  font-size: 12px;
  color: $gray-cold-500;
  border: none;
}

.footerContainer {
  composes: headerContainer;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-top: 1px solid $gray-cold-200;
}

.paddingContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.newFolderContainer {
  color: var(--primary-600);
  .newFolderText {
    font-size: 14px;
    font-weight: 600;
    margin-right: 0.5rem;
  }
}

.btn {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-600) !important;
}

.btnCreate {
  composes: btn;
  border: none;
  background-color: transparent;
}

.inputContainer {
  position: relative;
}

.checkMark {
  background-color: var(--primary-500);
  color: $gray-neutral-100;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
