@import 'src/styles/variables';

.wrapper {
  background: $gray-neutral-100;
  border-radius: 0.25rem;
  padding: 0.5rem;
  max-height: 412px;
  overflow-y: auto;
  .container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    box-shadow: 0px 2px 3px 1px rgba(7, 7, 55, 0.07);
    border-radius: 0.5rem;
    position: relative;
    background: var(--gray-neutral-100);
    margin-bottom: 0.25rem;
    &:hover {
      cursor: pointer;
      background: var(--primary-100);
    }

    .textContainer {
      .title {
        color: var(--primary-600);
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .description {
        font-size: 0.75rem;
        color: var(--gray-cold-600);
      }
      .size {
        display: flex;
        gap: 0.5rem;
      }
    }
    .downloadContainer {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-left: 2rem;
      height: 48px;
      background: linear-gradient(270deg, #ffffff 80.09%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      right: 14px;
      justify-content: flex-end;

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray-cold-600);
        margin-bottom: 0;
        @media (max-width: 576px) {
          display: none;
        }
      }
      .icon {
        color: var(--primary-500);
      }
    }
  }
}
