@import 'src/styles/variables';

.row {
  display: flex;

  &:hover {
    .actions {
      .buttons {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.actions {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;

  .buttons {
    padding: 2px 8px;
    border-radius: 14px;
    box-shadow: $shadow-raised;
    background-color: $gray-neutral-100;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;

    :global .btn {
      color: var(--primary-500);
    }

    :global .btn:not(.btn:first-child) {
      margin-left: 0.125rem;
    }

    .delete {
      color: $red-500;
    }
  }
}

.selectedRow {
  background-color: var(--primary-200);
}

:global .table {
  :global tbody {
    :local .selectedRow {
      :global td {
        color: $gray-cold-900;
      }
    }
  }
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.currencySign {
  font-weight: 600;
  font-size: 12px;
  color: $gray-cold-500;
  margin-left: 0.25rem;
}
