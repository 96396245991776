@import 'src/styles/variables';

.videoFooter {
  display: flex;
  align-items: center;

  .btn {
    height: auto;
  }
}
