@import 'src/styles/variables';
@import 'src/styles/media-queries';

.container {
  cursor: pointer;
  flex: 0 0 288px;
  max-width: 288px;
  min-width: 0;

  &:hover {
    text-decoration: none;

    .name {
      color: var(--primary-500);
    }
  }

  @include lt-sm {
    //margin-bottom: 0.5rem;
    flex-basis: 100%;
    max-width: none;
    //padding: 0 0.5rem;
  }
}

.inner {
  background: $gray-neutral-100;
  border-radius: 0.5rem;
  min-height: 100%;
  box-shadow: $shadow-raised;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include lt-sm {
    flex-direction: row;
    min-height: auto;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0.5rem 1rem 1rem;
  overflow: hidden;

  @include lt-sm {
    padding: 0.5rem;
  }
}

.imgContainer {
  position: relative;
  height: 162px;
  flex-shrink: 0;

  @include lt-sm {
    width: 82px;
    height: 106px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.category {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 0.75rem;
  background-color: $gray-neutral-100;
  padding: 3px 8px;
  font-size: 12px;
  color: var(--primary-500);
  display: flex;
  align-items: center;

  @include lt-sm {
    right: unset;
    left: 0.25rem;
    top: 0.25rem;
  }
}

.img {
  width: 100%;
  height: 162px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-900;
  margin-top: 0.5rem;
  -webkit-line-clamp: 3;
  flex: 1;
  
  @include lt-sm {
    margin-top: 0;
    height: 60px;
  }
}

.organization {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  padding-top: 0.5rem;

  @include lt-sm {
    margin-bottom: 0;
    padding-top: 6px;
  }
}

.orgImg {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;

  img {
    position: initial;
    border: none;
    width: 100%;
    height: 100%;
  }

  @include lt-sm {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0;
  }
}

.orgText {
  line-height: 2rem;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  top: 0;

  @include lt-sm {
    line-height: 1.5rem;
  }
}

.orgName {
  font-size: 14px;
  color: var(--primary-500);
  margin-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  max-width: calc(100% - 40px);

  @include lt-sm {
    font-size: 12px;
  }
}

.nextBtn {
  color: var(--primary-500);
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
