@import 'src/styles/variables';

.contactId {
  display: flex;
  align-items: flex-end;
  min-height: 3rem;
  padding: 0 1.5rem;
}

.actionButtons {
  display: flex;
  padding: 0.75rem 1.5rem;
  text-align: right;
  box-shadow: $shadow-floating;
  z-index: 1;
}

.save {
  width: 7rem;
}

.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: $gray-cold-500;
}
