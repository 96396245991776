@import 'src/styles/variables';

.imgContainer {
  border-radius: 1rem;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-neutral-100;
}

.paynetContainer {
  @extend .imgContainer;
  background: #ec6665;
  svg {
    width: 40px;
    height: 40px;
  }
}

.stripeContainer {
  @extend .imgContainer;
  background: #5433ff;
}

.paypalContainer {
  @extend .imgContainer;
  background: #113984;
}
