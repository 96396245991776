@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 416px;
  border: 1px solid $primary-300;
  border-radius: 0.25rem;
  background: var(--primary-100);
}

.spinnerSize {
  width: 64px;
  height: 64px;
  color: var(--primary-500);
}

.spinnerText {
  margin-top: 0.5rem;
  color: $gray-cold-700;
  font-size: 16px;
  font-weight: 600;
}
