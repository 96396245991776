.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 3.5rem);
  max-height: calc(100% - 3.5rem);
}

.content {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  margin: auto;
}

.dialog {
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
