@import 'src/styles/variables';

.container {
  padding: 3.5rem 3.5rem 2rem;
}

.title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.offer {
  background: url("../../../../../../assets/svg/union.svg") no-repeat;
  width: 340px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray-neutral-100;
  font-weight: 600;
  margin-bottom: 1.5rem;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 1;
  transform: scale(1);

  span {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 0.25rem;
  }

  strong {
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 0.25rem;
  }

  &.loading {
    opacity: 0;
    transform: scale(0);
  }
}
