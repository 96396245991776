@import 'src/styles/variables';

.container {
  background-color: $gray-neutral-100;
  display: flex;
  max-width: 260px;
  position: relative;
  border-radius: 0.25rem;
  max-height: 70px;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;

  &.selected {
    &:hover {
      .iconCheckmark {
        opacity: 1;
      }
    }

    .iconCheckmark {
      opacity: 1;
      background-color: var(--primary-500);
      color: $gray-neutral-100;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: auto;
    }
  }

  &.active {
    border: 1px solid $gray-neutral-300;

    &:hover {
      .text {
        color: var(--primary-500);
      }
    }
  }

  .iconCheckmark {
    opacity: 1;
  }

  &.hide {
    .imgContainer {
      img {
        opacity: 0.25;
      }
    }

    .text, .title {
      color: $gray-cold-500;
    }
  }

  &:hover:not(.active):not(.selected):not(.hide) {
    .text {
      color: var(--primary-500);
    }

    .iconCheckmark {
      opacity: 0.5;
      background-color: var(--primary-500);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: auto;
      color: $gray-neutral-100;
    }
  }
}

.imgContainer {
  border-radius: 0.25rem;
  position: relative;
  width: 96px;
  height: 54px;
  flex-shrink: 0;
  overflow: hidden;
}

.title {
  font-weight: 600;
  line-height: 1.43;
  font-size: 12px;
  color: $gray-cold-900;
}

.text {
  font-size: 12px;
  color: $gray-cold-900;
  margin-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconCheckmark {
  opacity: 0;
  position: absolute;
  color: var(--primary-500);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusContainer {
  position: absolute;
  bottom: 0.5rem;

  .customSize {
    font-size: 10px;
    line-height: unset;
  }
}
