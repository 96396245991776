@import 'src/styles/variables';

.container {
}

.chapter {
  font-weight: 600;
  font-size: 16px;
}

.title {
  @extend .chapter;
  color: var(--primary-500);
}

.icon {
  color: $yellow-500;
  margin-right: 0.5rem;
}

.description {
  margin-top: 0.75rem;
  color: $gray-cold-500;
  font-size: 14px;
}
