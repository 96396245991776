@import 'src/styles/variables';

.container {
  display: flex;
  position: relative;
}

.collapseText {
  width: 100%;
}

.icon {
  position: absolute;
  top: 3px;
  left: 100%;
  cursor: pointer;
  line-height: 1;
  color: $gray-cold-500;
}

.addressFields .form-control {
  padding-left: 0;
}
