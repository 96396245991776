@import 'src/styles/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid $gray-cold-200;
  }

  &:hover {
    background-color: var(--primary-100);
    color: var(--primary-500);

    .container {
      color: var(--primary-500);
    }

    .disable {
      color: $gray-cold-400;
    }
  }

  .disable {
    color: $gray-cold-400;
  }
}

.icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: var(--primary-500);
}

:global .iconRed {
  :local .icon {
    color: $red-500;
  }
}

.container {
  color: $gray-cold-700;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0rem 0.5rem 0.75rem;
}

.title {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;

  .disable {
    color: $gray-cold-500;
  }
}
