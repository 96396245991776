@import 'src/styles/variables';

.inputFile {
  display: none;
}

.progressContainer {
  background-color: $gray-neutral-100 !important;
  &:hover {
    border-color: transparent !important;
  }
}
