@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
  padding: 1rem 4rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.collapsed {
  height: 472px;
  overflow: hidden;
  transition: height 0.2s;
  max-width: 1152px;
  margin: 0 auto;

  &.open {
    height: auto;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1rem;

  .current & {
    color: $gray-cold-700;
  }
}

.header {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.25rem;
}
