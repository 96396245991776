@import 'src/styles/variables';

.container {
  border-radius: 0.25rem;
  background-color: var(--primary-100);
  margin-bottom: 0.25rem;
  display: flex;
  padding: 1rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--primary-200);

    .actions {
      opacity: 1;
    }
  }

  .status {
    margin-right: 0.5rem;
  }

  &.error {
    background-color: $red-100;

    .sender {
      color: $gray-cold-700;
    }

    .status {
      color: $red-500;
    }
  }

  &.loading {
    background-color: $gray-neutral-200;
  }
}

.info {
  flex-grow: 1;
}

.wrong {
  color: $yellow-500;
}

.confirmed {
  color: var(--primary-500);
}

.sender {
  margin-bottom: 0.25rem;
}

.note {
  font-size: 12px;
  line-height: 18px;
}

.action {
  font-size: 12px;
  line-height: 18px;
}

.link {
  color: var(--primary-500);
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }
}

.actions {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 0.2s;

  :global {
    .btn:disabled {
      color: $gray-cold-400 !important;
    }
  }
}

.confirmation {
  width: 222px;
  padding: 1rem 0.5rem 0.5rem;
  color: $gray-cold-900;
  text-align: center;
  font-size: 12px;
  line-height: 18px;

  strong {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: stretch;
    gap: 0.25rem;

    :global {
      .btn {
        flex-grow: 1;
      }
    }
  }
}
