@import 'src/styles/variables';

input:disabled {
  & + div {
    :global {
      .vi__character, .vi__character--selected {
        color: $gray-cold-400;
        border-color: $gray-cold-300;
      }
    }
  }
}

.container {
  height: 96px;
  width: 268px;
  margin: 0 auto;
  gap: 4px;
}

.character {
  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: 'case' on;
  color: $gray-cold-900;
  background: $gray-neutral-100;
  border: 1px solid $primary-300;
  border-radius: 0.5rem;

  .error & {
    border-color: $red-500;
    background-color: $red-100;
  }
}

.characterInactive {
  color: $gray-cold-300;
}

.characterSelected {
  border-color: var(--primary-600);
  outline: none;
}

