@import 'src/styles/media-queries.scss';
@import 'src/styles/variables';

.sidebar {
  overflow-y: auto;
  padding: 1.5rem 1rem 1rem;
  box-shadow: $shadow-raised;

  @include lt-lg {
    box-shadow: 0px 1px 0px 0px rgba(7, 7, 55, 0.07);
  }
}
