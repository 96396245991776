@import 'src/styles/variables';

.imageContainer {
  height: 72px;

  .image {
    width: 128px;
    height: inherit;
    object-fit: cover;
  }
}

.course {
  display: flex;
  border: 1px solid $primary-300;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: $gray-cold-900;
}

.actions {
  margin-top: auto;
  display: flex;
  width: 100%;
}

.status {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  text-transform: capitalize;

  &.enrolled {
    color: $green-600;
    background-color: $green-100;
  }

  &.pending {
    color: $yellow-600;
    background-color: $yellow-100;
  }

  &.started {
    color: $primary-600;
    background-color: $primary-300;
  }

  &.in_progress {
    color: $orange-700;
    background-color: $orange-300;
    text-transform: lowercase;

    ::first-letter {
      text-transform: capitalize;
    }
  }
}

.unEnroll {
  font-size: 12px;
  line-height: 1.5rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  flex-grow: 1;
}
