@import 'src/styles/variables';

.title {
  font-size: 24px;
  color: $gray-cold-900;
}

.container {
  display: grid;
  grid-template-columns: 272px auto;
  gap: 0.5rem;
  margin-top: 1.5rem;
  height: 100%;
}

.borderWrapper {
  background-color: $gray-neutral-100;
  box-shadow: $shadow-raised;
  border-radius: $border-radius;
}

.panel > * {
  margin-bottom: 0.5rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
}

.upgradeText {
  color: $red-500;
  font-size: 14px;
}

.contentContainer {
  padding: 0 1.5rem;
}

.integrationItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-cold-200;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
