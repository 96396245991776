@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.image {
  width: 100%;
}

.container {
  text-align: center;
}

.title {
  color: var(--primary-500);
}

.description {
  color: $gray-cold-700;
  font-size: 14px;
  margin-top: 2.125rem;
  margin-bottom: 0.25rem;
}
