@import 'src/styles/variables';

.container {
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.765) 0%, rgba(255, 255, 255, 0.68) 100%);
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  border-radius: 1rem;
  max-width: 512px;
  max-height: 473px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    max-width: 352px;
    max-height: 352px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .img {
    margin-bottom: 1.5rem;
    object-fit: contain;
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.25rem;
    text-align: center;
    color: var(--primary-500);
  }
  .description {
    margin-bottom: 2rem;
    text-align: center;
  }
  .btn {
    pointer-events: none;
  }
}
