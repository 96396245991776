@import 'src/styles/variables';

.contactId {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-700;
  display: flex;
  align-items: center;
  min-height: 2rem;
}

.copy {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
  cursor: pointer;
  margin-left: 0.25rem;
}

.creating {
  background-color: $primary-300 !important;
  color: var(--primary-600) !important;
}

.created {
  background-color: var(--primary-200) !important;
  color: $green-800 !important;
}
