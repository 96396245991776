@import 'src/styles/variables';

.hooray {
  background: $gradient;
}

.payment {
  padding-top: 1.5rem;
  color: $gray-cold-700;
  line-height: 22px;
  margin-bottom: 0.25rem;
}

.card {
  line-height: 22px;
  color: $gray-cold-700;
  margin-bottom: 1.5rem;
}

.note {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}
