@import 'src/styles/variables';

.elementWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 70px;
  gap: 0.5rem;
  background-color: var(--primary-100);
  padding: 0.5rem;
  border-radius: 0.25rem;
  height: 270px;
  overflow-y: auto;

  &.empty {
    display: flex;
    justify-content: center;
  }
}

.container {
  padding: 2rem;
}

.closeContainer {
  display: flex;
  justify-content: space-between;
}

.close {
  color: $gray-cold-500;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-500);

  &.black {
    color: $gray-cold-900;
  }
}

.sendEmailText {
  font-size: 14px;
  color: $gray-cold-900;
  align-items: center;
  display: flex;

  label {
    &::after {
      left: 0;
    }
  }
}

.iconEye {
  margin-left: 0.25rem;
  color: var(--primary-500);
}

.searchContainer {
  position: relative;
}

.searchMagnifier {
  color: $gray-cold-500;
  position: absolute;
  top: 0;
  left: 0.25rem;
}
