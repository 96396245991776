@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  composes: import borderWrapper from '../../main/setting.module.scss';
  max-width: 272px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;

  @include lt-lg {
    display: none;
  }
}

.wrapper {
  display: flex;
  height: calc(100% - 5.75rem);
}

.contentWrapper {
  flex-grow: 1;
  overflow-y: auto;
  composes: import borderWrapper from '../../../settings/main/setting.module.scss';
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  color: var(--primary-500);
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-500);
  margin-left: 0.5rem;
}

.description {
  font-size: 12px;
  color: $gray-cold-600;
  margin-top: 0.5rem;
  margin-bottom: auto;
}

.videoContainer {
  opacity: 0.9;
  border-radius: 0.25rem;
}

.learnText {
  font-size: 14px;
  color: $gray-cold-900;
}

.cursor {
  cursor: pointer;
}

.videoModal {
  background-color: transparent;
}
