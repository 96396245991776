@import 'src/styles/variables';

.sizeContainer {
  max-width: 256px;
  width: 100%;
  margin-bottom: 1rem;
}

.container {
  overflow-y: auto;
  height: calc(100% - 2rem);

  &.show {
    height: calc(100% - 12rem);
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  top: 3.5rem;
  left: calc((100% - 630px)/2 - 256px);
  @extend .sizeContainer;
  //height: 100%;
  background: var(--primary-100);
  box-shadow: $shadow-floating;
  border-radius: 0.5rem;
  overflow: hidden;
}

.head {
  font-weight: 600;
  font-size: 14px;
  color: $gray-cold-700;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}

.field {
  font-size: 14px;
  color: var(--primary-500);
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.field + .field {
  margin-top: 0.5rem;
}

.noSearch {
  margin-top: 2rem;
}
