@import 'src/styles/variables';

.remove {
  font-size: 12px;
  color: $red-500;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top: 1px solid $gray-cold-200;
  display: flex;
  align-items: flex-start;
}
