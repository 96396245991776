@import 'src/styles/variables';

.container {
  padding: 2.625rem 2rem 3.5rem 2rem;
  position: relative;
}

.cross {
  top: 2rem;
  right: 2rem;
  z-index: 1;
}

.iconDrip {
  color: var(--primary-500);
}

.iconFolder {
  color: $yellow-500;
}

.iconLesson {
  color: $orange-500;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin-left: 0.5rem;
}

.description {
  font-size: 14px;
  color: $gray-cold-800;
  margin-top: 0.5rem;
}

.dripOf {
  font-size: 12px;
  color: $gray-cold-700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.nodeTitle {
  font-size: 14px;
  margin-left: 0.5rem;
}
