@import 'src/styles/variables';

.container {
  display: block;

  &.fixed {
    :global {
      .ql-toolbar {
        width: 100%;
        border: none;
        box-shadow: none;
        border-radius: 0.25rem 0.25rem 0 0;

        .ql-formats {
          .ql-active,
          .ql-picker-label.ql-active,
          .ql-picker-label:hover {
            color: var(--primary-500);
          }
        }
      }

      .ql-editor {
        padding: 1rem;
        min-height: 124px;
      }

      .ql-container {
        border: none;
      }

      .ql-editor.ql-blank::before {
        left: 1rem;
      }
    }
  }

  :global {
    .ql-toolbar {
      width: 518px;
    }

    .ql-editor {
      padding: 1rem 1.5rem;
      min-height: 92px;
    }

    .readonly {
      .ql-editor {
        padding: 0.5rem;
        min-height: unset;
      }
    }
    
    .ql-editor.ql-blank::before {
      left: 1.5rem;
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      color: $gray-cold-500;
    }
  }
}
