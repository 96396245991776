@import 'src/styles/variables';

.container {
  border-radius: 0.25rem;
  padding: 0.625rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  &.info {
    background: $primary-100;
    border: 1px solid $primary-300;
  }
  &.error {
    background: $red-100;
    border: 1px solid $red-300;
  }
}

.text {
  font-size: 14px;
}

.icon {
  margin-right: 0.5rem;
  &.info {
    color: $primary-500;
  }
  &.error {
    color: $red-500;
  }
}
