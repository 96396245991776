@import 'src/styles/variables';

.container {
  padding: 1.5rem 1rem;
  border: 1px solid $gray-cold-300;
  border-radius: 0.25rem;
  background: $gray-cold-100;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
}
.commentImg {
  background: $gray-neutral-100;
  border-radius: 50%;
  color: $gray-cold-500;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eyeImg {
  @extend .commentImg;
  position: absolute;
  top: 0;
  left: 34px;
}
.imgWrapper {
  display: flex;
  margin-bottom: 0.5rem;
  margin-left: -34px;
  position: relative;
}
