@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkmarkIcon {
  width: 64px;
  height: 64px;
  color: var(--primary-500);
  background-color: var(--primary-200);
  border-radius: 50%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.warningErrorIcon {
  color: $red-500;
  width: 64px;
  height: 64px;
  margin-top: 1.5rem;
  margin-bottom: 0.875rem;
}

.crossIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: $gray-cold-500;
}

.successDescription {
  color: $gray-cold-700;
  font-size: 12px;
  line-height: 18px;
}

.successText {
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-900;
}

.errorText {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-900;
}

.title {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-900;
}
