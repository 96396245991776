@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  display: grid;
  height: calc(100vh - 64px);
  grid-template-columns: max(21.5rem) auto;

  @include lt-lg {
    display: block;
    //height: 100vh;
  }

  .content {
    overflow-y: auto;
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem 1rem 1rem;
    position: relative;
  }

  .imageEmpty {
    display: flex;
    max-width: 100%;
    height: 100%;
    margin: auto;
  }

  .quizSessions {
    width: 100%;
    max-width: 45rem;
    margin: 0 auto;
  }

  .quizSessionsList {
    .quizSessionsItem {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .quizSessionsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .quizSessionsHeaderLeft {
      display: flex;
      align-items: center;
    }

    .quizSessionsHeaderRight {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: -0.003em;
      color: $gray-cold-900;

      :global .dropdown-menu {
        min-width: auto;
      }

      .quizSessionsSortDropDown {
        display: flex;
        align-items: center;
        min-width: 48px;

        svg {
          color: var(--primary-500);
          font-size: 16px;
          height: 16px;
          width: 16px;
          margin-left: 0;
        }
      }

      .quizSessionsSort {
        display: flex;
        align-items: center;
        color: var(--primary-500);
        margin-left: 0.25rem;
        cursor: pointer;

        .quizSessionsSortItem {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gray-cold-900;
        }
      }
    }

    .quizSessionsTitle {
      margin: 0;
      color: $gray-cold-900;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
    }

    .quizSessionsCount {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
      margin: 0 0.5rem;
      padding: 0 0.5rem;
      letter-spacing: -0.003em;
      color: var(--primary-500);
      border-radius: 0.75rem;
      background-color: $gray-neutral-100;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
}
