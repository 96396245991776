.explanationContainer {
  margin-top: 1rem;
}

.explanationTitle {
  font-weight: 600;
  font-size: 14px;
}

.explanationDescription {
  font-size: 14px;
  margin-left: 2.5rem;
}
