@import 'src/styles/variables';

.container {
  padding: 1rem;
  border: 1px solid $gray-cold-300;
  border-radius: 0.25rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  background: $gray-cold-100;
  margin-top: 1rem;
}

.text {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
}

.status {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-500;
}

.imgContainer {
  background: $gray-neutral-100;
  border-radius: 50%;
  color: $gray-cold-500;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
