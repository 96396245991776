@import 'src/styles/variables';

.markQuizContain {
  display: flex;
  align-items: center;
}

.markQuizTitle {
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  font-size: 14px;
  font-weight: 600;
}

.markQuizButtons {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.markQuizButton {
  height: 1.75rem;
  margin: 0 0.25rem;
  padding: 0 0.5rem 0 0.25rem;
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-property: background-color, color, border-color;
  letter-spacing: -0.003em;
  color: $gray-cold-900;
  border: 1px solid var(--primary-300);
  background-color: transparent;
  font-weight: 400;

  .markQuizIcon {
    width: 1rem;
    height: 1rem;
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    transition-property: background-color, color;
    color: $gray-neutral-100;
    border-radius: 0.5rem;
  }

  &.markQuizButtonSuccess {
    .markQuizIcon {
      background-color: $green-500;
    }

    &:hover {
      border-color: $green-300;
      background-color: $green-100;
    }

    &:focus,
    &:active {
      border-color: $green-300;
      background-color: $green-300;
    }
  }

  &.markQuizButtonFailed {
    .markQuizIcon {
      background-color: $red-500;
    }

    &:hover {
      border-color: $red-300;
      background-color: $red-100;
    }

    &:focus,
    &:active {
      border-color: $red-300;
      background-color: $red-300;
    }
  }

  &.markQuizButtonSelected {
    cursor: default;

    &.markQuizButtonSuccess {
      color: $green-100;
      border-color: $green-500;
      background-color: $green-500;

      .markQuizIcon {
        color: $green-500;
        background-color: $green-100;
      }
    }

    &.markQuizButtonFailed {
      color: $red-100;
      border-color: $red-500;
      background-color: $red-500;

      .markQuizIcon {
        color: $red-500;
        background-color: $red-100;
      }
    }
  }
}
