@import 'src/styles/variables';

.icon {
  color: var(--primary-500);
}

.card {
  max-width: 610px;
  width: 100%;
  margin: auto;
  border: none;
  box-shadow: $shadow-raised;

  .body {
    display: flex;
    flex-direction: column;
  }

  .content {
    flex-grow: 1;
  }

  .title {
    text-align: center;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: -0.5px;
    margin-bottom: 0.5rem;
    @media (min-width: 576px) {
      text-align: left;
    }
  }

  .subtitle {
    line-height: 1.38;
    letter-spacing: -0.33px;
    color: $gray-cold-500;
  }

  .footer {
    padding: 2.25rem 0 2rem;
    margin: 0 2rem;
  }

  &.small {
    .body {
      flex-direction: row;
    }

    .content {
      margin-left: 2rem;
    }
  }
}
