@import 'src/styles/variables';

.container {
  background: $gray-cold-200;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.body {
  background: $gray-cold-100;
  border-radius: 0.5rem;
  box-shadow: $shadow-raised;
}

.item {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $gray-cold-300;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: $gray-cold-600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}

.value {
  font-weight: 600;
  color: $gray-cold-700;
  font-size: 14px;
  line-height: 20px;
}

.icon {
  margin: -4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.id {
  font-size: 14px;

  span {
    color: $gray-cold-500;
  }
}
