@import 'src/styles/variables';

.successRadio {
  > label::after {
    background-image: none !important;
    background-color: $green-500 !important;
  }

  > label::before {
    background-color: transparent !important;
    border-color: $green-500 !important;
  }
}

.wrongRadio {
  > label::after {
    background-image: none !important;
    background-color: $red-500 !important;
  }

  > label::before {
    background-color: transparent !important;
    border-color: $red-500 !important;
  }
}

.successCheck {
  > label::after {
    background-image: none !important;
    background-color: $gray-neutral-100 !important;
    border-radius: 0.25rem !important;
  }

  > label::before {
    z-index: 100;
    border-color: $green-300 !important;
    background-color: transparent !important;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzM0RDkwMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4yOTMgOC43OTNjLjM5LS4zOSAxLjAyNC0uMzkgMS40MTQgMCAuMzYuMzYuMzg4LjkyOC4wODMgMS4zMmwtLjA4My4wOTQtNyA3Yy0uMzYuMzYtLjkyOC4zODgtMS4zMi4wODNsLS4wOTQtLjA4My00LTRjLS4zOS0uMzktLjM5LTEuMDI0IDAtMS40MTQuMzYtLjM2LjkyOC0uMzg4IDEuMzItLjA4M2wuMDk0LjA4M0wxMSAxNS4wODVsNi4yOTMtNi4yOTJ6Ii8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K') !important;
    background-position: -4px;
  }
}

.wrongCheck {
  > label::after {
    background-image: none !important;
  }

  > label::before {
    border-color: $red-500 !important;
    background-color: $red-100 !important;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iI0YyMEQ1RCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyIDdjLjUxMyAwIC45MzYuMzg2Ljk5My44ODNMMTMgOHYyLjk5OUwxNiAxMWMuNTUyIDAgMSAuNDQ4IDEgMSAwIC41MTMtLjM4Ni45MzYtLjg4My45OTNMMTYgMTNsLTMtLjAwMVYxNmMwIC41NTItLjQ0OCAxLTEgMS0uNTEzIDAtLjkzNi0uMzg2LS45OTMtLjg4M0wxMSAxNnYtMy4wMDFMOCAxM2MtLjU1MiAwLTEtLjQ0OC0xLTEgMC0uNTEzLjM4Ni0uOTM2Ljg4My0uOTkzTDggMTFsMy0uMDAxVjhjMC0uNTUyLjQ0OC0xIDEtMXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMTIgLTEwNCkgdHJhbnNsYXRlKDgwIDg4KSB0cmFuc2xhdGUoMjMyIDE2KSByb3RhdGUoNDUgMTIgMTIpIi8+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=') !important;
    background-position: -4px;
  }

  > label:checked {
    background-color: none !important;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  background: var(--primary-100);

  & + .container {
    margin-top: 0.25rem;
  }

  &:hover:not(.isPassed),
  &.isCorrect {
    background: var(--primary-200);
  }
}

.avatar {
  border-radius: 50%;
  position: absolute;
  left: -12px;
  z-index: 1;
}

.isCheck {
  label {
    color: $gray-cold-900 !important;
  }
}

.wrapper {
  display: flex;
  font-size: 12px;
  color: $gray-cold-700;

  .isCorrectMultiTooltip {
    color: $green-700;
  }

  .isIncorrectMultiTooltip {
    color: $red-500;
  }
}

.selectImage {
  max-height: 360px;
}
