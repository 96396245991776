@import '../variables';

.table {
  border-radius: 4px;
  box-shadow: $shadow-floating;
  background-color: $gray-neutral-100;
  border: none;
  display: block;

  thead {
    display: block;

    tr {
      display: flex;
    }

    th {
      background: $gray-cold-200;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: normal;
      color: $gray-cold-700;
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      border: 0;
      border-right: 1px solid $gray-neutral-300;
      display: flex;
      align-items: center;
      height: 2rem;
      user-select: none;

      &:last-child {
        border-right: none;
      }

      &.sortable {
        cursor: pointer;
      }
    }
  }

  tbody {
    display: block;

    tr {
      transition: background-color 0.2s;
      display: flex;

      td {
        border-right: 1px solid $gray-neutral-300;
        border-bottom: 1px solid $gray-neutral-300;
        border-top: none;
        padding: 0.625rem 1rem;
        font-size: 14px;
        line-height: 1.375;
        color: $gray-cold-700;
        transition: color 0.2s;
        min-width: 0;

        &:last-child {
          border-right: none;
        }

        &.react-bs-table-no-data {
          width: 100%;
          border-bottom: none;

          &:hover {
            background-color: $gray-neutral-100;
          }
        }

        &.center-cell {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &:first-child {
        td {
          border-top: 0;
        }
      }
    }
  }

  &-hover {
    tbody {
      tr {
        td {
          transition: background-color 0.1s;
          cursor: pointer;
        }

        &:hover {
          background-color: transparent;

          td {
            color: $gray-cold-900;
            background-color: var(--primary-200);

            &.react-bs-table-no-data {
              background-color: $gray-neutral-100;
            }
          }
        }
      }
    }
  }

  .sorting {
    color: $gray-cold-500;
    line-height: 1;
  }

  &-scrolled {
    overflow-y: auto;
  }
}
