@import 'src/styles/variables';

.container {
  position: absolute;
  left: 0.5rem;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  display: none;
  pointer-events: none;

  .tagActions {
    pointer-events: all;
    line-height: 1;
    margin-right: 0.25rem;
  }
}

.wrapperTag {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.searchInput {
  position: relative;
  border: none;
  border-radius: 12px;
  font-size: 12px;
  box-sizing: content-box;
  padding: 3px 8px;
  min-width: 40px;

  &:focus {
    outline: none;
  }

  .tagName {
    overflow: hidden;
    max-width: 140px;
    white-space: nowrap;
    position: relative;

    .shadow {
      position: absolute;
      top: 0;
      left: 116px;
      width: 32px;
      height: 18px;
    }

    &.hidden {
      visibility: hidden;
      width: 100%;
      height: 0;
    }
  }

  &:hover {
    .container {
      display: flex;
    }

    .tagName {
      opacity: 0.3;
    }
  }
}
