@use 'sass:math';
@import 'colors';

$font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

// Common variables
$header-height: 4rem;
$content-padding-top: 2rem;
$content-padding-bottom: 2rem;
$page-top-panel-height: 3.5rem;
$student-sidebar-width: 26rem;
$student-navbar-height: 80px;
$sidebar-menu-width: 6rem;

$border-color: var(--primary-200);
$border-radius: 0.25rem;

// Bootstrap variables
$body-color: $gray-cold-900;

$input-btn-focus-width: 0;
$text-muted: $gray-cold-500;

// Bootstrap Colors
$primary: $primary-500 !default;
$secondary: $primary-200 !default;
$success: $green-600 !default;
//$info:          $cyan !default;
$warning: $yellow-500 !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;

// Cards
$card-border-color: $border-color;
$card-spacer-x: 2rem;
$card-cap-bg: $gray-neutral-100;

// Buttons
$btn-font-family: $font-family;
$btn-font-weight: 600;
$btn-border-width: 0;
// small
$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 0.75rem;
$btn-font-size-sm: 14px;
$btn-line-height-sm: 1.43;
$btn-border-radius-sm: $border-radius;
// large
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-font-size-lg: 14px;
$btn-line-height-lg: 1.43;
$btn-border-radius-lg: $border-radius;

// Forms
$input-color: $gray-cold-500;
$input-font-size: 14px;
$input-line-height: 1.3;
$input-padding-y: 0.875rem;
$input-padding-x: 1rem;
$input-color: $gray-cold-500 !default;
$input-border-color: var(--primary-300) !default;
$input-hover-border-color: var(--primary-500) !default;
$input-border-radius: $border-radius !default;
$input-focus-border-color: var(--primary-600) !default;
$input-focus-color: $gray-cold-900 !default;
$input-placeholder-color: $gray-cold-500 !default;
$input-plaintext-color: $gray-cold-900 !default;
$input-disabled-bg: $gray-neutral-100;

$form-group-margin-bottom: 2.375rem;

// Dropdown
$dropdown-min-width: 12rem;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;
//$dropdown-spacer: 0;
$dropdown-font-size: 14px;
$dropdown-color: $gray-cold-500;
$dropdown-bg: $gray-neutral-100;
$dropdown-border-width: 1px;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $border-radius;

$dropdown-item-padding-y: 1rem;
$dropdown-item-padding-x: 1rem;
$dropdown-link-color: $gray-cold-500;

$dropdown-link-hover-bg: var(--primary-100);
$dropdown-link-active-bg: $primary-200;
$dropdown-link-hover-color: $gray-cold-700;
$dropdown-link-active-color: $gray-cold-900;

// Modals
$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 600px !default;
$modal-sm: 400px !default;

$modal-backdrop-bg: rgba($primary-900, 0.5);
$modal-backdrop-opacity: 1;
$modal-content-border-width: 0;
$modal-header-padding: 2rem;
$modal-inner-padding: 2rem;
$modal-footer-margin-between: 1rem;

// z-indexes
$z-index: 1;
$palette-z-index: $z-index + 1;

$shadow-raised: 0 2px 3px 1px rgba(7, 7, 55, 0.07);
$shadow-popping: 0 2px 15px 4px rgba(7, 7, 55, 0.06);
$shadow-floating: 0 2px 10px 2px rgba(7, 7, 55, 0.08), 0 1px 5px -2px rgba(7, 7, 55, 0.15);
$shadow-well: inset 0 1px 1px 1px rgba(7, 7, 55, 0.08), inset 0 -1px 1px 1px rgba(196, 206, 255, 0.08);

// Global margins
$content-margin-top: 4rem;

//tooltip
$tooltip-bg: $gray-cold-900;

// pagination
$pagination-padding-x: 0.5rem;
$pagination-padding-y: 0.125rem;
$pagination-line-height: 1.43;
$pagination-color: $gray-cold-500;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-border-color: transparent;
$pagination-hover-color: $primary-600;
$pagination-hover-bg: $gray-neutral-100;
$pagination-hover-border-color: transparent;
$pagination-focus-outline: none;
$pagination-focus-box-shadow: $shadow-floating;
$pagination-active-color: $primary-600;
$pagination-active-bg: $gray-neutral-100;
$pagination-active-border-color: transparent;
//$pagination-disabled-color:
//$pagination-disabled-bg;
//$pagination-disabled-border-color;

// tooltips
$tooltip-bg: $gray-cold-800;
$tooltip-font-size: 0.75rem;

// Spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 2
    ),
    6: (
      $spacer * 2.5
    ),
    7: (
      $spacer * 3
    )
  ),
  $spacers
);

$headings-margin-bottom: math.div($spacer, 2) !default;

$transition-collapse-width: width 0.35s ease !default;

$gradient-main: linear-gradient(270deg, var(--primary-400) 0%, var(--primary-500) 100%);
$gradient: linear-gradient(270deg, #008bff 0%, #0059ff 100%);
