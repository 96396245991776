@import 'src/styles/variables';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-cold-200;
  padding-bottom: 1.5rem;
}

.icon {
  margin-right: 1.5rem;
}

.title {
  font-size: 24px;
}

.description {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;

  :global .form-control {
    border-color: $primary-300;
  }
}

.reload {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
  color: $gray-cold-500;
}

.button {
  width: 120px;
  justify-content: center;
}

.key {
  background: var(--primary-100) !important;
  border: 1px solid $primary-300 !important;
  color: $gray-cold-900 !important;
}
