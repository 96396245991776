@import 'src/styles/variables';

.trashContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0.5rem;
  border-radius: 0.25rem;
  background: $gray-neutral-200;
  margin-bottom: 0.5rem;
  svg {
    color: $red-500;
  }
}
