@import 'src/styles/variables';

.eventsContainer {
  position: absolute;
  left: 5rem;
}

.eventsNode {
  width: 7rem;
  padding: 0.5rem;
  background-color: $gray-neutral-100;
  border-radius: $border-radius;
  border: 1px solid $gray-cold-300;
}

.eventNode {
  border: 2px solid transparent;
  padding: 0.25rem 0.5rem 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: $primary-300;
  }

  &.selected {
    border-color: var(--primary-500);
    background-color: var(--primary-200);

    .delete {
      display: block;
    }
  }
}

.delete {
  position: absolute;
  display: none;
  top: -0.75rem !important;
  right: -0.75rem !important;
  z-index: 1;
}

.nodeName {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-600);
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.nodePreview {
  text-align: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.addEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 1.5;
  color: $gray-cold-500;
  padding: 0.75rem;

  .button {
    margin-top: 0.25rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
