@import 'src/styles/variables';

.container {
  display: block;
}

.description {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1rem;
}

.inputNote {
  color: $gray-cold-500;
  font-size: 12px;
  line-height: 18px;
  margin-top: 0.25rem;
}

.full {
  max-width: none;
}

.actions {
  margin: -1rem -1.5rem;
  padding-top: 1rem;
  overflow: hidden;

  & > div {
    box-shadow: $shadow-floating;
    padding: 1rem 1.5rem;
    display: flex;
  }
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}
