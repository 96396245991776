@import 'src/styles/variables';

.wrapper {
  padding: 2.5rem 2rem;
}

.container {
  position: fixed;
  top: calc(50% + 64px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 24px;
  color: $gray-cold-700;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 16px;
  color: $gray-cold-700;
  text-align: center;
  max-width: 330px;
  margin: 0 auto 2rem;
}

.btn {
  margin-left: auto;
  margin-right: auto;
}
