@import 'src/styles/variables';

.container {
  color: $gray-cold-700;
  text-align: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.text {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.img {
  min-height: 232px;
  margin-bottom: 1.5rem;
}
