@import 'src/styles/variables';

.export {
  width: 7.875rem;
}

.exporting {
  background-color: $primary-300 !important;
  color: var(--primary-600) !important;
}
