@import 'src/styles/variables';

.icon {
  color: var(--primary-500);
  margin-right: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.contentContainer {
  padding: 2rem;
  position: relative;
}

.closeContainer {
  top: 2rem;
  right: 2rem;
}
