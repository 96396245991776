@import 'src/styles/variables';

.error {
  position: absolute;
  top: 100%;
  color: $red-600;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
}
