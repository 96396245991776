@import 'src/styles/variables';

.container {
  display: flex;
  &:hover {
    text-decoration: none;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  height: 24px;
  display: flex;
  align-items: flex-end;
}

.status {
  font-size: 12px;
  line-height: 18px;
  margin-top: 2px;
}

.connected {
  @extend .status;
  color: $green-700;
}

.notConnected {
  @extend .status;
  color: $primary-500;
}
