@import 'src/styles/variables';

.iconContainer {
  display: flex;
  position: absolute;
  left: 0.25rem;
  // padding-left: 0.25rem;
  .icon {
    color: $gray-cold-500;
    width: 100%;
  }
}

.label {
  font-size: 14px;
}
.value {
  color: $gray-cold-500;
}

.wrapper {
  position: relative;
  margin-left: 0.5rem;
}

.iconWrapper {
  right: 0;
  top: 0;
  width: auto;
  padding: 3px 8px;
}

.noOptionText {
  color: $gray-cold-600;
  font-size: 14px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.noOptionCourse {
  composes: noOptionText;
  max-width: 224px;
}

.iconCross {
  border-radius: 50%;
  color: $gray-cold-500;
  background: $gray-cold-200;
  cursor: pointer;
}
