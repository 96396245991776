@import 'src/styles/variables';

.container {
  padding: 0.75rem;
  background: $gray-neutral-100;
  line-height: 1;
}

.title {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: var(--primary-500);
  cursor: pointer;
}

.icon {
  margin-right: 0.25rem;
  flex-shrink: 0;
}
