@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.text {
  font-weight: 600;
  font-size: 32px;
  color: var(--primary-500);
  margin-bottom: 1.5rem;
}

.btn {
  @include lt-md {
    width: 100%;
  }
}
