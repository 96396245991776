@import 'src/styles/variables';

.container {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.answer {
  display: flex;
}

.button {
  margin-top: 1px;
  background-color: $gray-cold-100;
  border: 1px solid $gray-cold-300;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  position: relative;
}

.radio {
  border-radius: 50%;

  &.selected {
    border-color: $primary-300;

    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--primary-500);
    }
  }
}

.check {
  border-radius: 0.25rem;

  &.selected {
    border-color: $primary-300;

    .checkmark {
      position: absolute;
      top: -4px;
      left: -4px;
      color: var(--primary-500);
    }
  }
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.choice {
  display: flex;
  margin-bottom: 0.5rem;
}

.label {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 0.5rem;
  border-radius: 12px;
}

.correct {
  background: $green-200;
  color: $green-700;
}

.wrong {
  background: $red-200;
  color: $red-700;
}

.student {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 12px;
  border-radius: 12px;
  background: $gray-cold-200;
  margin-left: 0.5rem;
}

.imgPreview {
  object-fit: none;
}
.selectImage {
  margin-left: 1.5rem;
  margin-top: -0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: fit-content;
  margin-bottom: 1.375rem;

  img {
    transition: transform 0.4s;
  }

  &:hover {
    img {
      transform: scale(1.4);
    }
  }
}
