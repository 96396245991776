@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  display: flex;
  height: calc(100vh - $header-height);
  overflow-y: hidden;
  box-shadow: $shadow-raised;

  @include lt-lg {
    box-shadow: 0px 1px 0px 0px rgba(7, 7, 55, 0.07);
  }
}

.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: $shadow-raised;
  width: 344px;
  flex-grow: 0;
  flex-basis: 344px;
  max-width: 100%;
}
