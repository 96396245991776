@import 'src/styles/variables';

.nav-tabs {
  border-bottom-color: var(--primary-100);
  border-bottom-width: 2px;
  padding: 0.5rem 1.5rem 0;

  .nav-item {
    margin-bottom: -2px;
    margin-left: 0.75rem;

    &:first-child {
      margin-left: 0;
    }
  }
  .nav-link {
    font-size: 14px;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: -0.3px;
    color: $gray-cold-700;
    padding: 0.5rem 0 0.25rem;
    border-width: 0 0 2px;
    border-radius: 0;

    &.active {
      border-bottom-color: var(--primary-600);
      color: var(--primary-600);
    }
  }
}

.tab-content {
  .sidebar-container & {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
