@import 'src/styles/variables';

@mixin font-size($size: 16px) {
  .ql-snow,
  .ql-bubble {
    font-family: $font-family;

    .ql-tooltip.ql-editing {
      position: sticky;
      box-shadow: 0 2px 15px 4px rgba(7, 7, 55, 0.06);
      background: #ffffff;
      border: none;
      border-radius: 0.25rem;
      padding: 0.5rem;

      .mathquill4quill-mathquill-input {
        background-color: #fff;
        border: 1px solid var(--primary-300);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }

      .ql-action {
        font-weight: 600;
        text-align: center;
        border: 0 solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        border-radius: 0.25rem;
        white-space: nowrap;
        color: var(--button-text-color);
        background-image: var(--gradient-main);
        background-color: var(--button-background);
        border-color: transparent;
        transition: background-color 0.2s;
        line-height: 20px;
        cursor: pointer;
        margin-left: 1rem;

        &::after {
          margin: 0;
        }
      }
    }

    .ql-container {
      box-sizing: border-box;
      font-family: $font-family;
      font-size: $size;
      height: 100%;
      margin: 0;
      position: relative;
    }

    .ql-editor.ql-blank::before {
      font-size: $size;
      font-style: normal;
      font-family: $font-family;
      color: $gray-cold-500;
    }

    .ql-picker.ql-size .ql-picker-label::before,
    .ql-picker.ql-size .ql-picker-item::before {
      content: '#{$size}';
      font-size: $size;
    }

    .ql-picker.ql-size,
    .ql-picker.ql-size.ql-expanded {
      .ql-picker-label.ql-active::before,
      .ql-picker-label::before {
        font-size: 14px !important;
      }
    }

    .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
      content: '10px';
      font-size: 10px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
      content: '12px';
      font-size: 12px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
      content: '14px';
      font-size: 14px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
      content: '16px';
      font-size: 16px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
      content: '18px';
      font-size: 17px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
      content: '20px';
      font-size: 18px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='22px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='22px']::before {
      content: '22px';
      font-size: 19px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
      content: '24px';
      font-size: 20px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='30px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
      content: '30px';
      font-size: 22px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
      content: '36px';
      font-size: 23px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
      content: '48px';
      font-size: 24px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='60px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='60px']::before {
      content: '60px';
      font-size: 25px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='72px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='72px']::before {
      content: '72px';
      font-size: 26px;
    }

    .ql-picker.ql-size .ql-picker-label[data-value='96px']::before,
    .ql-picker.ql-size .ql-picker-item[data-value='96px']::before {
      content: '96px';
      font-size: 27px;
    }
  }
}

.ql-item-color {
  width: 24px;
  //filter: invert(69%) sepia(9%) saturate(624%) hue-rotate(166deg) brightness(87%) contrast(84%);
  &:hover {
    //filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
  }
}

.ql-formats {
  .ql-active {
    //filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
    color: var(--primary-500);
  }

  .ql-picker-label {
    padding: 0;
    color: $gray-cold-500;

    &:hover {
      color: var(--primary-500);
      //filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
    }
  }

  .ql-font,
  .ql-size,
  .ql-header {
    .ql-picker-label {
      svg {
        //color: var(--primary-500);
      }
    }
  }

  .ql-align {
    .ql-picker-label {
      @extend .ql-item-color;
    }
  }

  .ql-list {
    @extend .ql-item-color;
  }
}

.ql-variable {
  color: var(--primary-500);
  cursor: default;
}

.ql-bubble {
  .ql-toolbar,
  &.ql-toolbar {
    button {
      &:hover,
      &:focus,
      &.ql-active {
        color: var(--primary-500);
      }
    }

    .ql-picker-label {
      &:hover,
      &.ql-active {
        color: var(--primary-500);

        .ql-stroke {
          stroke: $gray-cold-500;
        }
      }
    }

    .ql-picker-item {
      &:hover,
      &.ql-selected {
        color: var(--primary-500);
      }
    }
  }
}
