@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.hiddenScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container {
  display: flex;
  align-items: center;
  background-color: $gray-neutral-100;
  box-shadow: $shadow-popping;
  border-radius: $border-radius;
  position: relative;
  height: 40px;
  width: 100%;
  flex-shrink: 0;
  composes: hiddenScroll;
  margin-bottom: 1rem;
  padding: 0 0.5rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 109px;
    background: linear-gradient(270deg, $gray-neutral-100 67.82%, rgba($gray-neutral-100, 0) 100%);
    pointer-events: none;

    @include lt-lg {
      display: none;
    }
  }
}

.draggableElement {
  margin: 0;
  display: flex;
  align-items: center;
  // overflow: auto;
  flex-shrink: 0;
  cursor: default;
}

.addContainer {
  border: 1px solid var(--primary-500);
  border-radius: 50%;
  color: var(--primary-500);
  display: flex;
  cursor: pointer;
}

.icon {
  margin: 0.25rem;
}

.arrow {
  color: $gray-cold-500;
  margin-bottom: 0.25rem;
}

.arrowContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.events {
    pointer-events: none;
  }
}

.hide {
  display: none;
}

.unselectable {
  user-select: none;
}

.iconArrowScroll {
  background: var(--primary-500);
  box-shadow: $shadow-raised;
  color: $gray-neutral-100;
  border-radius: 50%;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  composes: unselectable;
}
