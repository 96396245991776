@import 'src/styles/variables';

.container {
  background: $gradient;
  border-radius: 0.25rem 0.25rem 0px 0px;
}

.cardTitle {
  font-size: 16px;
  color: $gray-cold-700;
  margin-top: 1.5rem;
  text-align: center;
}

.cardDescription {
  font-size: 12px;
  color: $gray-cold-500;
  margin-top: 1rem;
  text-align: center;
}

.wrapper {
  margin: 0 1.4rem;
}
