@import 'src/styles/variables';

.wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.container {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  //flex-grow: 1;
  border-radius: 0.25rem;
}

.itemContainer {
  display: flex;
  align-items: center;
  background-color: var(--primary-100);
  margin-bottom: 0.125rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-200);

    .inputContainer {
      &:after {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $primary-200 93%);
      }
    }
  }

  &.active {
    background-color: var(--primary-500);
    cursor: default;

    &:hover {
      background-color: var(--primary-500);

      .title,
      .actions,
      .titleInput,
      .icon {
        color: $gray-neutral-100;

        :global .btn {
          color: $gray-cold-100;
        }
      }
    }

    .title,
    .actions,
    .titleInput,
    .icon {
      position: relative;
      color: $gray-neutral-100;

      :global .btn {
        color: $gray-cold-100;
      }
    }
  }

  .item {
    width: 100%;
    padding: 11px 1rem;

    .itemRow {
      display: flex;
    }

    //&.draft {
    //  background: linear-gradient(90deg, rgba($gray-cold-900, 0.07) 38%, rgba($gray-neutral-200, 0) 38%);
    //}

    &.teacher {
      padding-left: 0.25rem;
      padding-right: 0.5rem;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  color: $gray-cold-900;
  font-size: 14px;
  overflow: hidden;
  width: 100%;

  &:hover {
    .edit {
      visibility: visible;
      opacity: 1;
    }
  }
}

.titleInput {
  border: none;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 20px;
  line-height: 22px;
  color: inherit;
  caret-color: transparent;
  width: 100% !important;

  &.active {
    &:not([readonly]) {
      cursor: text;
    }

    &::placeholder {
      color: rgba($gray-neutral-100, 0.8);
    }
  }

  &:not(:read-only) {
    caret-color: auto;
  }

  &:read-only,
  &[readonly] {
    user-select: none;
  }

  &::selection {
    .active & {
      color: $primary-500;
      background: $gray-neutral-100;
    }
  }
}

.inputContainer {
  overflow: hidden;
  width: 100%;
}

.dropDeleteModal {
  color: $yellow-500;
}

.dropDelete {
  color: $red-500;

  &:hover {
    color: $red-500 !important;
  }
}

.droDeleteText {
  color: $gray-cold-900 !important;
}

.dropdown {
  position: unset;
}

.dropdownMenu {
  // transform: translate(28px, 215px) !important;
  box-shadow: $shadow-raised;
  border-radius: $border-radius;
}

.folderNavigationItem {
  display: flex;
  padding: 0 0.5rem 0 0.25rem;
  background-color: var(--primary-100);
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  align-items: center;

  .title {
    padding-left: 1rem;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      height: 1.5rem;
      width: 2rem;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f9ff 93%);
    }
  }
}

.drag {
  color: $primary-300;
  cursor: move;
  line-height: 0;
  align-self: center;
}

.icon {
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.kebab {
  color: $gray-cold-500;
}

.activeKebab {
  &:hover {
    background-color: var(--primary-400);
  }
}

.actions {
  margin-left: auto;
  color: $gray-cold-500;
  line-height: 0;

  :global .dropdown-menu {
    line-height: 1.5;
  }
}

.lesson {
  .icon {
    color: $orange-500;
  }
}

.folder {
  padding-right: 0.5rem;

  .icon {
    color: $green-500;
  }
}

.edit {
  color: $primary-300;
  margin-left: 0.25rem;
  opacity: 0;
  visibility: hidden;
  line-height: 0;
}

.folderNavigation {
  border-right: 2px solid $gray-neutral-100;
  padding: 0.625rem 0.5rem;
}

.buttons {
  border-radius: 14px;
  box-shadow: $shadow-raised;
  background-color: $gray-neutral-100;
  overflow: hidden;
  display: flex;

  :global .btn {
    padding: 0.125rem 0.5rem;
    color: $gray-cold-500;

    &:hover {
      color: var(--primary-500);
    }

    &:first-child {
      border-right: 1px solid $gray-cold-200;
    }
  }
}
