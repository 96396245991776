@import 'src/styles/variables';

#ReactTags {
  overflow-y: auto;
  max-height: 300px;

  li {
    border-bottom: 1px solid $gray-cold-200;

    &:last-child {
      border-bottom: none;
    }
  }
}
