@mixin switch($res: 'md') {
  $width: 2rem;
  $height: 2rem;

  @if $res == 'md' {
    $width: 2rem;
    $height: 2rem;
  } @else if $res == 'lg' {
    $width: 3rem;
    $height: 3rem;
  } @else if $res == 'sm' {
    $width: 1rem;
    $height: 1rem;
  }
  width: $width;
  height: $height;
}

.spinner-lg {
  @include switch('lg');
}

.spinner-md {
  @include switch('md');
}

.spinner-sm {
  @include switch('sm');
}
