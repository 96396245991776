@import 'src/styles/variables';

.container {
  & > div {
    display: flex;
    align-items: center;
    padding: 0.1875rem 0.5rem 0.1875rem 0.375rem;
    border-radius: 0.75rem;
    text-transform: capitalize;
    font-size: 12px;
  }

  :global {
    .regular {
      color: var(--primary-500);
      background-color: var(--primary-100);
    }

    .course {
      color: $orange-500;
      background-color: $orange-100;
    }
  }
}

.icon {
  margin-right: 0.125rem;
}
