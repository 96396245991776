@import 'src/styles/variables';

.container {
  height: 100vh;
  background: url('../../../../../assets/svg/auth.svg') no-repeat 100% 100% var(--primary-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
}

.logo {
  margin: 1.5rem 0;

  img {
    width: 114px;
  }
}

.form {
  width: 100%;
  max-width: 512px;
  padding: 60px 80px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.765) 0%, rgba(255, 255, 255, 0.68) 100%);
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
  border-radius: 1rem;
  border: 2px solid $gray-neutral-100;
  margin-bottom: 1.5rem;
}

.copy {
  font-size: 12px;
  line-height: 18px;
  color: $gray-neutral-100;
  margin-bottom: 1rem;
}
