@import 'src/styles/variables';

.container {
  background: $primary-100;
  border: 1px solid $primary-200;
  border-radius: 4px;
  padding: 1rem;
}

:global {
  .full-instructions {
    :local {
      .container {
        background: $gray-neutral-100;
      }
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.trouble {
  text-decoration: underline;
  color: $primary-600;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;

  &:hover {
    color: $primary-400;
  }

  &:active {
    color: $primary-400;
  }
}

.troubleText {
  white-space: pre-line;
  margin: 0;
  padding-left: 22px;

  li {
    margin-bottom: 0.5rem;
  }
}
