@import 'src/styles/variables';

.custom {
  .select__option {
    padding: 1rem 0.5rem 1rem 1rem;
    cursor: pointer;
    &:first-child {
      padding-top: 16px;
      border: none;
    }
  }

  .select__single-value {
    .badge-offer {
      display: none;
    }
  }
  .select__option--is-selected {
    background-color: $gray-neutral-100 !important;
    color: $gray-cold-900 !important;
    &:hover {
      background-color: $gray-cold-200 !important;
      color: var(--primary-500) !important;
    }
  }
  .select__option--is-focused {
    background-color: $gray-cold-200 !important;
    color: var(--primary-500) !important;
  }
  .select__indicator {
    cursor: pointer;
  }
}
.custom {
  .form-control-sm {
    .select__indicator {
      &.select__dropdown-indicator {
        padding: 2px 0 0 0;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .search {
      height: 28px;
    }
  }
}
