@import 'src/styles/variables';

.container {
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  padding: 1rem;
  background: var(--primary-100);
  border-bottom: 1px solid $gray-cold-300;
  border-radius: 0.25rem 0.25rem 0 0;
  align-items: center;
  justify-content: space-between;
}

.left {
  flex-grow: 0;
  display: flex;
  width: 185px;
}

.center {
  flex-grow: 2;
  max-width: 600px;
}

.right {
  width: 185px;
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.messageName {
  border-color: transparent;
  padding: 5px 0;
  height: unset;
  max-width: 600px;
  background-color: var(--primary-100);

  &:hover {
    border-color: $primary-300;
    padding: 5px 0.5rem;
    margin: 0 -0.5rem;
  }

  &:focus {
    padding: 5px 0.5rem;
    margin: 0 -0.5rem;
  }
}

.title {
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-700;
}

.close {
  position: relative;
  top: auto;
  right: auto;
}

.borders {
  border-right: 1px solid $gray-cold-300;
  border-left: 1px solid $gray-cold-300;
  padding: 0 1rem;
}
