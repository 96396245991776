@import 'src/styles/variables';

.title {
  font-size: 14px;
  line-height: 20px;
}

.description {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
  display: flex;
}

.timer {
  font-size: 12px;
  line-height: 18px;
}

.email {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: $gray-cold-500;
  cursor: pointer;
}
