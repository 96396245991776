@import 'src/styles/variables';
@import 'src/styles/media-queries';

.titleContainer {
  padding: 0.75rem 1.5rem;
  background: $gray-neutral-100;
  margin: 0 0 1px 0;
  border-radius: 0.25rem;
}

.contentContainer {
  background: $gray-neutral-100;
  padding: 1.5rem;
  border-radius: 0.25rem;
}
.lectureTabContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1px;
  background: $gray-neutral-100;
  padding: 0.75rem 1.5rem;
  overflow: hidden;
  border-radius: 0 0 0.25rem 0.25rem;

  span {
    display: flex;
    margin-right: 1rem;
    @include lt-md {
      margin-right: 0.5rem;
    }
  }
}
