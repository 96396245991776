@import 'src/styles/variables';

.container {
  padding: 1.5rem 3.5rem 2rem;
}

.title {
  padding: 2rem 3.5rem;
  background: $red-100;
  color: $red-500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  border-radius: 0.5rem 0.5rem 0 0;

  svg {
    margin-bottom: 1rem;
  }
}
.text {
  line-height: 22px;
  margin-bottom: 1.5rem;
  text-align: center;
}
