@import 'src/styles/variables';

.container {
  display: flex;
  position: relative;
  cursor: default;
  &:hover {
    background: var(--primary-100);
    border-radius: 0.5rem;
    overflow: hidden;
    .loaderContainer {
      background: linear-gradient(270deg, #f5f9ff 80.09%, rgba(255, 255, 255, 0) 100%, rgba(245, 249, 255, 0) 100%);
    }
    .fileName {
      color: var(--primary-600);
    }
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  .fileName {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-600;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.loaderContainer {
  padding-left: 2rem;
  height: 48px;
  background: linear-gradient(270deg, #ffffff 80.09%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.loader {
  margin-right: 1rem;
}

.cancelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  .cancelText {
    font-size: 12px;
    line-height: 18px;
    color: $red-500;
    margin-right: 0.5rem;
    display: none;
  }

  .canceledIcon {
    border-radius: 50%;
    background: $gray-neutral-100;
    color: $gray-cold-500;
  }
  &:hover {
    .canceledIcon {
      background: $red-500;
      color: $gray-neutral-100;
    }
    .cancelText {
      display: block;
    }
  }
}
