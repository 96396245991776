@import 'src/styles/variables';

.header {
  // position: sticky;
  top: -1rem;
  background: $gray-neutral-100;
  z-index: 1;
  width: 100%;
  position: absolute;
  &.isPinned {
    box-shadow: 0px 1px 5px -2px rgba(7, 7, 55, 0.15), 0px 2px 10px 2px rgba(7, 7, 55, 0.08);
  }
  @media (min-width: 576px) {
    top: 0rem;
  }
}

.wrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  align-self: flex-start;
  // margin-top: 128px;
  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -48px;
}

.loadMoreContainer {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #f5f9ff 38.24%, rgba(245, 249, 255, 0) 92.16%);
  height: 100px;
  .load {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    color: $primary-500;
    cursor: pointer;
    z-index: 11;
  }
}
