@import 'src/styles/variables';

.field-type-select {
  .select {
    &__control {
      border-width: 0 0 1px;
      border-color: $primary-300;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;

      &--menu-is-open {
        border-color: var(--primary-500);
      }

      &--is-focused {
      }
    }

    &__value-container {
      padding: 0;

      &--has-value {
      }
    }

    &__placeholder {
      font-size: 14px;
      color: $gray-cold-500;
    }

    &__single-value {
      display: flex;
      align-items: center;
      color: $gray-cold-900;

      .icon {
        line-height: 1;
        margin-right: 0.5rem;
        color: $gray-cold-500;
      }

      .label {
        font-size: 14px;
        line-height: 1;
      }
    }

    &__input {
    }

    &__indicators {
    }

    &__indicator {
      padding: 2px;
    }

    &__dropdown-indicator {
    }

    &__menu {
      border-radius: 4px;
      box-shadow: $shadow-floating;
      background-color: $gray-neutral-100;
      z-index: 100;
    }
    &__menu-list {
    }

    &__option {
      display: flex;
      padding: 0.25rem 0.5rem;
      border-top: 1px solid $gray-cold-200;
      cursor: pointer;
      align-items: center;
      color: $gray-cold-700;

      &--is-selected {
        color: $gray-cold-900;
        background-color: transparent;

        &.select__option--is-focused {
          background-color: var(--primary-100);
          color: $gray-cold-700;
        }

        .icon {
          color: $gray-cold-100;
        }
      }

      &--is-focused {
        background-color: var(--primary-100);
      }

      .icon {
        line-height: 1;
        margin-right: 0.5rem;
        color: $gray-cold-500;
      }

      .label {
        font-size: 14px;
        line-height: 1;
      }
    }
  }
}
