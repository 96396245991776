@import 'src/styles/variables';

.answersCurses {
  margin-top: 1.25rem;

  .answersCursesTitle {
    margin: 0 0 0.5rem;
    letter-spacing: -0.003em;
    color: $gray-cold-700;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .answersCursesList {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .answersCursesItem {
      display: flex;
      align-items: flex-start;
      width: 100%;
      min-height: 3rem;
      padding: 0.5rem;
      cursor: pointer;
      transition-timing-function: ease-in-out;
      transition-duration: 0.25s;
      transition-property: background-color, color;
      letter-spacing: -0.003em;
      color: $gray-cold-900;
      border-radius: 0.25rem;
      background-color: $gray-neutral-100;
      font-size: 12px;

      &.answersCursesItemDefault {
        justify-content: space-between;
        align-items: center;
      }

      &:hover {
        color: $primary-500;

        .answersCursesItemIcon {
          color: $primary-300;
        }
      }

      &:focus,
      &:active {
        color: $primary-500;
        background-color: $primary-200;

        .answersCursesItemIcon {
          color: $primary-400;
        }
      }

      .answersCursesItemTitle {
        // display: -webkit-box;
        // overflow: hidden;
        // -webkit-box-orient: vertical;
        margin: 0;
        // padding: 0.25rem;
        // text-overflow: ellipsis;
        // -webkit-line-clamp: 2; /* number of lines to show */
      }

      .answersCursesItemIcon {
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
        transition-property: color;
        color: $gray-neutral-100;
      }

      .answersCursesItemImage {
        width: 4rem;
        height: 2.25rem;
        margin-right: 0.25rem;
        object-fit: cover;
      }

      &.answersCursesItemSelected {
        color: $gray-neutral-100;
        background-color: $primary-500;

        &:hover {
          background-color: $primary-400;
        }

        &:focus,
        &:active {
          background-color: $primary-600;
        }

        .answersCursesItemIcon {
          color: $gray-neutral-100;
        }

        &.answersCursesItemDefaultDisabled {
          cursor: default;

          &:hover {
            background-color: $primary-500;
          }

          &:focus,
          &:active {
            background-color: $primary-500;
          }
        }
      }
    }

    .answersCursesListEmpty {
      margin: 0;
      letter-spacing: -0.003em;
      color: $gray-cold-700;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
