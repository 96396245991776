@import 'src/styles/variables';

.container {
  background: var(--primary-700);
  height: 40px;
  color: $gray-neutral-100;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: $yellow-500;
  text-decoration: none !important;
  &:hover {
    color: $yellow-500;
  }
}
