@import 'src/styles/variables';

.container {
  position: relative;
  padding: 1rem 1.5rem 1.5rem 1.5rem;

  .cross {
    cursor: pointer;
    color: $gray-cold-500;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
}

.popoverContainer {
  width: 336px;
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
  border: none;
}
