@import 'src/styles/variables';

.dotted {
  background-color: $gray-neutral-200;
  width: 100%;
}

.funnel-canvas {
  width: 100%;
  height: calc(100vh - 4rem - 2px);
  position: relative;

  .full & {
    height: calc(100vh - 2px);
  }

  &::before {
    position: absolute;
    top: -5000px;
    left: -5000px;
    width: 10000px;
    height: 10000px;
    content: '';
    z-index: -1;
    background-image: linear-gradient(transparent, transparent 2.5px, $gray-neutral-200 2.5px, $gray-neutral-200 25px),
      linear-gradient(to right, $gray-cold-300, $gray-cold-300 2.5px, $gray-neutral-200 2.5px, $gray-neutral-200 25px);
    background-size: 30px 30px;
    background-position: 0 0;
  }
}

#TRAFFIC_SOURCE_INSTAGRAM_AD,
.TRAFFIC_SOURCE_INSTAGRAM_AD {
  .element-preview {
    position: relative;
    &:before {
      position: absolute;
      top: 49px;
      left: 49px;
      content: '';
      width: 24px;
      height: 24px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMS41IiBmaWxsPSIjRkZGIiBzdHJva2U9IiNDQ0UzRkYiLz4KICAgIDxnIGZpbGw9IiMwMDcyRkYiPgogICAgICAgIDxwYXRoIGQ9Ik0xMS41IDNjLS4xMzIgMC0uMjYuMDUzLS4zNTMuMTQ3LS4wOTQuMDkzLS4xNDcuMjItLjE0Ny4zNTR2LjMxOGMtLjg1MyAxLjA2LTIuMTQzIDEuNjY4LTMuNTAyIDEuNjczSDQuNDk5Yy0uODI3LjAwMS0xLjQ5OC42NzMtMS40OTkgMS41MDJ2MWMwIC44My42NzIgMS41MDIgMS41IDEuNTAyaC4yNDFsLTEuMiAyLjgwNmMtLjA2Ny4xNTUtLjA1MS4zMzMuMDQxLjQ3My4wOTMuMTQuMjUuMjI1LjQxOC4yMjVoMS45OTljLjIgMCAuMzgtLjEyLjQ1OS0uMzA0bDEuMzYxLTMuMThjMS4yNDMuMDkgMi4zOTcuNzAzIDMuMTgxIDEuNjc0di4zMTljMCAuMjc2LjIyNC41LjUuNXMuNS0uMjI0LjUtLjVWMy41YzAtLjEzMy0uMDUzLS4yNi0uMTQ2LS4zNTRDMTEuNzYgMy4wNTMgMTEuNjMzIDMgMTEuNSAzem0tNyA1LjQ5NWMtLjI3NyAwLS41LS4yMjQtLjUtLjVWNi45OTRjMC0uMjc2LjIyMy0uNS41LS41aC40OTl2Mi4wMDFoLS41ek01LjY3IDEyaC0uOTEzbDEuMDcxLTIuNTAzaC45MTJMNS42NyAxMnpNMTEgOS43NjhjLS45ODUtLjgxMy0yLjIyNS0xLjI3LTMuNTAyLTEuMjczaC0xLjVWNi40OTNoMS41QzguNzc1IDYuNDkgMTAuMDE1IDYuMDU0IDExIDUuMjQxdjQuNTI3eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIvPgogICAgPC9nPgo8L3N2Zz4KCg==);
    }
  }
}

.element-node {
  position: absolute;
  cursor: grab;
  border: 2px solid transparent;
  padding: 3px;
  border-radius: 9px;
  z-index: 1;

  &.instagramAd:before {
    position: absolute;
    top: 51px;
    left: 51px;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMS41IiBmaWxsPSIjRkZGIiBzdHJva2U9IiNDQ0UzRkYiLz4KICAgIDxnIGZpbGw9IiMwMDcyRkYiPgogICAgICAgIDxwYXRoIGQ9Ik0xMS41IDNjLS4xMzIgMC0uMjYuMDUzLS4zNTMuMTQ3LS4wOTQuMDkzLS4xNDcuMjItLjE0Ny4zNTR2LjMxOGMtLjg1MyAxLjA2LTIuMTQzIDEuNjY4LTMuNTAyIDEuNjczSDQuNDk5Yy0uODI3LjAwMS0xLjQ5OC42NzMtMS40OTkgMS41MDJ2MWMwIC44My42NzIgMS41MDIgMS41IDEuNTAyaC4yNDFsLTEuMiAyLjgwNmMtLjA2Ny4xNTUtLjA1MS4zMzMuMDQxLjQ3My4wOTMuMTQuMjUuMjI1LjQxOC4yMjVoMS45OTljLjIgMCAuMzgtLjEyLjQ1OS0uMzA0bDEuMzYxLTMuMThjMS4yNDMuMDkgMi4zOTcuNzAzIDMuMTgxIDEuNjc0di4zMTljMCAuMjc2LjIyNC41LjUuNXMuNS0uMjI0LjUtLjVWMy41YzAtLjEzMy0uMDUzLS4yNi0uMTQ2LS4zNTRDMTEuNzYgMy4wNTMgMTEuNjMzIDMgMTEuNSAzem0tNyA1LjQ5NWMtLjI3NyAwLS41LS4yMjQtLjUtLjVWNi45OTRjMC0uMjc2LjIyMy0uNS41LS41aC40OTl2Mi4wMDFoLS41ek01LjY3IDEyaC0uOTEzbDEuMDcxLTIuNTAzaC45MTJMNS42NyAxMnpNMTEgOS43NjhjLS45ODUtLjgxMy0yLjIyNS0xLjI3LTMuNTAyLTEuMjczaC0xLjVWNi40OTNoMS41QzguNzc1IDYuNDkgMTAuMDE1IDYuMDU0IDExIDUuMjQxdjQuNTI3eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIvPgogICAgPC9nPgo8L3N2Zz4KCg==);
  }

  &.automation {
    .title {
      position: unset;
    }

    display: flex;
    align-items: center;
  }

  &:hover {
    border-color: $primary-300;
  }

  &.selected {
    border-color: var(--primary-400);
    background-color: rgba(255, 255, 255, 0.6);
    z-index: $z-index + 2;

    .btn-delete {
      display: block;
    }
  }

  .title {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin-bottom: 0.25rem;
    text-align: center;
    user-select: none;
    pointer-events: none;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    pointer-events: none;
  }

  .btn-delete {
    display: none;
    position: absolute;
    right: -1rem;
    top: -1rem;
    border-radius: 50%;
    line-height: 0;
    color: $red-500;
    background: $gray-neutral-100;

    &:hover {
      color: $gray-neutral-100;
      background: $red-500;
    }
  }

  &.jtk-drag {
    cursor: grabbing;
  }

  &.jtk-dragged {
    //cursor: grabbing;
  }
}

.jtk-endpoint {
  color: $primary-300;

  &::after {
    content: '';
    position: absolute;
    background: #fff;
    top: 6px;
    left: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    z-index: $z-index + 1;
  }

  &.jtk-hover {
    color: var(--primary-500);
  }

  &.selected {
    opacity: 1;
    z-index: $z-index + 3;
  }

  &.jtk-endpoint-connected {
    &::before {
      content: '';
      position: absolute;
      background: var(--primary-500);
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      z-index: $z-index + 2;
    }

    &.jtk-hover {
      &::before {
        opacity: 0;
      }
    }

    &.group-endpoint {
      &::before {
        content: none;
      }
    }
  }
}

.jtk-hover {
  z-index: $z-index;
}

.endpoint {
  opacity: 0;
  box-shadow: $shadow-raised;
  border-radius: 50%;
  z-index: $z-index + 1;

  &.jtk {
    &-drag-active {
      opacity: 1;
      color: var(--primary-500);

      &::before {
        content: '';
        position: absolute;
        background: var(--primary-500);
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        z-index: $z-index + 2;
      }
    }
  }
}

.group-endpoint {
  z-index: 3;

  svg {
    border-radius: 50%;
    box-shadow: $shadow-raised;
  }

  &::after {
    content: '';
    position: absolute;
    background: $gray-neutral-100;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: $z-index + 1;
  }
}

.node-hover {
  opacity: 1;
}

.jtk-endpoint-connected {
  //opacity: 1;
}

.endpoint-hover {
  color: var(--primary-400);
  opacity: 1;
}

.jtk-connector {
  z-index: $z-index;

  &.selected {
    z-index: $z-index + 2;

    path:not(.jtk-connector-outline) {
      stroke: var(--primary-500);
    }
  }

  &.jtk-hover {
    z-index: $z-index;

    path:not(.jtk-connector-outline) {
      stroke: var(--primary-400);
    }
  }
}

//.jtk-connector-outline {
//  stroke-width: 8px;
//  stroke-opacity: 0;
//}

.btn-delete-connection {
  z-index: $z-index + 2;
  display: none;

  span {
    pointer-events: none;
  }

  &.selected {
    opacity: 1;
  }
}

.react-transform-component {
  width: unset !important;
  height: unset !important;
}

.react-transform-element {
  width: unset !important;
  height: unset !important;
}
