@import 'src/styles/variables';

.container {
  display: block;
}

.id, .date, .last {
  flex: 0 0 184px;
  max-width: 184px;
}

.amount {
  flex: 0 0 144px;
  max-width: 144px;
}

.status {
  flex: 0 0 108px;
  max-width: 108px;
}

.name {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  min-width: 200px !important;
  gap: 0.25rem;
}

.title {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
  min-width: 174px !important;
  gap: 0.25rem;
}

.email {
  font-weight: 600;
  color: $primary-600;
}

.offerTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: $primary-600;
}

/* RUNS */
.contact {
  flex: 0 0 330px;
  max-width: 330px;
  gap: 0.25rem;
}

.workflow {
  flex: 1 0 16.6666666667%;
  min-width: 200px !important;
  gap: 0.25rem;
}

.last {
  flex: 0 0 274px;
  max-width: 274px;
  padding: 0.5rem !important;
}
