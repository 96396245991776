@import 'src/styles/variables';

.container {
  width: 232px;
}

.wrapper {
  padding: 1rem;
  position: relative;
  width: 232px;
  height: 180px;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  // overflow: hidden;
}

.colorPicker {
  height: 20px;
  position: relative;
  border-radius: 1.5rem;
  :global .hue-horizontal {
    border-radius: 1.5rem;
    position: unset;
    height: 12px;
  }
  > :first-child {
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    top: 3px !important;
    border-radius: 0.5rem;
  }
}

.colorPickerAlpha {
  composes: colorPicker;
  div {
    &:last-child {
      margin-left: 0 !important;
      // transform: translateX(-8px);
      // max-width: 218px !important;
    }
  }
}

.saturationCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  transform: translate(-10px, -10px);
}

.colorCircleWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -3px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  .colorCircle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid $gray-neutral-100;
    box-sizing: border-box;
  }
}

.rgbInput {
  max-width: 72px;
}
