@import 'src/styles/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  &:hover {
    .remove,
    .unlink,
    .edit {
      display: block !important;
    }
  }
}

.container {
  border-bottom: 1px solid $gray-cold-200;
  padding-bottom: 1.5rem;
  .name {
    font-size: 24px;
    line-height: 36px;
    cursor: default;
    &.lost {
      color: $gray-cold-500;
    }
    &:hover {
      color: $primary-500;
    }
  }
  .default {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-700;
  }
}

.edit {
  position: absolute;
  right: 0;
  top: 1rem;
  color: $primary-600;
  display: none !important;
  &:hover {
    background: $primary-100 !important;
    color: $primary-400 !important;
  }
  &:active {
    background: $primary-200 !important;
    color: $primary-500 !important;
  }
  svg {
    margin: 0 !important;
  }
}

.unlink {
  position: absolute;
  right: 0;
  top: 1rem;
  color: $primary-600;
  display: none !important;
  &:hover {
    background: $primary-100 !important;
    color: $primary-400 !important;
  }
  &:active {
    background: $primary-200 !important;
    color: $primary-500 !important;
  }
  svg {
    margin: 0 !important;
  }
}

.remove {
  position: absolute;
  right: 0;
  top: 1rem;
  color: $red-600;
  display: none !important;
  &:hover {
    background: $red-100 !important;
    color: $red-400 !important;
  }
  &:active {
    background: $red-200 !important;
    color: $red-500 !important;
  }
  svg {
    margin: 0 !important;
  }
}
