@import 'src/styles/variables';

.container {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.headerContainer {
  position: relative;
  display: flex;
  align-items: center;
  .iconQuestion {
    color: $gray-cold-500;
    margin-left: 0.5rem;
    margin-top: -1.5rem;
    &:hover {
      color: $primary-400;
    }
  }
}
