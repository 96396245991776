@import 'src/styles/variables';

.link {
  background: var(--primary-100) !important;
  border: 1px solid $primary-300 !important;
  color: $gray-cold-900 !important;
}

.btn {
  max-width: 146px;
  width: 100%;
}
