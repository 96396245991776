@import 'src/styles/variables';

.title {
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.description {
  color: $gray-cold-700;
  margin-bottom: 1.5rem;
  line-height: 22px;
}

.textarea {
  height: 92px !important;
}

.note {
  font-size: 12px;
  color: $gray-cold-500;
}

.actions {
  box-shadow: $shadow-popping;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: $gray-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin-top: auto;
  height: 48px;
  flex-shrink: 0;
  max-width: 768px;
  width: 100%;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}

.icon {
  color: var(--primary-500);
  width: 64px;
  height: 64px;
  margin-bottom: 0.5rem;
}

.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.note {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;
  text-align: center;
}
