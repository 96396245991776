@import 'src/styles/variables';

.container {
  composes: import borderWrapper from '../setting.module.scss';
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.editIcon {
  color: $gray-cold-300;
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
}

.avatar {
  border-radius: 50%;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.title {
  color: $gray-cold-900;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem; 
  line-clamp: 3;
}

.location {
  font-size: 14px;
  color: $gray-cold-600;
  margin-top: 0.25rem;
}

.iconContainer {
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  display: flex;
  position: relative;
}

.user {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-500);
  height: 2.25rem;
  width: 2.25rem;
  border: 2px solid rgba($color: $gray-neutral-100, $alpha: 1);
  overflow: hidden;
  background-color: var(--primary-200);
  margin-left: -0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.addUser {
  composes: user;
  background-color: var(--primary-500);
  color: $gray-neutral-100;
  border: 2px solid $gray-neutral-100;
  cursor: pointer;
}

.addUserText {
  font-weight: 600;
  font-size: 14px;
}

.containerImage {
  img {
    position: unset;
  }
}

.containerText {
  font-size: 32px;
}
