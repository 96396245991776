@import 'src/styles/variables';

.wrapper {
  padding-bottom: 3rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-700;
  text-align: center;
  padding-top: 1.5rem;
}

.text {
  font-size: 14px;
  color: $gray-cold-700;
}

.textContainer {
  bottom: -24px !important;
  padding-bottom: 1.5rem !important;
  background-color: $gray-neutral-100;
  li {
    width: auto !important;
  }
  :global .slick-active {
    :local .text {
      color: var(--primary-500);
      font-weight: 600;
      border-bottom: 2px solid var(--primary-500);
    }
  }
}

.image {
  margin: auto;
  height: 340px;
}
.laptop {
  width: 608px;
  composes: image;
}

.ipad {
  width: 226px;
  composes: image;
}

.iphone {
  composes: image;
  width: 135px;
  height: 280px;
}

.widget {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}

.laptopWidget {
  composes: widget;
  transform: scale(0.5) translateY(-50%);
}

.ipadWidget {
  composes: widget;
  transform: scale(0.3) translateY(-116%);
}

.iphoneWidget {
  composes: widget;
  transform: scale(0.2) translateY(-200%);
}

.imgWrap {
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: scroll;
  height: 100%;
  max-height: 246px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.imgWrapperLaptop {
  composes: imgWrap;
  top: 39px;
}

.imgWrapperIPad {
  composes: imgWrap;
  top: 53px;
}

.imgWrapperIPhone {
  composes: imgWrap;
  top: 34px;
  max-height: 230px;
}

.imgContainer {
  position: relative;
}

.imgContainerLaptop {
  composes: imgContainer;
  margin-top: 2.375rem;
}

.imgContainerIPad {
  composes: imgContainer;
  margin-top: 2.375rem;
}

.imgContainerIPhone {
  composes: imgContainer;
  margin-top: 4.25rem;
}
