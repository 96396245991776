@import 'src/styles/variables';

.container {
  display: block;
}

.logout {
  cursor: pointer;
  margin-bottom: 1.5rem;
  z-index: 1;
}

.avatar {
  border-radius: 50%;
}

.organization {
  border: 2px solid $gray-neutral-100;
  border-radius: 50%;
  z-index: 11;
  margin-top: -12px;
  margin-left: -2px;
}

.name {
  font-weight: 600;
  font-size: 16px;
}

.placeholder {
  border-radius: 50%;
  background: var(--primary-200);
  color: var(--primary-500);
  padding: 0.5rem;
  width: 3rem;
}
