@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 14px;
  border-left: 1px solid $gray-cold-200;
  padding-left: 0.5rem;
  color: $gray-cold-700;
  &.active {
    color: $gray-cold-900;
  }
}

.container {
  padding: 0.875rem 1.5rem 0.875rem 1.5rem;
  margin-right: 6px !important;
}

.text {
  color: $gray-cold-500;
  font-size: 14px;
  margin-top: 0.5rem;
}

.wrapper {
  height: 52px;
  display: flex;
  margin-top: 1rem;
}
