@import 'src/styles/variables';

.wrapper {
  overflow-x: auto;
  position: relative;
  ::-webkit-scrollbar {
    height: 0;
  }
  background: var(--primary-200);
  display: flex;
  height: 26px;
}

.container {
  display: flex;
  background: transparent;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  overflow-x: auto;
  padding-left: 1rem;

  &:not(:hover) {
    .containerItem:last-child {
      .text {
        max-width: 80px;
        transition: max-width 0.3s;
      }
    }
  }
}

.containerDisappear {
  background: linear-gradient(270deg, var(--primary-200) 50%, rgba(224, 238, 255, 0) 100%);
  width: 41px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.containerItem {
  cursor: pointer;
  display: flex;
  &:hover {
    .text {
      max-width: 80px;
      transition: max-width 0.3s;
    }
  }
}

.text {
  font-size: 12px;
  color: var(--primary-600);
  align-self: center;
  margin-left: 0.25rem;
  max-width: 0;
  overflow: hidden;
  text-indent: 0.5em;
  transition: max-width 0.3s;
}
