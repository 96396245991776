.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  margin: 0.5rem 0;
  font-size: 14px;
}

.label {
  margin-bottom: 1rem;
  letter-spacing: -0.33px;
}
