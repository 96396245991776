@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-700;
  text-align: center;
  margin: auto;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.icon {
  color: $gray-cold-500;
}

.container {
  background: var(--primary-100);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid var(--primary-200);
  display: flex;
  align-items: center;
}
