// Colors
$red-100: #fee7ef;
$red-200: #ffb3cd;
$red-300: #fc83ad;
$red-400: #fb518c;
$red-500: #f20d5d;
$red-600: #da0c54;
$red-700: #ae0440;
$red-800: #990035;
$red-900: #660024;

$mint-100: #e8fdf5;
$mint-200: #b8ffea;
$mint-300: #8ef6d8;
$mint-400: #5ff2ba;
$mint-500: #19e699;
$mint-600: #22c386;
$mint-700: #09aa6d;
$mint-800: #037c4e;
$mint-900: #004d3c;

$orange-100: #fff5f2;
$orange-200: #ffcdbd;
$orange-300: #fda481;
$orange-400: #fa8d3e;
$orange-500: #f96a06;
$orange-600: #c75505;
$orange-700: #b04203;
$orange-800: #953204;
$orange-900: #662a00;

$purple-100: #fcf2ff;
$purple-200: #eeb3fe;
$purple-300: #e281fd;
$purple-400: #d54cfa;
$purple-500: #c309f6;
$purple-600: #9708bf;
$purple-700: #7e0a9e;
$purple-800: #62037c;
$purple-900: #3c004d;

$green-100: #f3fff2;
$green-200: #e7ffe6;
$green-300: #97f291;
$green-400: #65d941;
$green-500: #34d900;
$green-600: #25cf1b;
$green-700: #0ab300;
$green-800: #099900;
$green-900: #044d00;

$yellow-100: #fef9e6;
$yellow-200: #fffacc;
$yellow-300: #fff0a3;
$yellow-400: #ffe44d;
$yellow-500: #f9c206;
$yellow-600: #e6b100;
$yellow-700: #c78605;
$yellow-800: #956004;
$yellow-900: #4d3000;

$gray-cold-100: #f5f8fa;
$gray-cold-200: #edf0f2;
$gray-cold-300: #cddae4;
$gray-cold-400: #bfcdd9;
$gray-cold-500: #8a9aa8;
$gray-cold-600: #627484;
$gray-cold-700: #475b6b;
$gray-cold-800: #3d4851;
$gray-cold-900: #001c33;

$gray-neutral-100: #ffffff;
$gray-neutral-200: #f8f7f8;
$gray-neutral-300: #e6e6e6;
$gray-neutral-400: #cccccc;
$gray-neutral-500: #9c9c9c;
$gray-neutral-600: #737373;
$gray-neutral-700: #595959;
$gray-neutral-800: #404040;
$gray-neutral-900: #0d0d0d;

$primary-100: #f5f9ff;
$primary-200: #e0eeff;
$primary-300: #99c7ff;
$primary-400: #338eff;
$primary-500: #0072ff;
$primary-600: #005bcc;
$primary-700: #004499;
$primary-800: #002e66;
$primary-900: #001733;
$black: #000;

// Bootstrap default colors
$blue: var(--primary-500) !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: $red-600 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
