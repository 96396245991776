@import "src/styles/variables";

.container {
  background: var(--primary-100);
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 3.75rem;
}

.title {
  display: inline-block;
  font-weight: 600;
  font-size: 64px;
  line-height: 1.3;
  background: -webkit-linear-gradient(180deg, #008BFF, #0059FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.subtitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: $gray-cold-400;
  margin-bottom: 1rem;
}

.text {
  max-width: 25rem;
  margin-bottom: 3rem;
}

.buttons {
  display: flex;

  :global .btn {
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
  }
}

.copy {
  font-size: 14px;
  color: $gray-cold-500;
}
