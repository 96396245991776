@import 'src/styles/variables';
.wrapper {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.container {
  position: relative;
}

.icon {
  position: absolute;
  left: 7px;
  top: 0.25rem;
  color: $gray-cold-500;
}
