@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  border-radius: 0.5rem;
  height: 128px;
  width: 180px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  border: 2px solid transparent;
  flex-shrink: 0;

  &:hover {
    border-color: $primary-300;
    color: var(--primary-500);
  }
}

.addIcon {
  background: $gradient;
  border-radius: 50%;
  width: 24px;
  line-height: 0;
  color: $gray-neutral-100;
}
