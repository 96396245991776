@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.text {
  font-weight: 600;
  line-height: 22px;
}

.img {
  width: 120px;
  height: 120px;
  margin-bottom: 0.5rem;
}
