@import 'src/styles/variables';

.modal {
  :global .modal-dialog {
    @media (min-width: 576px) {
      max-width: 668px;
    }
  }
}

.container {
  padding: 3.5rem;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: var(--primary-500);
  margin-bottom: 0.5rem;
}

.description {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1.5rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button {
  width: 180px;
  height: 150px;
  background: $gray-cold-100;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem 1rem 1rem;
  position: relative;
  cursor: pointer;

  &:hover {
    .name {
      color: var(--primary-500);
    }

    .help {
      opacity: 1;
    }
  }
}

.name {
  font-size: 24px;
  line-height: 36px;
  transition: color 0.2s;
}

.text {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
  margin-bottom: 0.25rem;
}

.help {
  opacity: 0;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-400;
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
  transition: color 0.2s, opacity 50ms;

  &:hover {
    color: var(--primary-500);
  }
}

.loading {
  pointer-events: none;
}
