@import 'src/styles/variables';

.delete-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: -0.3px;
  color: $gray-cold-900;
  cursor: default;

  &.delete-text-element {
    color: var(--primary-500);
  }
}

.modal-content {
  border-radius: 0.25rem;
}
