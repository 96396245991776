@import '../variables';

.dropdown {
  .dropdown-kebab-toggle {
    color: $gray-cold-500;
    line-height: 0;

    &:hover {
      color: var(--primary-500);
    }
  }
}

.dropdown-menu {
  box-shadow: $shadow-popping;

  &.selector {
    padding: 0.5rem 1rem;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  border-top: 1px solid $gray-cold-200;
  color: $gray-cold-700;

  &:first-child {
    border-top: none;
  }

  svg {
    margin-right: 0.5rem;
    color: var(--primary-500);
  }

  &:hover {
    color: var(--primary-500);
  }

  &.iconRed {
    svg {
      color: $red-500;
    }
  }

  .sm & {
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    line-height: 20px;
  }

  .selector & {
    padding: 0;
    margin-bottom: 0.25rem;
    color: $gray-cold-900;
    border: none;
    line-height: 18px;
    font-size: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: transparent;
      color: var(--primary-500);
    }

    &.disabled {
      color: $gray-cold-500 !important;

      svg {
        color: $gray-cold-500;
      }
    }

    svg {
      margin-right: 0.25rem;
    }
  }

  &.with-toggle {
    align-items: flex-start;
  }
}

.dropdown-toggle::before {
  display: none !important;
}
