@import "src/styles/colors";

.comingSoon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;

  &.automation {
    background: url("../../../../assets/images/automation_preview.png") no-repeat center center;
    box-shadow: inset 9px 12px 10px 2px $gray-neutral-200;
  }

  &.funnel {
    background: url("../../../../assets/images/funnel_preview.png") no-repeat;
    margin: 0;
  }
}
