@import 'src/styles/variables';

.container {
  position: relative;
}

.icon {
  color: var(--primary-600);
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.wrapper {
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.notice {
  color: $gray-cold-500;
  font-size: 12px;
  line-height: 18px;
}
