.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.iframe {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin: 0;
  padding: 0;
  border: none;
}

.h0 {
  height: 0;
  min-height: 0;
}

video {
  user-select: none;
}
