@import 'src/styles/variables';

.container {
  padding: 3rem 3.5rem 3.5rem 3.5rem;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.text + .text {
  margin-top: 0.75rem;
}
