@import 'src/styles/variables';

.wrapper {
  background-color: $gray-neutral-100;
  padding: 0.375rem;
}

.container {
  position: relative;
  background-color: $gray-cold-100;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.375rem 0.375rem 0.625rem 0.375rem;
}

.inputContainer {
  display: flex;
  height: 100%;
  padding-right: 2rem;
}

.input {
  background-color: transparent;
  text-align: right;
  border: none;
}

.label {
  color: $gray-cold-500;
  white-space: nowrap;

  &.range {
    padding-left: 0.5rem;
  }
}

.notInRange {
  position: relative;
  border: 1px solid $gray-cold-100;
  border-radius: 0.25rem;
  margin-left: 0.275rem;
  margin-right: 0.275rem;
  padding-left: 0.5rem;
  color: $gray-cold-500;
}

.endpointView {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    content: '';
    border-radius: 50%;
    background: $gray-neutral-100;
    width: 8px;
    height: 8px;
  }
}

.endpointCSS {
  width: 12px;
  height: 12px;
  background: #99c7ff;
  border: 1px solid $gray-neutral-100;
  box-sizing: content-box;
  box-shadow: $shadow-raised;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ellipse {
    border-radius: 50%;
    background: #ffffff;
    width: 12px;
    height: 12px;
  }

  &.notIn {
    right: 11px;
  }
}
