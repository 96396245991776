@import 'src/styles/variables';

.textarea {
  resize: none;
}

.length {
  font-size: 12px;
  color: $gray-cold-500;
  margin-top: 0.25rem;
  text-align: end;
}

.action {
  max-width: 356px;
}

.container {
  background-color: var(--primary-100);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.inputError {
  border: 1px solid $red-500 !important;
}
