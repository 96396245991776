@import '../../../../../../../../styles/variables';

.container {
  margin-left: auto;
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.25rem;
  }

  &.progress {
    color: $yellow-700;

    svg {
      color: $yellow-600;
    }
  }

  &.passed {
    color: $green-700;
  }

  &.fail {
    color: $red-700;
  }

  &.skipable {
    color: $gray-cold-700;
  }
}

.icon {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  line-height: 0;
  margin-left: 0.25rem;

  .passed & {
    background-color: $green-500;;
  }

  .fail & {
    background-color: $red-500;;
  }

  .skipable & {
    background-color: $gray-cold-500;
  }

  svg {
    margin: 0;
    color: $gray-neutral-100;
  }
}
