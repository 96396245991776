@import 'src/styles/variables';

.container {
  max-width: 768px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.intro {
  margin-bottom: 1rem;
  color: $gray-cold-600;
  font-size: 14px;
  line-height: 20px;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border: 2px solid $gray-neutral-100;
  border-radius: 0.5rem;
  background: $primary-200;
  box-shadow: $shadow-raised;
}

.colors {
  display: flex;
  align-items: center;
  padding: 0.875rem;
  gap: 0.5rem;
}

.restore {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: color 0.2s;
  color: $primary-500;
  font-size: 12px;
  line-height: 15px;
  gap: 0.25rem;

  &:hover {
    color: $primary-400;
  }

  &:active {
    color: $primary-600;
  }
}

.image {
  height: 428px;
  margin-bottom: 1.5rem;
  border: 1px dashed $primary-300;
  border-radius: 4px;
}

.disclaimer {
  color: $gray-cold-700;
  font-size: 12px;
  line-height: 18px;
}

.icon {
  width: 64px;
  height: 64px;
  margin-bottom: 0.5rem;
  color: var(--primary-500);
}

.text {
  margin: 0 0 0.25rem;
  color: $gray-cold-900;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  &.textPrimary {
    color: var(--primary-500);
  }
}

.textSecond {
  margin: 0 0 1rem;
  text-align: center;
  letter-spacing: -0.003em;
  color: $gray-cold-900;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.note {
  margin: 0;
  text-align: center;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
