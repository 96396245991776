@import 'src/styles/variables';

.wrapper {
  max-width: 560px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;
  padding-top: 2rem;
  user-select: none;
}

.container {
  background: rgba(0, 23, 51, 0.5);
  border-radius: 4px;
  padding-bottom: 2rem;
}

.previewText {
  font-size: 16px;
  color: $gray-neutral-100;
  cursor: pointer;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  img {
    margin-left: 0.5rem;
  }
}

.noneEvents {
  box-shadow: $shadow-popping;
  border-radius: 0.5rem;
  min-width: 320px;
  margin: auto;
  max-width: 560px;
  pointer-events: none;
}
