@import 'src/styles/variables';

.wrapper {
  max-width: 452px;
}

.container {
  padding: 3.125rem 3.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.card {
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $gray-cold-700;
}

.text {
  text-align: center;
  line-height: 22px;
  margin-bottom: 1rem;
}
