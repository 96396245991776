@import 'src/styles/variables';

.description {
  color: $gray-cold-700;
  font-size: 14px;
}

.container {
  display: flex;
  align-items: center;
}

.status {
  border-radius: 2px;
  font-size: 12px;
  padding: 0.188rem 0.5rem;
  margin-top: 0.25rem;
}

.incomplete {
  background: $gray-cold-100;
  border: 1px solid $gray-cold-300;
  color: $gray-cold-700;
  composes: status;
}

.pending {
  background: $yellow-100;
  border: 1px solid $yellow-300;
  color: $yellow-700;
  composes: status;
}

.rejected {
  background: $red-100;
  border: 1px solid $red-300;
  color: $red-700;
  composes: status;
}

.done {
  background: $green-100;
  border: 1px solid $green-300;
  color: $green-700;
  composes: status;
}

.text {
  font-size: 14px;
  margin-left: 0.5rem;
}
