@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $gray-cold-700;
  height: 100%;
}

.icon {
  color: $gray-cold-500;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  margin-bottom: 0.125rem;
}

.text {
  font-size: 16px;
  line-height: 1.38;
}

:global .react-bs-table-no-data {
  height: calc(
    100vh - #{$header-height} - #{$content-padding-top} - #{$content-padding-bottom} - #{$page-top-panel-height} - 3rem
  );
}
