@import 'src/styles/variables';

.manageText {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.3px;
  color: $gray-cold-900;
}

.magnificContainer {
  width: 100%;
  margin: 0;
}

.magnific {
  position: absolute;
  display: flex;
  align-items: center;
  left: 8px;
  top: 0;
  bottom: 0;
  z-index: 10;

  &.magnificZIndex0 {
    z-index: 0;
  }
}

.magnificIcon {
  width: 16px;
  height: 16px;
  color: $gray-cold-500;
}

.infoIcon {
  color: $gray-cold-500;
}

.input {
  width: 100%;
  padding: 0.25rem;
  text-indent: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  border-radius: 4px;
  height: 28px;

  &.resetZIndexInputGroup {
    &:focus {
      z-index: 0;
    }
  }
}

.textInfo {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $gray-cold-500;
}

.textLinkInfo {
  color: var(--primary-500);
  cursor: pointer;
}

.linkContainer {
  margin-top: 0.75rem;

  .link {
    color: var(--primary-600);
    font-size: 12px;
    line-height: 18px;
  }
}

.createContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--primary-500);
  color: var(--primary-600);
  font-size: 12px;
  padding-left: 0.25rem;
  height: 20px;
  cursor: pointer;

  .createImage {
    color: var(--primary-500);
  }
}
