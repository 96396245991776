@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.description {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}

.finishContainer {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  .finishIcon {
    background: $gray-neutral-100;
    color: $green-500;
    border-radius: 50%;
  }
}

.failText {
  font-size: 12px;
  line-height: 18px;
  color: $red-600;
}

.progress {
  display: flex;
  width: 100%;
  height: 0.25rem;
  background: $primary-300;
  border-radius: 2px;
  overflow: hidden;
  :global .progress-bar {
    background-color: var(--primary-500);
  }
  &.success {
    :global .progress-bar {
      background-color: $green-500;
    }
  }
  &.notLoad {
    :global .progress-bar {
      background-color: $gray-cold-300;
    }
    &.progress {
      background: $gray-cold-300;
    }
  }
}
