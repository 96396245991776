@import 'src/styles/variables';

.container {
  margin-bottom: 2rem;

  .create {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2rem 0;
    background: $gray-neutral-100;
    border-radius: 0.5rem;
    box-shadow: $shadow-raised;

    .icon {
      color: var(--primary-500);
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.33px;
    }

    .textMuted {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -0.25px;
      color: $gray-cold-500;
      text-align: center;
      max-width: 160px;
      margin: 0;
    }
  }
}
