@import 'src/styles/variables';

.container {
  border-radius: 0.75rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid $gray-neutral-100;

  :global {
    .icon {
      margin-right: 2px;
    }
  }
}

.additional {
  justify-content: center;
  flex-wrap: wrap;

  .container {
    padding: 0.188rem 0.5rem;
  }
}

.additional {
  .container + .container {
    margin-left: 0.25rem;
    margin-top: 0.25rem;
  }
}

.empty {
  .container + .container {
    margin-left: 0.25rem;
  }
}

.folderContainer {
  composes: container;
  background-color: $green-100;
  color: $green-500;
  // margin-bottom: 0.25rem;
  &:active,
  &:hover {
    background-color: $green-500;
    color: $gray-neutral-100;
  }
}

.playlistContainer {
  composes: container;
  background-color: var(--primary-100);
  color: var(--primary-500);

  &:active,
  &:hover {
    background-color: var(--primary-500);
    color: $gray-neutral-100;
  }
}

.lessonContainer {
  composes: container;
  background-color: $orange-100;
  color: $orange-500;
  margin-top: 0 !important;

  &:active,
  &:hover {
    background-color: $orange-500;
    color: $gray-neutral-100;
  }
}

.videoContainer {
  composes: container;
  background-color: var(--primary-100);
  color: var(--primary-500);

  &:active,
  &:hover {
    background-color: var(--primary-500);
    color: $gray-neutral-100;
  }
}

.attachmentContainer {
  @extend .videoContainer;
}

.quizContainer {
  composes: container;
  background-color: $orange-100;
  color: $orange-500;

  &:active,
  &:hover {
    background-color: $orange-500;
    color: $gray-neutral-100;
  }
}

.surveyContainer {
  composes: container;
  background-color: $purple-100;
  color: $purple-500;

  &:active,
  &:hover {
    background-color: $purple-500;
    color: $gray-neutral-100;
  }
}

.presentationContainer {
  composes: container;
  background-color: $yellow-100;
  color: $yellow-600;

  &:active,
  &:hover {
    background-color: $yellow-600;
    color: $gray-neutral-100;
  }
}

.textContainer {
  composes: container;
  background-color: $gray-cold-100;
  color: $gray-cold-900;

  &:active,
  &:hover {
    background-color: $gray-cold-900;
    color: $gray-neutral-100;
  }
}

.textContainerCreateSection {
  composes: textContainer;
  color: $gray-cold-700;
}

.embedContainer {
  composes: container;
  background-color: $mint-100;
  color: $mint-700;

  &:active,
  &:hover {
    background-color: $mint-700;
    color: $gray-neutral-100;
  }
}

.embedContainerCreateSection {
  composes: embedContainer;
  color: $mint-700;
}

.audioContainerCreateSection {
  @extend .videoContainer;
}
