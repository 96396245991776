@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.name {
  color: $gray-cold-700;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.25rem;
}

.paymentDate {
  font-size: 12px;
  line-height: 18px;
  min-height: 18px;
}

.amount {
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-500;
}

.card {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
}

.number {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
}

.status {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 2px;
}

.download {
  a {
    color: $gray-cold-500;
    text-decoration: none;
  }
}

.draft {
  background-color: $yellow-100;
  color: $yellow-700;
  border-color: $yellow-400;
}

.open {
  background-color: $yellow-100;
  color: $yellow-700;
  border-color: $yellow-400;
}

.paid {
  background-color: $green-100;
  color: $green-700;
  border-color: $green-300;
}

.void {
  background-color: $green-100;
}

.uncollectible {
  background-color: $green-100;
}
