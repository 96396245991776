@import 'src/styles/variables';

.container {
  max-width: 816px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: $gray-neutral-200;

  &.active {
    background: $primary-100;
  }

  &.inactive {
    cursor: pointer;

    &:hover {
      background: $primary-100;

      .title {
        color: $primary-500;
      }
    }

    &:focus,
    &:active {
      background: $primary-200;

      .title {
        color: $primary-600;
      }
    }
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  .active & {
    color: $primary-500;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.25rem;
}

.content {
  border-top: 1px solid $gray-cold-300;
  margin-top: 0.5rem;
  padding-top: 2rem;

  :global {
    .ql-container {
      font-size: 16px;
    }

    .ql-editor {
      padding: 0;

      &.ql-blank::before {
        left: 0;
      }
    }
  }
}
