@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  padding: 0 1.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 14px;
  border-left: 1px solid $gray-cold-200;
  padding-left: 0.5rem;
}
