@import "src/styles/variables";

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  padding: 1rem 0.5rem 2rem;
}

.navigation {
  list-style: none;
  padding: 0;
  margin: 0 -1rem;
}

.navItem {
  border-bottom: 1px solid $gray-cold-200;
}

.navLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  color: $gray-cold-700;

  &:hover, &.active {
    background-color: var(--primary-100);
    color: $gray-cold-900;
    text-decoration: none;

    .icon {
      color: var(--primary-500);
    }
  }
}

.icon {
  margin-right: 0.5rem;
  color: $gray-cold-500;
}
