@import 'src/styles/variables';

.mainContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bulkFooter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  @extend .mainContainer;
  z-index: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dropped {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  width: 26rem;
  z-index: 100;
}

.blurContainer {
  backdrop-filter: blur(5px);
  background: rgba(0, 23, 51, 0.5);
  height: 100%;
  width: 26rem;
  position: relative;
  z-index: 100;

  &:after {
    content: '';
    position: absolute;
    border: 1px dashed $gray-neutral-100;
    top: 1rem;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    pointer-events: none;
  }
}

.isOverPanel {
  position: absolute !important;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  width: auto !important;
  z-index: 99;
}

.wrapper {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .cancelWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .cancelContainer {
      width: 64px;
      height: 64px;
      background: $gray-neutral-100;
      opacity: 0.25;
      border-radius: 50%;
      z-index: 100;
      transition: height 0.1s, width 0.1s;

      &.isOverCancel {
        width: 80px;
        height: 80px;
      }
    }

    .cancel {
      position: absolute;
      padding: 0.5rem;
      z-index: 110;
      border: 1px solid $gray-neutral-100;
      border-radius: 50%;
      color: $gray-neutral-100;
    }
  }
}

.cancelText {
  font-size: 14px;
  color: $gray-neutral-100;
  z-index: 100;
}

.dropContainer {
  text-align: center;
  height: inherit;

  .dropText {
    font-weight: 600;
    font-size: 32px;
    color: $gray-neutral-100;
  }

  .dropTextDescribe {
    font-weight: 600;
    font-size: 16px;
    color: $gray-neutral-100;
  }
}

.panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 26rem;
  box-shadow: $shadow-floating;
}
