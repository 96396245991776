@import 'src/styles/variables';

.container {
  background-color: var(--primary-100);
  display: flex;
  border-radius: 0.5rem;

  .btn {
    font-size: 14px;
    padding: 0.375rem 3rem;
    border: none;
    justify-content: space-between;
    background-color: var(--primary-100);
    color: var(--primary-500);
    margin: 0.5rem 0 0.5rem 0.5rem;
    border-radius: 0.25rem;

    &.select {
      background-color: var(--primary-500);
      color: $gray-neutral-100;
    }

    &:hover:not(.select) {
      background-color: var(--primary-200);
    }
  }
}
