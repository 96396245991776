@import 'src/styles/variables';

.container {
  max-width: 768px;
  margin: 0 auto;
}

.header {
  padding: 2rem 0;
  border-bottom: 1px solid $gray-neutral-300;
  margin-bottom: 80px;
}

.organization {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0.25rem;
}

.name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.content {
  padding-bottom: 80px;
}
