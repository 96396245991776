@import 'src/styles/variables';

.input {
  border: none;
  display: flex;
  padding: 0;
  height: unset;
  margin-left: 0.5rem;
  text-align: right;
}

.icon {
  color: $gray-cold-500;
  width: 3rem;
}

.iconTrash {
  composes: icon;
  color: $red-500;
  cursor: pointer;
}

.from {
  border-right: 2px solid $gray-cold-100;
}

.error {
  border-color: $red-500;
}

.errorIndex {
  position: absolute;
  left: -5px;
  top: -8px;
  background-color: $red-500;
  color: $gray-neutral-100;
  border-radius: 20px;
  width: 12px;
  height: 12px;
  font-size: 11px;
  margin-right: 0.25rem;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
