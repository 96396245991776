@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.organization {
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-700);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 1.5rem;

  &.resume {
    margin-bottom: 0.5rem;
  }
}

.resumeAccessText {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.25rem;
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
  text-align: center;
}

.copy {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;

  a {
    color: $gray-cold-500;
  }

  @include lt-md {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.25rem;
  }
}

.form {
  margin: auto 0;
}
