@import 'src/styles/variables';

.titleContainer {
  border-radius: 0.25rem 0.25rem 0 0;
  border: 1px solid $primary-300;
  border-bottom: none;
  background: var(--primary-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  .title {
    font-size: 16px;
  }
}

.contentContainer {
  border-radius: 0 0 0.25rem 0.25rem;
  background: var(--primary-100);
  position: relative;
}

.copyBtn {
  position: absolute;
  bottom: 0.875rem;
  left: 1rem;
  max-width: 126px;
  width: 100%;
}

.textScript {
  border-radius: 0 0 0.25rem 0.25rem;
  border-color: $primary-300 !important;

  &.hideHeader {
    border-radius: 0.25rem;
  }

  &:hover {
    border-color: $primary-300;
  }
}

.callToAction {
  pointer-events: none;
}
