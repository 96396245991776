@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wrapper {
  background: $gray-cold-100;
  border-radius: 6px;
}

.cross {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: $gray-cold-500;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 32px;
  color: $yellow-600;
  margin: 2rem 0;
}

.icon {
  margin-top: -100px;
}

.description {
  margin: 1.5rem 0;
}

.btn {
  margin-bottom: 3.375rem;
}
