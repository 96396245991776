@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-900;
  margin-bottom: 0.5rem;
}

.helpInputText {
  composes: import inputContainer from '../common/input/setting-input.module.scss';
  color: $gray-cold-500;
  font-size: 12px;
  margin-top: 0.25rem;
}

.timeContainer {
  max-width: 144px;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}
