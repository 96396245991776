@import 'src/styles/variables';

.container {
  display: block;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem 0 1rem;
}

.title {
  font-size: 24px;
  line-height: 36px;
}

.count {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: $gray-neutral-100;
  border-radius: 12px;
  padding: 2px;
  text-align: center;
  min-width: 24px;
  color: $primary-500;
}
