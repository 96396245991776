@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  margin: 0;
  max-width: 472px;
  height: var(--vh);
  padding: 1.5rem 3.75rem 3rem;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.765) 0%, rgba(255, 255, 255, 0.68) 100%);
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  border-radius: 0 24px 24px 0;

  @include lt-md {
    max-width: unset;
    padding: 1.25rem;
    border-radius: unset;
  }

  @include lt-sm {
    padding: 1.5rem 0.625rem 1rem;
  }
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 0.5rem;

  @include lt-md {
    text-align: center;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.25rem;

  @include lt-md {
    text-align: center;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  position: unset;

  @include lt-md {
    max-width: 352px;
    margin: 0 auto;
  }
}

.copy {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-500;

  @include lt-md {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.25rem;
  }
}

.closeIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
