@import 'src/styles/variables';

.container {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;

  &:disabled {
    color: inherit;
  }

  :global .dropdown-toggle {
    text-align: initial;
    white-space: initial;

    &:after {
      display: none;
    }
  }
}

.icon {
  flex-shrink: 0;
  color: $gray-cold-500;
  align-self: center;
  margin-left: 1rem;
}

:global .dropdown.show {
  :local .icon {
    transform: rotate(180deg);
  }
}
