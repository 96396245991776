@import 'src/styles/variables';

.navItem {
  border-bottom: 1px solid $gray-cold-200;

  &.navItemLogo {
    height: 80px;
  }
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $gray-cold-500;
  text-decoration: none;
  padding: 1rem 0 1rem;
  transition: color 0.2s;
  cursor: pointer;

  &.active,
  &:hover {
    text-decoration: none;
    color: $gray-cold-900;

    .navIcon {
      color: var(--primary-500);
    }
  }

  &.unActive {
    color: $gray-cold-400;
    cursor: pointer;

    svg {
      color: $gray-cold-300;
    }
  }
}

.inactiveLink {
  composes: navLink;

  &.active,
  &:hover {
    text-decoration: none;
    color: $gray-cold-500;

    .navIcon {
      color: $gray-cold-500;
    }
  }
}

.navIcon {
  transition: color 0.2s;
}

.cursor {
  cursor: default;
}

.organizationImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.closeSidebar {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: $gray-cold-500 !important;
}

.user {
  padding-top: 1rem;
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 162px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 18.83%);
    pointer-events: none;
  }
}
