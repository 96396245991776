@import 'src/styles/variables';

.planUpgradeBtn {
  background: $orange-500;
  &:hover {
    background: $orange-400;
  }
  &:active {
    background: $orange-700;
  }
}

.smallBtn {
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  padding-left: 0.5rem;
  padding-right: 0.25rem !important;
  svg {
    margin-left: 0 !important;
  }
}
