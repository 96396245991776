@import 'src/styles/variables';

.modalContainer {
  max-width: 452px;
}

.alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalTitle {
  composes: alignment;
  background-color: $gray-cold-100;
  padding-bottom: 1rem;
  padding-top: 3.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.iconClose {
  color: $gray-cold-500;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}

.iconWarning {
  width: 64px;
  height: 64px;
  color: $yellow-500;
}

.title {
  font-size: 24px;
  color: $gray-cold-900;
}

.description {
  font-size: 16px;
  color: $gray-cold-900;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  composes: alignment;
}

.btn {
  max-width: 340px;
  height: 48px;
  width: 100%;
}

.wrapper {
  padding-bottom: 2.375rem;
  composes: alignment;
}
