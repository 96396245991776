@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  //overflow: hidden;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.opened {
    border-bottom: 1px solid var(--primary-200);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}

.button {
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--primary-100);
  margin-right: 1rem;
  border: 1px solid transparent;
  transition: 0.2s;

  &:hover {
    border-color: #99C7FF;
  }
}
