@import 'src/styles/variables';

.icon {
  background-color: var(--primary-100);
  border-radius: 50%;
}

.btn {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.trash {
  color: $red-200;
  cursor: default;
  z-index: 10;
  &:hover {
    color: $red-500;
  }
}
