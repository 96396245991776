@import 'src/styles/variables';

.selected {
  font-size: 14px;
  line-height: 20px;
}

.paynet {
  color: #ec6665 !important;
}

.dropItem {
  padding: 0.25rem 0.75rem;
}
