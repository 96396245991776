@import 'src/styles/variables';

.container {
  display: block;
}

.date {
  flex: 0 0 184px;
  max-width: 184px;
}

.amount {
  flex: 0 0 144px;
  max-width: 144px;
}

.statusContainer {
  width: fit-content;
  align-self: flex-end;
}

.status {
  flex: 0 0 108px;
  max-width: 108px;
}

.name {
  flex: 0 0 200px;
  max-width: 200px;
  gap: 0.25rem;
}

.title {
  flex: 0 1 174px;
  max-width: 100%;
  gap: 0.25rem;
}

.email {
  font-weight: 600;
  color: $primary-600;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: $primary-400;
  }
}

.deleted {
  font-weight: 600;
  color: $gray-cold-500;
}

.red {
  color: $red-500;
}

.offerTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: $primary-600;
  cursor: pointer;

  &:hover {
    color: $primary-400;
  }

  &.deleted {
    color: $gray-cold-500;
  }
}

.amountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
