@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 128px;
  height: 128px;
  background: $gray-neutral-100;
  color: var(--primary-500);
  margin-bottom: 2rem;
  border-radius: 50%;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: var(--primary-500);
  margin-bottom: 0.5rem;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 1.5rem;
}
