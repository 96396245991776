@import 'src/styles/variables';

.audioSeekBar {
  cursor: pointer;
  background-color: $gray-neutral-100;
  overflow: hidden;
  height: 0.5rem;
  border-radius: 1px;
}
.tick {
  background: var(--primary-500);
  border-radius: 2px 0px 0px 2px;
  height: 100%;
}
