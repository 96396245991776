@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border: 1px solid $yellow-300;
  background: $yellow-100;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;

  :global {
    .icon {
      color: $yellow-500;
    }
  }
}
