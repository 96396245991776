@import 'src/styles/variables';

.container {
  padding: 1rem;
  background: var(--primary-100);
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  position: relative;

  &.passed {
    background: $gray-neutral-200;

    .title {
      color: $gray-cold-500;
      text-decoration: line-through;
    }

    .quiz {
      border-color: $orange-300;
      color: $orange-500;
    }

    .date {
      color: $gray-cold-700;
    }
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
}

.title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  color: $orange-500;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.checkmark {
  color: $gray-cold-500;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.reload {
  color: $gray-cold-500;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.quiz {
  border: 1px solid $gray-cold-300;
  background-color: $gray-neutral-100;
  padding: 3px;
  border-radius: 0.25rem;
  line-height: 0;
  color: $gray-cold-500;
  margin-right: 1rem;
  margin-left: 1rem;
}

.date {
  font-size: 12px;
  line-height: 24px;
  color: $gray-cold-500;
  flex-shrink: 0;
  width: 4.5rem;
  text-align: right;
}

.newLabel {
  font-size: 12px;
  line-height: 18px;
  color: $green-700;
  border: 1px solid $green-300;
  background-color: $green-100;
  border-radius: 0.125rem;
  padding: 0.125rem 7px;
  position: absolute;
  left: -10px;
  top: 1rem;
}

.new {
  padding-left: 2rem;
}

.description {
  font-size: 12px;
  line-height: 18px;
  margin-top: 0.25rem;
  white-space: normal;
}

.breadcrumbs {
  margin-top: 0.5rem;
  margin-left: 2rem;
  border-top: 1px solid $gray-cold-300;
  padding-top: 0.5rem;
}
