@import 'src/styles/variables';

.container {
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-500;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.eyeIcon {
  color: $primary-500;
}
