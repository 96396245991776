@import 'src/styles/variables';

.wrapper {
  border-radius: 0.25rem;
  background: $gray-neutral-100;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.75rem;
}

.error {
  font-size: 12px;
  line-height: 18px;
  color: $red-600;
}
