@import '../variables';

.palette {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 40px;
  border-radius: 4px;
  z-index: $z-index + 1;

  &.disabled {
    pointer-events: none;
    user-select: none;

    .palette-nav-item {
      .btn {
        color: $gray-cold-300;
      }
    }
  }
}

.palette-nav {
  background: #fff;
  box-shadow: $shadow-raised;
  border-radius: $border-radius;
}

.palette-nav-item {
  padding: 12px 0;
  text-align: center;

  .btn {
    color: $gray-cold-500;

    &.active {
      color: var(--primary-500);
    }
  }
}
