@import 'src/styles/variables';

.offer-radio {
  height: 2rem;
  &.disable {
    label {
      pointer-events: none;
      color: $gray-cold-500 !important;
      &::before {
        border-color: $gray-cold-300 !important;
        background: $gray-cold-100 !important;
      }
    }
  }
}
