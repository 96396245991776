@import 'src/styles/variables';

.container {
  max-width: 768px;
  margin: auto;
}

.chapter {
  font-size: 24px;
  color: $gray-cold-900;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .btn {
    max-width: unset;
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.commonContainer {
  position: relative;
}

.descriptionText {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 16px;
  color: $gray-cold-700;
}
