@import 'src/styles/variables';

.container {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  background: var(--primary-200);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
