@import 'src/styles/variables';

.container {
  display: block;
}

.card {
  margin-bottom: 1.5rem;
  min-height: 284px;
}

.plan {
  display: flex;
  color: $gray-cold-700;
  margin-bottom: 1.5rem;

  & > div {
    flex-grow: 1;
  }
}

.label {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0.25rem;
}

.value {
  font-size: 16px;
  line-height: 22px;

  strong {
    margin-right: 0.5rem;
    font-weight: 600;
    color: $gray-cold-900;
  }
}

.note {
  color: $gray-cold-700;
  text-align: center;
  font-size: 12px;
  line-height: 18px;;
}
