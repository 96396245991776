@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  // padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: $gray-neutral-100;
  gap: 0.25rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin: 0 auto 16px;
  border-radius: 0.5rem;
  background-color: var(--primary-200);
}

.icon {
  width: 100%;
  height: 100%;
  color: var(--primary-500);
}

.inputFile {
  display: none;
}

.inputDnD,
.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0.4rem;
  transition: border-color 0.33s ease-in-out;
  border: 1px solid transparent;
  border-radius: 0.125rem;
  background-color: var(--primary-100);

  &.invalidForm {
    background-color: $gray-neutral-100;
  }

  &.inputDnDHover,
  &.cardTypeOfUpload,
  &:hover {
    border-color: $primary-300;
  }

  &.typeOfUpload {
    justify-content: flex-start;
    padding: 1.5rem 3rem;
  }

  &.fullWidth {
    position: absolute;
    top: 0;
    width: 100%;

    &:hover {
      border-color: transparent;
    }
  }

  .title {
    margin: 0 0 0.25rem;
    letter-spacing: -0.003em;
    color: $gray-cold-900;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4285;
  }

  .note {
    margin: 0;
    letter-spacing: -0.003em;
    color: $gray-cold-700;
    font-size: 12px;
    line-height: 1.5;

    .noteHighlight {
      color: var(--primary-600);
    }
  }

  &.active {
    border: 1px solid $primary-300;
    border-radius: 0.25rem;
    background-color: var(--primary-100);
  }

  .uploadText {
    margin-top: 1rem;
    color: $gray-cold-700;
    font-size: 16px;
    font-weight: 600;
  }

  .content {
    transition: transform 0.3s ease-in-out;
    transform: translateY(1rem);
    text-align: center;
    background-color: var(--primary-100);
  }

  .browseButton {
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    &.browseButtonActive {
      width: 232px;
      opacity: 1;
    }
  }

  .redirectButton {
    width: 100%;
    padding: 1rem;
    transition-delay: 0.15s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.15s;
    transition-property: color, opacity, background-color;
    text-align: left;
    letter-spacing: -0.003em;
    opacity: 0;
    color: $gray-cold-500;
    border: 1px solid var(--primary-500);
    border-radius: 0.25rem;
    background-color: $gray-neutral-100;
    background-image: none;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: $gray-cold-700;
      background-color: $gray-neutral-100;
    }
  }

  .contentHover {
    transform: translateY(-1rem);

    & + .browseButton,
    & + .redirectButton {
      opacity: 1;
    }
  }
}

.inputDnDContainer,
.card {
  position: relative;
  width: 100%;
  height: 416px;
  cursor: pointer;
}

.typeOfUploadTitle {
  z-index: 1;
  margin: 0 0 0.25rem;
  letter-spacing: -0.003em;
  color: $gray-cold-900;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.typeOfUploadNote {
  z-index: 1;
  margin: 0;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  font-size: 12px;

  .typeOfUploadNoteHighlight {
    color: var(--primary-600);
  }
}

.linkContainer {
  position: relative;
  width: 100%;
  margin-top: 4.375rem;
}

.linkActions {
  display: flex;
  margin-top: 0.5rem;
}

.linkButton {
  margin-right: 0.5rem;
}

.linkNote {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: $gray-cold-500;
}

.linkNoteText {
  margin: 0 0 0 0.25rem;
  letter-spacing: -0.003em;
  color: $gray-cold-700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  svg {
    color: $gray-cold-500;
    margin-right: 0.25rem;
  }
}

.videoEmbed {
  resize: none;
  font-family: 'Roboto Mono', monospace;
}

.droppedFile {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
