@import 'src/styles/variables';

.modal {
  :global .modal-dialog {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  :global .modal-content {
    border-radius: 0.5rem;
    overflow: hidden;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.image {
  height: 140px;
  background: url("../../../assets/images/mobile-restrict.jpg") no-repeat center;
  margin-bottom: 1rem;
}

.title {
  color: var(--primary-500);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.25rem;
  text-align: center;
}

.text {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2.25rem;
  padding: 0 1.25rem;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 0.5rem;
}
