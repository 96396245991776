@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.container {
  background-color: var(--primary-100);
  height: var(--vh);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgDefault {
  background-image: url('../../../../../assets/images/auth_bg_image.png');
  background-position: 0 50%;
}
