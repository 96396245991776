@import 'src/styles/variables';

.container {
  position: relative;
}

.image {
  border-radius: 50%;
}

.name {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-neutral-100;
  font-weight: 600;
  z-index: 1;
}
