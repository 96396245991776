@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border: 1px solid $primary-300;
  background: $gray-neutral-100;
  border-radius: 19px;
  cursor: pointer;

  &.active {
    border-color: $primary-400;
  }
}

.title {
  font-size: 12px;
  line-height: 18px;
  flex-grow: 1;
  user-select: none;
}

.preview {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid $gray-cold-300;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 1.5rem;
}

.wrapper {
  padding: 1rem;
}
