@import 'src/styles/variables';

.pageBuilder {
  height: calc(100vh - 4rem);
}

.save {
  position: absolute;
  right: 2rem;
  top: 1rem;
  z-index: 100;
}
