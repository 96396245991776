@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-end;
  margin: 0 1.5rem;
  padding: 1rem 0;
  background: $gray-neutral-100;
  position: relative;
}

.container + .container {
  border-top: 1px solid $gray-cold-200;
}

.input {
  border: none;
  resize: none;
  line-height: 18px;
  font-size: 12px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
  height: 100%;
  transition: height 0.3s ease;
  @media (min-width: 576px) {
    line-height: 20px;
    font-size: 14px;
    // min-height: 48px;
  }
}

.image {
  position: sticky;
  top: 1rem;
  z-index: 0;
  border-radius: 50%;
  align-self: flex-start;
  margin-right: 0.5rem;
  width: 32px;
  height: 32px;
  @media (min-width: 576px) {
    margin-right: 1rem;
    width: 48px;
    height: 48px;
  }
}

.authorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 576px) {
    .date {
      margin-left: 0.5rem;
    }
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .date {
    font-size: 12px;
    line-height: 18px;
    color: $gray-cold-500;
  }
}

.more {
  border: none;
  background: none;
  color: $primary-500;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5rem 0 0 0;
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem !important;
  font-size: 0.75rem;
  line-height: 1.125rem;
  .customizationToggle {
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
  }
}

.infoContainer {
  background: $primary-100 !important;
  border-color: $primary-300 !important;
  padding: 0.5rem !important;
  border-radius: 0.25rem !important;
  margin-right: 1.5rem !important;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.info {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.infoIcon {
  color: $primary-500;
}
