@import 'colors';

:root {
  --vh: 100vh;
  --primary-100: #f5f9ff;
  --primary-200: #e0eeff;
  --primary-300: #99c7ff;
  --primary-400: #338eff;
  --primary-500: #0072ff;
  --primary-600: #005bcc;
  --primary-700: #004499;
  --primary-800: #002e66;
  --primary-900: #001733;
  --gradient-main: linear-gradient(270deg, #008bff 0%, #0059ff 100%);
  --button-background: #0059ff;
  --button-text-color: #fff;
  --bg-color: #f8f7f8;
}
