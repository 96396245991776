@import 'src/styles/variables';

.organizationIconWrapper {
  position: relative;
  border-radius: 50%;
  border: 0.125rem solid transparent;
  padding: 0.125rem;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  transition: border-color 0.2s;
  margin-right: 0.25rem;

  &.selected {
    border-color: var(--primary-500);
  }

  &:hover {
    border-color: $primary-300;
  }

  &:last-child {
    margin-right: 0;
  }
}

.organizationImgContainer {
  position: relative;
}

.organizationImgText {
  font-size: 14px;
}

.plusContainer {
  composes: organizationIconWrapper;
}

.organizationIcon {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: var(--primary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-500);
}
