@import 'src/styles/variables';

.container {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: content-box;
  height: calc(3rem - 0.125rem);
}

.text {
  font-size: 14px;
  border: none;
  padding-left: 1rem;
  max-width: 250px;
  height: 46px;
}
.error {
  color: $red-600;
  font-size: 12px;
}

.btn {
  margin-left: 0.5rem;
  &:disabled {
    &:hover {
      background-color: $gray-cold-300;
      color: $gray-neutral-100;
    }
  }
}

.select {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
