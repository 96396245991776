@import 'src/styles/variables';

:global .dropdown-menu {
  border: none;
}

.container {
  background: $gray-neutral-100;
  box-shadow: $shadow-floating;
  border-radius: 0.25rem;
  overflow: hidden;

  :global .active {
    color: var(--primary-500);
    background: $gray-cold-100;

    :local .icon {
      color: var(--primary-500);
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid $gray-cold-200;
  color: $gray-cold-900;

  &:hover {
    background-color: $gray-cold-100;
    text-decoration: none;
    color: $gray-cold-900;

    .icon {
      color: var(--primary-500);
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.title {
  font-size: 24px;
  color: $gray-cold-900;
  margin-right: 0.5rem;
}

.arrow {
  color: $gray-cold-500;
}

.icon {
  color: $gray-cold-500;
  margin-right: 0.25rem;
  line-height: 0;
}

.name {
  font-size: 14px;
  line-height: 20px;
}
