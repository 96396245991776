@import 'src/styles/variables';

.container {
  background: $gray-neutral-100;
  box-shadow: $shadow-floating;
  border-radius: 0.25rem;
  padding: 3.5rem;
  width: 100%;
  max-width: 620px;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: var(--primary-500);
  margin-bottom: 0.5rem;
}

.text {
  line-height: 22px;
  margin-bottom: 1.5rem;
}

.create {
  background: $gray-cold-100;
  border-radius: 1rem;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    .label {
      color: var(--primary-500);
    }

    .icon {
      background: var(--primary-500);
      color: $gray-neutral-100;
    }
  }

  &:first-child {
    margin-right: 0.5rem;
  }
}

.image {
  min-height: 52px;
}

.label {
  padding-top: 0.375rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.2s;
}

.icon {
  background: $gray-neutral-100;
  border-radius: 50%;
  padding: 0.5rem;
  transition: background-color 0.2s, color 0.2s;
  color: var(--primary-500);
}
