@import 'src/styles/variables';

.title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
}
.link {
  color: $primary-600;
  text-decoration: underline;
  cursor: pointer;
}
.description {
  font-size: 12px;
  line-height: 18px;
  color: $gray-cold-700;
}
.email {
  font-size: 12px;
  line-height: 18px;
}
.img {
  width: 80px;
  height: 80px;
}
