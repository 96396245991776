@import 'src/styles/variables';
@import 'src/styles/media-queries.scss';

.modal {
  @include gt-xs {
    max-width: 680px;
  }
}

.container  {
  color: $gray-cold-500;
  font-size: 14px;
  line-height: 20px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.6875rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.description {
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-900;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 24px;
  line-height: 36px;
  color: black;
  margin-left: 1rem;
}

.thumbnail {
  width: 96px;
  height: 54px;
}
