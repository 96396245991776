@import 'src/styles/variables';
@import 'src/styles/media-queries';

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: $student-sidebar-width;
  z-index: 100;

  @include lt-xl {
    padding-left: $student-sidebar-width + 1rem;
    padding-right: 1rem;
  }

  @include lt-lg {
    padding-left: 1rem;
  }

  @include lt-md {
    padding: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-popping;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: $gray-neutral-100;
  height: 48px;
  flex-shrink: 0;
  max-width: 816px;
  width: 100%;
  margin: 0 auto;

  :global .btn {
    width: 9.75rem;
  }
}

.readOnly {
  pointer-events: none;
  background: var(--primary-600);
}
