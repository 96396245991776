@import 'src/styles/variables';

.modal {
  @media (min-width: 576px) {
    :global .modal-dialog {
      max-width: 500px;
    }
  }
}

.container {
  display: block;
}

.header {
  background-color: var(--primary-100);
  padding: 2.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0 0;

  svg {
    color: var(--primary-500);
  }
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: $gray-cold-700;
  margin-bottom: 0.5rem;
}

.name {
  text-align: center;
}

.content {
  padding: 1.5rem 3.5rem;
}

.title {
  text-align: center;
  color: $gray-cold-700;
  margin-bottom: 1rem;

  span {
    color: $gray-cold-900;
  }
}

.item {
  display: flex;
  align-items: center;
  color: $gray-cold-700;
  background-color: var(--primary-100);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
}

.new {
  font-size: 12px;
  line-height: 18px;
  padding: 3px 0.5rem;
  color: $green-700;
  background-color: $green-100;
  border: 1px solid $green-300;
  border-radius: 0.125rem;
  margin-right: 0.5rem;
}

.course {
  color: var(--primary-500);
}

.offer {
  color: $yellow-500;
}

.product {
  color: $green-500;
}

.loading:disabled {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--primary-600);
}
