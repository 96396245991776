@import 'src/styles/variables';

.toggleContainer {
  display: flex;
  height: 30px;
}

.toggleText {
  font-size: 14px;
  color: $gray-cold-700;
  align-items: center;
  display: flex;
}
