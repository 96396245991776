@import 'src/styles/variables';

.container {
  background: $gray-neutral-200;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-height: 288px;
  height: 100%;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
}

.thumbnailContainer {
  padding: 1rem;
}

.textContainer {
  color: var(--primary-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.text {
  font-weight: 600;
  margin-top: 0.5rem;
}

.emailImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: $shadow-popping;
  border-radius: 0.25rem;
}

.title {
  color: $gray-cold-700;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
