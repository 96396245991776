@import 'src/styles/variables';

.planContainer {
  max-width: 276px;
}

.plan {
  background: var(--primary-100);
  border-radius: 1rem;
  padding: 1rem 1.5rem 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.popular {
    background: $gradient;
    color: #fff;
  }

  &.current {
    background: $gray-neutral-200;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;

  .current & {
    color: $gray-cold-700;
  }
}

.cancel {
  padding: 0;
  line-height: 22px;
  margin-left: auto;
  font-size: 12px;
  color: var(--primary-400);
  font-weight: 400;
}

.label {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 18px;
  color: $yellow-700;
  padding: 3px 8px;
  border: 1px solid $yellow-400;
  background: $yellow-100;
  border-radius: 2px;
}

.price {
  margin-bottom: 1rem;
}

.month {
  font-size: 24px;
  line-height: 36px;
  color: var(--primary-500);

  .popular & {
    color: $gray-neutral-100;
  }
}

.year {
  font-size: 12px;
  line-height: 18px;
  min-height: 18px;
  color: $gray-cold-700;

  .popular & {
    color: var(--primary-200);
  }

  .current & {
    color: $gray-neutral-500;
  }
}
