@import 'src/styles/variables';

.table {
  width: 100%;
  display: block;
  margin-bottom: 0;
  overflow-y: auto;
  height: calc(
    100vh - #{$header-height} - #{$content-padding-top} - #{$content-padding-bottom} - #{$page-top-panel-height}
  );
  min-height: 30rem;

  :local tbody,
  :local thead {
    display: block;
  }

  :local tbody tr:last-child td {
    border-bottom: 1px solid $gray-neutral-300;
  }

  :local thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.row {
  display: flex;

  &:hover {
    .buttons {
      opacity: 1;
      visibility: visible;
    }
  }
}

.selectedLists {
  max-height: 11rem;
  overflow-y: auto;
  background-color: $gray-cold-100;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
}

.text {
  color: $gray-cold-900;
}

.selectedRow {
  background-color: var(--primary-200);

  .listName {
    font-weight: bold;
  }

  .actions {
    .buttons {
      opacity: 1;
      visibility: visible;
    }
  }
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--primary-600);
  cursor: pointer;
  height: 100%;

  .icon {
    color: $gray-cold-500;
    margin-right: 0.25rem;
  }
}

.actions {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

  .buttons {
    opacity: 0;
    visibility: hidden;
    padding: 2px 8px;
    border-radius: 14px;
    box-shadow: $shadow-raised;
    background-color: $gray-neutral-100;
    margin-left: auto;
    display: flex;

    :global .btn {
      color: $gray-cold-500;
    }

    //:global .btn:not(.btn:first-child) {
    :global div .btn {
      margin-left: 0.125rem;
    }
  }
}

:global .table {
  :global tbody {
    :local .listName {
      padding: 0.5rem 1.5rem;
    }

    :local .selectedRow {
      :global td {
        color: $gray-cold-900;
      }
    }
  }
}
