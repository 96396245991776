@import 'src/styles/variables';

.time-container {
  display: flex;

  & input {
    max-width: 64px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0 0 1px;
    border-color: $primary-300;

    &.invalid {
      color: $red-500;
      border-color: $red-500;
    }
  }

  & input:focus {
    outline: none;
  }
}

.time {
  margin-right: 0.5rem;
}

.timeTitle {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.3px;
  color: $gray-cold-700;
}

.selectContainer {
  width: 64px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.calendar-payload {
  .date-field {
    &.react-datepicker {
      .react-datepicker {
        &__month-container,
        &__header,
        &__navigation {
          display: none;
        }

        &__time-container {
          border-left: none;
        }
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      margin-top: 0.125rem;
    }
  }
}

.date-field {
  &.react-datepicker {
    font-family: $font-family;
    font-size: 12px;
    background-color: #fff;
    color: $gray-cold-700;
    border: none;
    display: inline-block;
    position: relative;
    box-shadow: $shadow-popping;
    border-radius: 0.5rem;

    .react-datepicker {
      &__input-time-container {
        display: flex;
        justify-content: space-between;
        margin: unset;

        position: absolute;
        left: 0;
        right: 0;
        bottom: -54px;
        background: #fff;
        box-shadow: $shadow-popping;
        border-radius: 0.5rem;
      }

      &__triangle {
        display: none;
      }

      &__navigation {
        top: 20px;
      }

      &__header {
        text-align: center;
        background-color: transparent;
        border-bottom: none;
        border-top-left-radius: 0.3rem;
        padding-top: 1rem;
        position: relative;
      }

      &__current-month {
        font-size: 16px;
        line-height: 22px;
        color: $gray-cold-500;
      }

      &-time__caption {
        font-size: 16px;
        line-height: 22px;
        color: $gray-cold-500;
        margin: 0.8125rem 1rem;
      }

      &-time__input-container {
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
      }

      &-time__input {
        color: $gray-cold-900;
        font-size: 14px;
        line-height: 20px;

        input {
          border: 1px solid $primary-300;
          border-radius: 0.5rem;
          box-sizing: border-box;
          padding: 0.375rem 0.5rem;
        }
      }

      &-time__header,
      &-year-header {
        margin-top: 0;
        padding-bottom: 1rem;
        color: $gray-cold-900;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.38;
        border-bottom: 1px solid $gray-cold-300;
      }

      &__month {
        margin: 0 0.5rem 0.5rem;
      }

      &__day-names {
        padding-top: 0.5rem;
      }

      &__day-name {
        color: $gray-cold-500;
      }

      &__day,
      &__time-name {
        color: $gray-cold-700;
      }

      &__day-name,
      &__day,
      &__time-name {
        width: 40px;
        line-height: 40px;
        margin: 1px;
      }

      &__day--selected,
      &__day--in-selecting-range,
      &__day--in-range,
      &__month-text--selected,
      &__month-text--in-selecting-range,
      &__month-text--in-range,
      &__quarter-text--selected,
      &__quarter-text--in-selecting-range,
      &__quarter-text--in-range,
      &__year-text--selected,
      &__year-text--in-selecting-range,
      &__year-text--in-range {
        border-radius: 0.3rem;
        background-color: var(--primary-500);
        color: #fff;
      }

      &__day--keyboard-selected {
        background-color: var(--primary-500);
        color: #fff;
      }

      &__day--outside-month {
        background-color: $gray-cold-200;
        color: $gray-cold-500;
      }
    }
  }
}
