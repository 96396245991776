@import 'src/styles/variables';

.container {
  margin-bottom: 0.5rem;
}

.item {
  max-width: 816px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: $gray-neutral-200;

  &.active {
    background: $primary-100;
  }

  &.inactive {
    cursor: pointer;

    &:hover {
      background: $primary-100;
    }
  }
}

.actions {
  gap: 0.5rem;
  margin: 1rem -1.5rem -1rem;
  position: unset;
  bottom: auto;
  left: auto;
  right: auto;
  padding: 0;
}

.enter {
  transform: translateY(100%);
}

.enterActive {
  transition: transform 300ms;
  transform: translateY(0);
}

.exit {
  transform: translateY(0);
}

.exitActive {
  transform: translateY(100%);
  transition: transform 300ms;
}

.delete {
  padding: 0.25rem;
  margin-left: auto;

  &:focus {
    background: $red-300 !important;
  }

  &:active {
    background: $red-500 !important;
    color: $gray-neutral-100 !important;
  }
}

.error {
  max-width: 816px;
  margin: 0.5rem auto 0;
}
