@import 'src/styles/variables';

.wrapper {
  padding: 1.5rem 3.5rem 2.375rem 3.5rem;
  .description {
    text-align: center;
    font-size: 16px;
  }
}

.content {
  overflow: hidden;
  max-width: 452px;
}

.container {
  background-color: $red-100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5rem;
  .icon {
    color: $red-500;
    width: 64px;
    height: 64px;
  }
  .title {
    font-size: 24px;
    text-align: center;
    padding: 1rem 2.625rem 1.5rem 2.625rem;
  }
}
