@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 32px;
  color: $gray-cold-500;
  display: flex;
}

.description {
  font-size: 16px;
  color: $gray-cold-700;
}

.icon {
  width: 48px;
  height: 48px;
  margin-left: 0.5rem;
}

.btn {
  height: 48px;
}

.textContainer {
  text-align: right;
  .dayLeftTxt {
    color: $orange-500;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
  }
}
