@import 'src/styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $primary-300;
  border-radius: 0.25rem;
  height: 2rem;
  margin-top: 0.5rem;
}

.input {
  text-align: end;
  border: none;
  padding: 0.375rem 0.375rem 0.375rem;
  height: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.label {
  cursor: text;
  padding: 0.375rem 0 0.375rem 0.5rem;
  color: $gray-cold-500;
}

.container + .container {
  margin-left: 0.375rem;
}

.description {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: $gray-cold-900;
  letter-spacing: -0.003em;
}

.timeWrapper {
  border: 1px solid $primary-300;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 92px;
  width: 100%;
}

.icon {
  position: absolute;
  right: 5px;
  top: 1px;
  color: $gray-cold-400;
}

.title {
  font-size: 12px;
  color: $gray-cold-500;
  background-color: var(--primary-100);
  text-align: center;
  padding: 0.438rem 0.75rem;
}

.timeInput {
  border: none;
  max-width: 93px;
  padding: 0.313rem 0.5rem;
  height: 100%;
}

.utcText {
  composes: timeInput;
  font-size: 12px;
  color: $gray-cold-500;
  text-align: center;
  padding: 0.438rem 0.5rem;
  background-color: $gray-neutral-100;
  flex-wrap: nowrap;
}

.calendarContainer + .calendarContainer {
  margin-left: 0.375rem;
}

.inputContainer {
  border-top: none;
  line-height: normal;
  border-radius: 0;
}

.inputMask {
  border: none;
  width: 100%;
}

.zoneContainer {
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.zoneText {
  font-size: 10px;
  font-weight: bold;
  color: $gray-cold-500;
  border: none;
  background-color: transparent;
  &.selected {
    color: var(--primary-500);
  }
}

.errorText {
  color: $red-600;
  font-size: 12px;
}
