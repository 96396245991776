.mainContainer {
  padding-bottom: 0;
}

.wrapper {
  max-width: 768px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
