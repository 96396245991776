@import 'src/styles/variables';

.container {
  padding: 19px 1.5rem 1rem;
}

.header {
  border: 1px solid $primary-300;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

.top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primary-300;
  padding: 0.5rem;
}

.image {
  width: 72px;
  height: 40px;
  margin-right: 0.5rem;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
}

.menu {
  width: 100%;
}

.menuItem {
  white-space: initial;

  &.active {
    background-color: var(--primary-100);
    color: var(--primary-500);
  }
}

.buttons {
  padding: 0.5rem;
  display: flex;

  :global .btn {
    flex-grow: 1;
    width: 100%;

    &:first-child {
      margin-right: 0.125rem;
    }
  }
}

.image {
  max-width: 72px;
}
