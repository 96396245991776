@import 'src/styles/variables';

.container {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  position: relative;
}

.selected {
  display: contents;
}

.selectedTag {
  padding: 3px 8px;
}

.searchInput {
  background-image: url('../../../../assets/icons/16/4-icon-16-px-plus.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  padding: 2px 24px 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid var(--primary-500);
  color: var(--primary-600);
  box-sizing: content-box;
  margin-bottom: 0.5rem;
  max-width: 140px;

  .tagName {
    background-color: #000;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--primary-600);
  }
}

.showAdd {
  display: flex;
  align-self: flex-end;
  position: relative;
}

.hideAdd {
  display: none;
}

.searchInputFill {
  composes: searchInput;
  background-image: none;
  padding: 2px 8px 2px 8px;
}

.suggestions {
  position: absolute;
  margin-top: 2rem;
  z-index: 10;
  // right: 50px;
  border-radius: 4px;
  box-shadow: $shadow-floating;
  background-color: #ffffff;
}

.suggestionActive {
  margin-top: unset;
  composes: suggestions;
  box-shadow: none;
  background-color: var(--primary-100);
  position: relative;
  right: 0;
  left: 0;
}

.suggestionDisabled {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  &:hover {
    background-color: var(--primary-100);
  }
}

.selected {
  border: none;
  background-color: var(--primary-200);
  border-radius: 12px;
  color: var(--primary-600);

  &:focus {
    outline: none;
  }
}
