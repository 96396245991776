@import 'src/styles/variables';

.payload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 23rem;
  display: flex;
  flex-direction: column;
  background: $gray-neutral-100;
  box-shadow: $shadow-raised;
  overflow-y: auto;
}
.action-buttons {
  margin-top: auto;
}
