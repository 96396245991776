@import 'src/styles/variables';

.animation {
  position: relative;
  background: var(--primary-100);
  padding: 0.25rem 0;
  .rc-tree {
    .rc-tree-treenode {
      span.rc-tree-switcher {
        background: none;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        margin: 0;
        padding-right: 0.5rem;
        position: absolute;
      }
    }
  }

  .rc-tree-iconEle {
    width: auto !important;
    height: 100% !important;
    display: flex !important;
    align-self: flex-start;
    opacity: 1 !important;
  }

  .rc-tree-node-content-wrapper {
    height: 100% !important;
    width: 100%;
    display: flex !important;
    align-items: center !important;
    .rc-tree-title {
      &:hover {
        // background-color: red;
      }
    }
    &:hover {
      .rc-tree-title {
        span {
          color: var(--primary-500);
        }
      }
      .rc-tree-title {
        div {
          color: var(--primary-500);
        }
      }
    }

    &.rc-tree-node-selected {
      .selected-lesson {
        color: var(--primary-600);
      }
    }

    &:not(.rc-tree-node-selected) {
      .selected-lesson {
        color: unset;

        svg {
          display: none;
        }
      }
    }
  }

  .rc-tree-node-content-folder {
    position: relative;

    .rc-tree-switcher {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: center;

      // span {
      //   position: absolute;
      //   z-index: 6;
      // }
    }

    .rc-tree-iconEle {
      color: $yellow-500;
    }

    &:hover {
      .rc-tree-switcher {
        svg {
          color: var(--primary-500);
        }
      }
      .rc-tree-title {
        div {
          color: var(--primary-500);
        }
      }
    }
  }

  .rc-tree-node-content-wrapper-close {
    .rc-tree-title {
      span {
        color: $gray-cold-700;
        // width: 100%;
      }
    }
  }

  .rc-tree-node-content-lesson {
    position: relative;

    .rc-tree-node-content-wrapper {
      width: 100%;
    }
    .rc-tree-iconEle {
      color: $orange-500;
    }

    &.not-parent {
      .rc-tree-switcher {
        display: none;
      }
    }
  }
}

.rc-tree {
  .rc-tree-treenode {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    .rc-tree-indent {
      position: initial;
      height: auto;

      .rc-tree-indent-unit {
        width: 2rem;
        position: relative;
        height: 100%;

        &::before {
          position: absolute;
          top: -0.5rem;
          bottom: -0.5rem;
          border-left: 2px solid #cddae4;
          left: 11px;
          content: '';
        }

        &:first-child:not(:last-child) {
          background: none;
        }
      }
    }

    &.rc-tree-node-content-folder {
      &.rc-tree-treenode-leaf-last {
        .rc-tree-indent {
          .rc-tree-indent-unit {
            &:last-child {
              background-position: 0 -80px;
            }
          }
        }
      }
    }

    &.rc-tree-treenode-leaf-last {
      .rc-tree-indent {
        .rc-tree-indent-unit {
          &:last-child::before {
            background-position: 0 -80px;
          }
          &-end {
            &::before {
              background-position: 0 -80px;
            }
          }

          & + .rc-tree-indent-unit {
            &::before {
              background-position: 0 -80px;
            }
          }
        }
      }
    }

    .rc-tree-node-selected {
      background: unset !important;
      box-shadow: unset !important;
      opacity: 1 !important;
    }

    .rc-tree-node-content-wrapper {
      min-width: 0;
    }
  }
}

.rc-tree-title {
  overflow: hidden;

  svg {
    flex-shrink: 0;
  }

  span {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all 0.3s;
  overflow-y: hidden;
}
