@use './common';
@import 'src/styles/variables';

@include common.font-size();

.ql-item-color {
  width: 24px;
  filter: invert(69%) sepia(9%) saturate(624%) hue-rotate(166deg) brightness(87%) contrast(84%);

  &:hover {
    filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
  }
}

.snow {
  .quill {
    .ql-toolbar,
    .ql-snow {
      left: 0;
      z-index: 1;
      border: none;

      .ql-formats {
        background: $gray-cold-200;
        border-radius: 0.25rem;
        padding: 0.25rem;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .ql-active {
          filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
        }

        .ql-picker-label {
          padding: 0;
          color: $gray-cold-500;

          &:hover {
            color: var(--primary-500);
            filter: invert(40%) sepia(94%) saturate(5159%) hue-rotate(204deg) brightness(102%) contrast(108%);
          }

          border: none;
        }

        .ql-expanded {
          .ql-picker-options {
            border: none;
            background-color: $gray-cold-100;
            box-shadow: $shadow-floating;
            border-radius: 0.5rem;
          }
        }

        button {
          color: $gray-cold-500;
          display: flex;
          align-items: center;
          padding: 0;
          width: 24px !important;
          height: 24px;

          &:hover {
            color: var(--primary-500);
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .ql-container {
      font-size: inherit !important;
      font-family: $font-family;
      z-index: 0;
    }

    .ql-toolbar.ql-snow {
      box-shadow: common.$shadow-popping;
      background: $gray-neutral-100;
      border-radius: 0.5rem 0.5rem 0 0;
      position: sticky;
      top: -2rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-family: common.$font-family;
      gap: 0.25rem;
    }

    .ql-editor {
      //min-height: 400px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: 1.5rem 1.5rem 0.75rem 1.5rem;

      &.ql-blank::before {
        left: 1.5rem;
        font-style: normal;
        color: $gray-cold-500;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .ql-disabled {
      .ql-editor {
        padding-top: 0.75rem;
      }
    }
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
    font-family: 'Arial', cursive;
    content: 'Arial' !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
    font-family: 'Roboto', cursive;
    content: 'Roboto' !important;
    font-size: inherit;
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Raleway']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Raleway']::before {
    font-family: 'Raleway', cursive;
    content: 'Raleway' !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
    font-family: 'Montserrat', cursive;
    content: 'Montserrat' !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
    font-family: 'Lato', cursive;
    content: 'Lato' !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value='Rubik']::before,
  .ql-picker.ql-font .ql-picker-item[data-value='Rubik']::before {
    font-family: 'Rubik', cursive;
    content: 'Rubik' !important;
  }

  /* Set content font-families */
  .ql-font-Arial {
    font-family: 'Arial', serif;
  }

  .ql-font-Roboto {
    font-family: 'Roboto', serif;
  }

  .ql-font-Raleway {
    font-family: 'Raleway', serif;
  }

  .ql-font-Montserrat {
    font-family: 'Montserrat', serif;
  }

  .ql-font-Lato {
    font-family: 'Lato', serif;
  }

  .ql-font-Rubik {
    font-family: 'Rubik', serif;
  }
}
