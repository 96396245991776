@import 'src/styles/variables';

.warningIcon {
  color: $yellow-500;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.errorContainer {
  height: 40px;
  border-radius: 0.25rem !important;
  padding: unset;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.errorTitle {
  font-size: 12px;
  color: $red-600;
  margin-top: 0.25rem;
}

.text {
  color: $gray-cold-500;
  font-size: 14px;
  margin-top: 0.5rem;
}

.container {
  height: 52px;
  display: flex;
  margin-top: 1rem;
}
