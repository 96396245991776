@import 'src/styles/variables';

.iconDelete {
  color: $red-500;
}

.iconUnpublish {
  color: $red-500;
}

.customTitle {
  padding-top: 0;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-900 !important;

  .main {
    color: var(--primary-500);
  }
}

.description {
  color: $gray-cold-500;
  font-size: 14px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0.75rem;
  display: flex;
}

.actions {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
