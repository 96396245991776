@import 'src/styles/variables';

.container {
  display: block;
}

.deleted {
  font-weight: 600;
  color: $gray-cold-500;
}

.red {
  color: $red-500;
}

.email {
  font-weight: 600;
  color: $primary-600;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: $primary-400;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.workflow {
  font-weight: 600;
  color: $primary-600;
  cursor: pointer;
}

.status {
  text-align: center;
}

.lastActions {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
}

.chevron {
  color: $primary-500;
  margin-left: auto;
}

.details {
  width: 22rem;
}
