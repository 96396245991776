@import 'src/styles/variables';

.container {
  max-width: 464px;
  text-align: center;
}

.title {
  @extend .container;
  font-size: 24px;
  line-height: 36px;
  margin-top: 1rem;
}

.description {
  @extend .container;
  font-size: 16px;
  line-height: 22px;
  color: $gray-cold-700;
  margin-top: 0.5rem;
}

.icon {
  width: 128px;
  height: 128px;
}

.btn {
  max-width: 256px;
  width: 100%;
  margin-top: 2rem;
}
