@import 'src/styles/variables';

.icon {
  color: var(--primary-500);
}

.title {
  margin: 0 0 0 0.5rem;
  letter-spacing: -0.003em;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.container {
  width: 100%;
  max-width: 816px;
}

.commonModal {
  padding: 1.5rem;
}
