@import 'src/styles/variables';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}

.footerText {
  font-size: 14px;
  line-height: 20px;
  margin-top: 1.5rem;
}

.container {
  max-width: 424px;
}
