@import 'src/styles/variables';

.container {
  padding: 1.813rem 0;
  text-align: center;
  .title {
    font-size: 16px;
    color: $gray-cold-500;
  }
}
