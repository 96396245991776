@import 'src/styles/variables';

.palette-popup {
  position: absolute;
  left: 80px;
  top: 25px;
  width: 274px;
  z-index: $palette-z-index;
  box-shadow: $shadow-raised;
  border: none;

  .scrollable-container {
    padding: 1.5rem;
    max-height: calc(100vh - 7rem);
    overflow-y: auto;
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    letter-spacing: -0.33px;
    color: $gray-cold-700;
  }
}

.palette-elements {
  width: 100%;
  display: grid;
  grid-template-columns: 64px 64px 64px;
  grid-gap: 16px;
}

.palette-element {
  user-select: none;
  cursor: grab;
  width: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.react-draggable {
    position: absolute;
    z-index: $z-index + 99;
  }
}

.palette-element-automation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  cursor: grab;

  &.react-draggable {
    position: absolute;
    z-index: $z-index + 99;
  }

  &.palette-element-automation + .palette-element-automation {
    margin-top: 0.75rem;
  }
}

.element-name {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.25px;
  text-align: center;
  color: $gray-cold-500;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.element-name-automation {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.react-draggable-dragging {
  z-index: $z-index;
  cursor: grabbing;
}

.palette {
  .icon {
    &:hover {
      color: var(--primary-500);
    }
  }
}

.infoPalette {
  border-bottom: 1px solid var(--primary-200);
}
