@import 'src/styles/variables';

.container {
  display: flex;
  margin: 0;
  padding-bottom: 1rem;
}

.pagination {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  align-items: center;

  .paginationInput {
    padding: 0.5rem !important;
    text-align: center;
    width: 3rem;
  }

  .pageFrom {
    display: flex;
    align-items: center;
  }

  .button {
    height: 32px;
    min-width: 32px;
    text-align: center;
    line-height: 32px;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    color: $gray-cold-600;
    cursor: pointer;

    &:hover {
      background: $gray-neutral-100;
    }

    &.active {
      color: $primary-500;
      background: $gray-neutral-100;
      cursor: default;
    }
  }

  .pageText {
    margin-left: 1rem;
  }
}

.arrow {
  cursor: pointer;
  color: $gray-cold-600;

  &:hover {
    color: $primary-500;
  }

  &.inactive {
    color: $gray-cold-500;
    pointer-events: none;
    cursor: default;
  }
}

.dots {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: default;
}
