@import 'src/styles/variables';

.iconInfo {
  color: var(--primary-500);
}

.title {
  font-weight: 600;
  color: $gray-cold-900 !important;
}

.expiredText {
  font-weight: 600;
  color: var(--primary-500);
}

.description {
  color: $gray-cold-500;
  font-size: 14px;
  margin: 0.75rem 2.5rem 0 2.5rem;
}

.btn {
  margin-top: 1rem;
  margin-left: auto;
}
