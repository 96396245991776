@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: 48px auto;
  gap: 1rem;
  padding: 1rem;
  background: $gray-neutral-100;
  border: 1px solid var(--primary-200);
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 312px;
  height: 100%;

  &:hover {
    background: var(--primary-100);
    cursor: pointer;
    .iconContainer {
      color: $gray-neutral-100;
      background-color: var(--primary-500);
    }
    .title {
      color: var(--primary-500);
    }
  }
}

.iconContainer {
  background-color: var(--primary-200);
  color: var(--primary-500);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $gray-cold-900;
}

.description {
  font-size: 14px;
  color: $gray-cold-500;
}

.link {
  text-decoration: none !important;
}

.billingActive {
  position: relative;
  pointer-events: none;
}
